// /src/components/admin/league/league.utils.ts

// Define dropdown options for each field
export const scoreOptions = {
    ipponOptions: [0, 1],
    wazaariOptions: [0, 1, 2],
    yukoOptions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    shidoOptions: [0, 1, 2, 3],
    hsmOptions: [0, 1],
    siegOptions: [0, 1],
    ubwOptions: Array.from({ length: 101 }, (_, i) => i) // 0-100 values
};

export const calculatePenaltyPoints = (shido: number, hsm: number) => {
    let penaltyPoints = 0;

    // Shido (max. 3): count points based on number of shidos
    if (shido >= 1) penaltyPoints += 0;  // First shido gives 1 point
    if (shido >= 2) penaltyPoints += 0;  // Second shido gives another point (total 2)
    if (shido === 3) penaltyPoints += 100; // Third shido gives 98 more points (total 100)

    // Hansokumake (max. 1): 1 counts 100P UBW
    if (hsm === 1) {
        penaltyPoints += 100;
    }

    return penaltyPoints;
};

// Calculate UBW based on scores and opponent's penalties
export const calculateUBW = (ippon: number, wazaari: number, yuko: number, opponent_shido: number, opponent_hsm: number) => {
    let ubw = 0;

    // Ippon (max. 1): 1 counts for 100P UBW
    if (ippon === 1) {
        ubw += 100;
    }

    // Wazaari (max. 2): 1 counts 10P, but 2 count for 100P UBW
    if (wazaari === 1) {
        ubw += 10;
    } else if (wazaari === 2) {
        ubw += 100;
    }

    // Yuko (max. 10): count for 1P UBW
    ubw += yuko;

    // Add points from opponent's penalties
    ubw += calculatePenaltyPoints(opponent_shido, opponent_hsm);

    // Cap UBW at 100
    return Math.min(ubw, 100);
};

// Calculate sieg (win) based on the UBW comparison
// Updated calculateSieg function in utils.ts
export const calculateSieg = (ubw_h: number, ubw_a: number) => {
    // Only set sieg when UBW reaches 100
    if (ubw_h >= 100 && ubw_a < 100) {
        return { sieg_h: 1, sieg_a: 0 };
    } else if (ubw_a >= 100 && ubw_h < 100) {
        return { sieg_h: 0, sieg_a: 1 };
    } else if (ubw_h >= 100 && ubw_a >= 100) {
        // If both have reached 100, higher UBW wins
        if (ubw_h > ubw_a) {
            return { sieg_h: 1, sieg_a: 0 };
        } else if (ubw_a > ubw_h) {
            return { sieg_h: 0, sieg_a: 1 };
        }
    }

    // If no side has 100 UBW or there's a tie at 100+, don't automatically set sieg
    return { sieg_h: 0, sieg_a: 0 };
};

