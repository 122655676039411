import React, {useState} from 'react';
import {Layout} from "../../components/foundation/layout";
import MemberDetail from "../../components/admin/member/member_detail";
import {Member} from "../../components/admin/member/member";
import {IPageProps} from "../../components/page.props";
import {withDesktop} from "../../api/withDesktop";
import {useParamRole} from "../../components/useParam";


const DesktopMemberPage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const gotoUrl = `/${role?.orgId}/${role?.funkMgId}/members`;
    const [refresh, setRefresh] = useState<number>(0)
    return (
        <Layout list={
            <Member {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh}
            />
        } page={
            <MemberDetail {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh}
            />
        } />
    );
};

export default withDesktop(DesktopMemberPage);