import {IFileModel} from "./models";
import {Constants} from "./constants";
import {HttpService} from "./http";

export const IMAGE_URL = `${Constants.JAMA_SERVER_API_V2}/web/profile/image/`;
const FILE_UPLOAD = `${Constants.JAMA_SERVER_API_V2}/admin/:org/upload`;

export enum EFileVersion {
    original,
    md,
    sm,
    xs
}

export const R = (r: string, org: number) => r.replace('/:org/', `/${org}/`);

export class UtilService {

    public static async Upload(org: number, data: FormData) : Promise<IFileModel> {
        return HttpService.postBinaryJson<IFileModel>(FILE_UPLOAD.replace('/:org/', `/${org}/`), data);
    }

    public static getFileUrl(file: IFileModel, version: EFileVersion) {
        switch (version) {
            case EFileVersion.original:
                return `${Constants.JAMA_SERVER_API}/uploads?file=${file?.fileName}`;
            case EFileVersion.md:
                return `${Constants.JAMA_SERVER_API}/uploads?file=${file?.fileParam?.thumbnails?.md}`;
            case EFileVersion.sm:
                return `${Constants.JAMA_SERVER_API}/uploads?file=${file?.fileParam?.thumbnails?.sm}`;
            case EFileVersion.xs:
                return `${Constants.JAMA_SERVER_API}/uploads?file=${file?.fileParam?.thumbnails?.xs}`;
        }
    }

    // uuid aka file.fileName is a UUIDv4 with striped dashes
    public static async DeleteFile(org: number, uuid: string): Promise<boolean> {
        return HttpService.delete<boolean>(FILE_UPLOAD.replace('/:org/', `/${org}/`) + `/${uuid}`);
    }

}