import React from 'react';
import {IMAGE_URL} from "../../../api";
import {useNavigate} from "react-router-dom";
import styles from "./profile.module.scss";
import {Button, DateLabel, Item, Label, PDS} from "../../form";
import CameraButton from "../../foundation/camera_button";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {IPageLinkProps} from "../../page.props";
import classNames from "classnames";
import { ImageCropDialog } from "../../foundation/ImageCropDialog";
import { Capacitor } from '@capacitor/core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faPen} from "@fortawesome/free-solid-svg-icons";
import { ProfileService } from "../../../api/profile";
import AvatarImage from "../../foundation/AvatarImage";
import {MemberService} from "../../../api/member";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

export const Profile: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    const [localProfile, setLocalProfile] = React.useState(profile || user);
    const [avatarKey, setAvatarKey] = React.useState<number>(0);
    const [isUploadingImage, setIsUploadingImage] = React.useState(false);
    const [isCropDialogOpen, setIsCropDialogOpen] = React.useState(false);
    const [initialImageUrl, setInitialImageUrl] = React.useState<string | null>(null);
    const [imageExists, setImageExists] = React.useState<boolean>(false);

    const navigate = useNavigate();
    const backgroundImage = IMAGE_URL + user?.imageLink + `?v=${Date.now()}`;
    const checkOpenRequests = localProfile?.trainerLicense.some(license => license.status === "created");

    console.log('localProfile?.license?.orgId:', localProfile?.license?.orgId);
    console.log('user?.license?.orgId:', user?.license?.orgId);
    console.log('checkOpenRequests:', checkOpenRequests);
    console.log(' localProfile?.trainerLicense:',  localProfile?.trainerLicense);

    const checkImageExists = async (imageLink: string | undefined) => {
        if (!imageLink) {
            setImageExists(false);
            return;
        }
        try {
            const response = await fetch(IMAGE_URL + imageLink);
            setImageExists(response.status === 200);
        } catch (error) {
            setImageExists(false);
        }
    };

    React.useEffect(() => {
        if (user?.imageLink) {
            checkImageExists(user.imageLink);
        } else {
            setImageExists(false);
        }
    }, [user?.imageLink]);

    console.log('local:', localProfile?.license?.orgId + ' user: ' + user?.license?.orgId + ' checkOpenReq: ' + checkOpenRequests);

    const handleTrainerLicenseClick = () => {
        const targetUrl = `${gotoUrl}/trainer`;

        // Option 1: Using window.location for direct navigation
        window.location.href = targetUrl;

        // Option 2: Or use both navigate and window.location for better compatibility
        navigate(targetUrl);
        setTimeout(() => {
            window.location.href = targetUrl;
        }, 100);
    };

    return (
        <div className={styles.headline}>
            <div className={styles.profile}>
                <h1 className={styles.name}>{user.title} {user.givenName.charAt(0).toUpperCase() + user.givenName.toLowerCase().slice(1)} {user.middleName && user.middleName.charAt(0).toUpperCase() + user.middleName.toLowerCase().slice(1)} {user.familyName.toUpperCase()}</h1>

                <div className={styles.container}>
                    <ItemNavGroup label={"Bild"} className={styles.bild}>
                        <div className={classNames(styles.headercontainer)}>
                            <div className={styles.profilecontainer}>
                                <AvatarImage
                                    key={avatarKey}
                                    src={MemberService.imageUrl(user?.imageLink)}
                                    className={styles.image}
                                />
                                <div className={styles.controls}>
                                    <CameraButton
                                        uuid={user.imageLink}
                                        className={styles.cameraButton}
                                        onClick={() => {
                                            if (!Capacitor.isNativePlatform()) {
                                                setIsCropDialogOpen(true);
                                            } else {
                                                setAvatarKey(prev => prev + 1);
                                            }
                                        }}
                                    />
                                    <div className={styles.imageControls}>
                                        <Button
                                            className={styles.editButton}
                                            onClick={async () => {
                                                try {
                                                    const response = await fetch(IMAGE_URL + user.imageLink);
                                                    const blob = await response.blob();
                                                    const imageUrl = URL.createObjectURL(blob);
                                                    setInitialImageUrl(imageUrl);
                                                    setIsCropDialogOpen(true);
                                                } catch (error) {
                                                    console.error('Error loading image for editing:', error);
                                                }
                                            }}
                                            disabled={!imageExists}
                                        >
                                            <FontAwesomeIcon icon={faPen}/>
                                        </Button>
                                        <Button
                                            className={styles.deleteButton}
                                            onClick={() => {
                                                if (user.imageLink) {
                                                    ProfileService.deleteProfilePicture(user.imageLink).then(() => {
                                                        setImageExists(false);
                                                        setAvatarKey(prev => prev + 1);
                                                    });
                                                }
                                            }}
                                            disabled={!imageExists}
                                        >
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ItemNavGroup>
                    <ItemNavGroup label={"Stammdaten"} className={`styles.row ${styles.stammdaten}`}>
                        <div className={styles.info}>
                            <Item className={styles.infoItem} type="split"
                                  label="JAMA-ID."><Label><b>{user.mg_id}</b></Label></Item>
                            <Item className={styles.infoItem} type="split"
                                  label="JudoManager-ID."><Label>{user.jr_id ? user.jr_id : '--'}</Label></Item>
                            <Item className={styles.infoItem} type="split"
                                  label="Nation."><Label>{user.nationality} / {user.IOC}</Label></Item>
                            <Item className={styles.infoItem} type="split" label="Geb.Dat."><DateLabel
                                value={PDS(user.birthDate)}/></Item>
                            <Item className={styles.infoItem} type="split"
                                  label="Adresse"><Label>{user.address}</Label></Item>
                            <Item className={styles.infoItem} type="split" label="PLZ"><Label>{user.zip}</Label></Item>
                            <Item className={styles.infoItem} type="split" label="Ort"><Label>{user.city}</Label></Item>
                            <Item className={styles.infoItem} type="split"
                                  label="Land"><Label>{user.countryOfResidence}</Label></Item>
                            <Item className={styles.infoItem} type="split"
                                  label="Geschlecht"><Label>{user.gender}</Label></Item>
                            <Item className={styles.infoItem} type="split"
                                  label="Mobil"><Label>{user.cellPhone}</Label></Item>
                            {user?.fixedLine && (
                                <Item className={styles.infoItem} type="split"
                                      label="Festnetz"><Label>{user.fixedLine}</Label></Item>)}
                            {user?.companyPhone && (
                                <Item className={styles.infoItem} type="split"
                                      label="Firma"><Label>{user.companyPhone}</Label></Item>)}
                            <Item className={styles.infoItem} type="split"
                                  label="Email"><Label>{user.email}</Label></Item>
                            {user?.nationchecked && (
                                <Item className={styles.infoItem} type="split" label="Nat.check"><DateLabel
                                    value={PDS(user.nationchecked)}/></Item>)}
                            {user?.medical && (<Item className={styles.infoItem} label="Medical"><DateLabel
                                value={PDS(user.medical)}/></Item>)}
                        </div>
                    </ItemNavGroup>
                    <ItemNavGroup label={"Prüfungen"} className={`${styles.row} ${styles.pruefungen}`}>
                        <div className={styles.info}>
                            {(user?.allGrades || []).map((item: any) =>
                                (<Item className={styles.infoItem} type="split" key={item?.name} label={item.name}>
                                    <Label>
                                        {new Date(item?.datum).toLocaleString('de-DE', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                        })}
                                    </Label>
                                </Item>)
                            )}
                        </div>
                    </ItemNavGroup>
                    <ItemNavGroup label={"Trainer-Lizenz"} className={`${styles.row} ${styles.trainerlizenz}`}>
                        <div className={classNames(styles.trainerbutton)}>
                            {
                                ((localProfile?.license?.orgId || 0) > 0 || (user?.license?.orgId || 0) > 0) && !checkOpenRequests
                                    ? (
                                        <Button
                                            //className={styles.hideOnMobile}
                                            width='full'
                                            label="Trainerlizenz beantragen"
                                            onClick={handleTrainerLicenseClick}
                                        />
                                    )
                                    : (
                                        <Button
                                            //className={styles.hideOnMobile}
                                            width='full'
                                            label="Trainerlizenz bereits beantragt!"
                                            disabled={false}
                                            onClick={handleTrainerLicenseClick}
                                        />
                                    )
                            }
                        </div>
                        {(localProfile?.trainerLicense).length > 0 ? (
                            // <ItemNavGroup label={"Trainer-Lizenz"} className={`${styles.row} ${styles.trainerlizenz}`}>
                            <div className={styles.info}>
                                {localProfile.trainerLicense.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()).map((license, index) => {
                                    let statusText = (license.status === 'rejected' || license.status === 'canceled') ? 'abgelehnt' :
                                        (license.status === 'created' ? 'in Arbeit' :
                                                license.status === 'approved_oejv' ? 'Lizenz eingetragen' :
                                                    license.status === 'approved_lv' ? 'genehmigt vom Landesverband' :
                                                        license.status === 'approved_jv' ? 'genehmigt vom Verein' : 'Fehler!'
                                        );
                                    return (
                                        <div key={index}
                                             className={`${styles.infoItem} ${index % 2 === 0 ? styles.even : styles.odd} ${license.status === 'rejected' ? styles.rejected : ''}`}>
                                            <Item type="split" label={`Antrag: ${license.orderId}`}>
                                                <Label className={
                                                    (license.status === 'rejected' || license.status === 'canceled') ?
                                                        styles.rejected :
                                                        (license.status === 'created' ?
                                                                styles.created : (
                                                                    license.status === 'approved_oejv' ? styles.approved :
                                                                        license.status.substring(0, 8) === 'approved' ? styles.created : ''
                                                                )
                                                        )
                                                }>{statusText}</Label>
                                            </Item>
                                            <Item type="split" label="Organisation">
                                                <Label>{license.orgName}</Label>
                                            </Item>
                                            {license.trainerLicenseName && (<Item type="split" label="LizenzTyp">
                                                <Label>{license.trainerLicenseName}</Label>
                                            </Item>)}
                                            <Item type="split" label="Aktualisiert am">
                                                <Label> {new Date(license.updatedAt).toLocaleString('de-DE')}</Label>
                                            </Item>
                                            <Item type="split" label="Kommentar">
                                                <Label> {license.comment}</Label>
                                            </Item>
                                        </div>
                                    )
                                })}
                            </div>

                        ) : ''}
                    </ItemNavGroup>
                </div>
            </div>

            {!Capacitor.isNativePlatform() && (
                <ImageCropDialog
                    isOpen={isCropDialogOpen}
                    onClose={() => {
                        setIsCropDialogOpen(false);
                        setInitialImageUrl(null);
                    }}
                    onSave={async (blob) => {
                        setIsUploadingImage(true);
                        try {
                            const formData = new FormData();
                            formData.append('file', blob, 'profile.jpg');

                            await ProfileService.uploadProfilePicture(user.imageLink!, formData);
                            setAvatarKey(prev => prev + 1);
                            setImageExists(true);
                        } finally {
                            setIsUploadingImage(false);
                            setIsCropDialogOpen(false);
                            setInitialImageUrl(null);
                        }
                    }}
                    initialImage={initialImageUrl}
                    aspectRatio={7/9}
                    cropShape="rect"
                    minWidth={350}
                    title="Profilbild bearbeiten"
                />
            )}
        </div>
    );
};