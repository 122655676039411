import React from 'react';
import {IPageProps} from "../../../../components/page.props";
import {EForms, useParamForm, useParamOrg} from "../../../../components/useParam";
import Judocards from "../../../desktop/judocards";
import AppAdminLicences from "./organization/licences";
import AppAdminEvent from "./organization/events";
import AppAdminOrganizations from "./organization/organizations";
import AppAdminKyu from "./organization/kyu";
import AppAdminMember from "./organization/member";
import { useParams } from 'react-router-dom';


export const AppForm: React.FC<IPageProps> = (props) => {
    const form = useParamForm();
    const params = useParams(); // Add this to debug
    const orgId = useParamOrg();
    const formType = form?.toLowerCase();
    switch (form) {
        case EForms.memberships:
            return <Judocards {...props} />
        case EForms.members:
            return <AppAdminMember {...props} />
        case EForms.kyu:
            return <AppAdminKyu {...props} />
        case EForms.licences:
            return <AppAdminLicences {...props} />
        case EForms.events:
            return <AppAdminEvent {...props} />
        case EForms.organizations:
            return <AppAdminOrganizations {...props} />
        /*case EForms.trainer:
            return <Trainer {...props} />*/
        default:
            return (
                <div>
                    <p>Form not found</p>
                    {/*<p>Debug info:</p>*/}
                    {/*<pre>*/}
                    {/*    {JSON.stringify({*/}
                    {/*        form,*/}
                    {/*        formType,*/}
                    {/*        params,*/}
                    {/*        url: window.location.pathname*/}
                    {/*    }, null, 2)}*/}
                    {/*</pre>*/}
                </div>
            );
    }
};