import React, {useEffect, useState} from 'react';
import {IPageLinkProps} from "../../page.props";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import styles from "./member_detail.module.scss";
import {Duplicate} from "../../member/duplicate";
import {BackButton, Button, DateInput, Form, Input, Item, PDS, Select} from "../../form";
import AvatarImage from "../../foundation/AvatarImage";
import {MemberService} from "../../../api/member";
import CameraButton from "../../foundation/camera_button";
import {IMember, IMemberDuplicate} from "../../../api/models/MemberModel";
import {useNavigate} from "react-router-dom";
import {useParamId, useParamRole} from "../../useParam";
import {toaster} from "../../../core/toaster";
import {CacheService} from "../../../api";
import {Loading} from "../../foundation/Loading";
import { ImageCropDialog } from "../../foundation/ImageCropDialog";
import { ProfileService } from "../../../api/profile";
import { Capacitor } from '@capacitor/core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faPen} from "@fortawesome/free-solid-svg-icons";


export const MemberDetailGeneral: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    const [loading, setLoading] = useState(true);
    const [member, setMember] = useState<IMember | null>();
    const [duplicates, setDuplicates] = useState<IMemberDuplicate[] | null>(null)
    const [selectedDuplicate, setSelectedDuplicate] = useState<IMemberDuplicate | null>(null)
    const [avatarKey, setAvatarKey] = useState<number>(0);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);
    const [initialImageUrl, setInitialImageUrl] = useState<string | null>(null);
    const navigate = useNavigate();
    const role = useParamRole(user);
    const id = useParamId();
    const [imageExists, setImageExists] = useState<boolean>(false);

    const checkImageExists = async (uuid: string | undefined) => {
        if (!uuid) {
            setImageExists(false);
            return;
        }
        try {
            const response = await fetch(MemberService.imageUrl(uuid)!);
            setImageExists(response.status === 200);
        } catch (error) {
            setImageExists(false);
        }
    };

    useEffect(() => {
        if (member?.uuid) {
            checkImageExists(member.uuid);
        } else {
            setImageExists(false);
        }
    }, [member?.uuid]);

    const profileControlsStyle = {
        position: 'absolute' as 'absolute',
        bottom: 0,
        right: 0,
        display: 'flex',
        gap: '8px',
        padding: '4px',
        background: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '0.5rem',
        margin: '8px',
        opacity: 0,
        transition: 'opacity 0.2s ease-in-out',
    };

    const profileContainerStyle = {
        position: 'relative' as 'relative',
        '&:hover .controls': {
            opacity: 1,
        }
    };


    useEffect(() => {
        // console.log('argh', id)
        if (user) {
            setLoading(true);
            if (id) {
                MemberService.get(role.orgId, id).then((m) => {
                    setMember(m);
                    setDuplicates(null);
                    setLoading(false);
                });
            } else {
                setMember({
                    email: '',
                    surname: '',
                    birthdate: "",
                    city: "",
                    country: 3,
                    doorNo: "",
                    firstname: "",
                    gender: 'm',
                    houseNo: "",
                    middlename: "",
                    mobile: "",
                    stair: "",
                    street: "",
                    title: "",
                    uuid: "",
                    nationality: 500,
                    weightClass: 0,
                    zip: 0,
                    add_1: "",
                    add_2: "",
                    add_3: "",
                    add_4: "",
                    add_5: "",
                    email2: "",
                    tel_4: "",
                    tel_5: "",
                    tel_festnetz: "",
                    tel_firma: "",
                    title2:"",
                });
                setDuplicates(null);
                setLoading(false);
            }
        }
    }, [user, id]);

    const setMemberParam = (param: keyof IMember) => {
        return (value: any) => {
            let m = member || {} as unknown as IMember;

            // @ts-ignore
            m[param] = value;
            setMember(Object.assign({}, m));
        }
        }

    const assignMembership = (m: IMemberDuplicate) => {
        //console.log(m)
        toaster(async () => {
            await MemberService.assignRoleSimple(role.orgId, m.id, 'MEMBERSHIP');
            navigate(`${gotoUrl}`);
        })
    }

    const onSubmit = (evt: any) => {
        evt.preventDefault();
        setLoading(true);
        if (member) {
            if (member.id) {
                toaster(async () => {
                    setMember(await MemberService.update(role.orgId, member));
                    await CacheService.clear();
                    setRefresh && setRefresh((refresh || 0) + 1)
                }, {
                    success: "Mitglied aktualisiert",
                    failure: "Speichern fehlgeschlagen"
                }, () => {
                    setLoading(false);
                });
            } else {
                toaster(async () => {
                    const m = await MemberService.create(role.orgId, member)
                    if (m.created) {
                        setMember(m.created);
                        await CacheService.clear();
                        navigate(`${gotoUrl}/${m.created.id}`)
                        setRefresh && setRefresh((refresh || 0) + 1)
                    } else if ((m.duplicates?.length || 0) > 0) {
                        setDuplicates(m.duplicates || [])
                        throw new Error('found duplicates')
                    }
                }, {
                    success: "Mitglied hinzugefügt",
                    failure: "Speichern fehlgeschlagen"
                }, () => {
                    setLoading(false);
                });
            }
        }
        //console.log(evt);
        return false;
    }

    const anyAssignable = (duplicates?.filter(x => x?.assignable)?.length || 0) > 0

    const currentYear = new Date(Date.now()).getFullYear()
    //console.log(profile)

    const disabled = !!(role.orgId > 10 && member?.id) ;
    const verstorben = member?.verstorben == 1;

    return (
        <>
            {loading && <Loading/>}
            <>
                    {/*<BackButton label={'zur Übersicht'} navigateTo={gotoUrl}/>*/}
                {duplicates ? <>
                    <ItemNavGroup label={member?.id ? `JAMA: #${member?.id} ${member?.judomanagerId && `| JudoManager #${member?.judomanagerId}`}` : "Neues Mitglied"}>
                    <div className={styles.somePadding}><h3>Die Person existiert bereits</h3></div>
                    {duplicates.map(d => (
                        <Duplicate selected={d.id === selectedDuplicate?.id} member={d}
                                   onClick={(x) => x.assignable && (x.id !== selectedDuplicate?.id ? setSelectedDuplicate(x) : setSelectedDuplicate(null))}/>
                    ))}
                    {anyAssignable ?
                        <Button width="full" disabled={selectedDuplicate == null} label="Zum Verein hinzufügen"
                                onClick={() => selectedDuplicate && assignMembership(selectedDuplicate)}/>
                        : <Item type="list">Ist bereits Mitglied in einem Verein. Setze dich mit deinem Landesverband in
                            Verbindung.</Item>}
                    </ItemNavGroup>
                </> : <ItemNavGroup label={member?.id ?`JAMA: #${member?.id} ${member?.judomanagerId ? `| JudoManager #${member?.judomanagerId}`:''}` : "Neues Mitglied anlegen"}  className={styles.itemnavgroup}>
                    <Form onSubmit={onSubmit} className={styles.form}>
                        <div className={styles.header}>
                            <div className={styles.profile}>
                                <AvatarImage
                                    key={avatarKey} // Key forces re-render when changed
                                    src={MemberService.imageUrl(member?.uuid)}
                                    className={styles.image}
                                />

                                {member?.id && (
                                    <div className={styles.controls}>
                                        <CameraButton
                                            className={styles.cameraButton}
                                            uuid={member?.uuid}
                                            onClick={() => {
                                                if (!Capacitor.isNativePlatform()) {
                                                    setIsCropDialogOpen(true);
                                                } else {
                                                    setAvatarKey(prev => prev + 1);
                                                }
                                            }}
                                        />
                                        <Button
                                            //className={styles.editButton}
                                            onClick={async () => {
                                                try {
                                                    const response = await fetch(MemberService.imageUrl(member.uuid)!);
                                                    const blob = await response.blob();
                                                    const imageUrl = URL.createObjectURL(blob);
                                                    setInitialImageUrl(imageUrl);
                                                    setIsCropDialogOpen(true);
                                                } catch (error) {
                                                    console.error('Error loading image for editing:', error);
                                                }
                                            }}
                                            disabled={!imageExists}
                                        >
                                            <FontAwesomeIcon icon={faPen} />
                                        </Button>
                                        <Button
                                            className={styles.deleteButton}
                                            onClick={() => {
                                                toaster(async () => {
                                                    await ProfileService.deleteProfilePicture(member.uuid!);
                                                    setImageExists(false); // Update image existence after deletion
                                                    setAvatarKey(prev => prev + 1);
                                                }, {
                                                    success: "Profilbild gelöscht",
                                                    failure: "Löschen fehlgeschlagen"
                                                });
                                            }}
                                            disabled={!imageExists}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className={styles.top}>
                            </div>
                        </div>
                            {/*<ItemNavGroup label={member?.id ? `JAMA #${member?.id}` : "Neues Mitglied anlegen"}>*/}
                                    {member?.id && <Item type="full" label="Titel vor" size={3}>
                                    <Input onChange={setMemberParam("title")} value={member?.title}
                                           disabled={false}/>
                                </Item>}
                                <Item type="full" label="Nachname" size={6} required={true}>
                                    <Input onChange={setMemberParam("surname")} value={member?.surname} required
                                           disabled={disabled}/>
                                </Item>
                                {member?.id && <Item type="full" label="Titel nach" size={3}>
                                    <Input onChange={setMemberParam("title2")} value={member?.title2}
                                           disabled={false}/>
                                </Item>}
                                <Item type="full" label="Vorname" size={6} required={true}>
                                    <Input onChange={setMemberParam("firstname")} value={member?.firstname} required
                                           disabled={disabled}/>
                                </Item>
                                {member?.id && <Item type="full" label="Zweiter Vorname" size={6}>
                                    <Input onChange={setMemberParam("middlename")} value={member?.middlename}
                                           disabled={false}/>
                                </Item>}

                                <Item type="full" label="Geb." size={6} required={true}>
                                    <DateInput value={PDS(member?.birthdate)} onChange={setMemberParam("birthdate")} required
                                               disabled={disabled}/>
                                </Item>
                                <Item type="full" label="Geschlecht" size={4}>
                                    <Select onChange={setMemberParam("gender")} value={member?.gender} options={[
                                        {label: 'M', value: 'm'},
                                        {label: 'W', value: 'w'},
                                        // {label: 'X', value: 'x'}
                                    ]} disabled={disabled}/>
                                </Item>

                            {/*</ItemNavGroup>*/}


                    {member?.id && <Item type="full" label="Nationalität" size={6} required={true}>
                        <Select type="number" onChange={setMemberParam("nationality")} value={member?.nationality}
                                options={profile?.countries?.map(x => ({
                                    label: x.name,
                                    value: x.id
                                }))}

                        />
                    </Item>}
                    {member?.id && <Item type="full" label="National. bestätigt" size={6}>
                        <DateInput
                            value={PDS(member?.nationchecked)}
                            onChange={setMemberParam("nationchecked")}
                            disabled={role.orgLevel !== 1 || disabled}
                        />
                    </Item>}
                    {member?.id && <Item type="full" label="Attest" size={6}>
                        <DateInput
                            value={PDS(member?.attest)}
                            onChange={setMemberParam("attest")}
                            //      disabled={role.orgLevel > 2 || disabled}
                        />
                    </Item>}

                    {member?.id && <Item type="full" label="Gew.Klasse" size={6}>
                        <Select type="number" onChange={setMemberParam("weightClass")} value={member?.weightClass}
                                options={profile?.weightClasses?.filter(x => {
                                    const age = currentYear - (PDS(member?.birthdate)?.getFullYear() || 0);
                                    return (age >= x.min && age <= x.max && (x.gender === member?.gender || member?.gender === 'x')) || x.max === 0;
                                }).map(x => ({
                                    label: `${x.label}`,
                                    value: x.id
                                }))}/>
                    </Item>}

                    {member?.id && <Item type="full" label="Straße" size={12} required={true}>
                        <Input onChange={setMemberParam("street")} value={member?.street} required/>
                    </Item>}
                    {member?.id && <Item type="full" label="Nr" size={4} required={true}>
                        <Input onChange={setMemberParam("houseNo")} value={member?.houseNo} required/>
                    </Item>}
                    {member?.id && <Item type="full" label="Stiege" size={4}>
                        <Input onChange={setMemberParam("stair")} value={member?.stair}/>
                    </Item>}
                    {member?.id && <Item type="full" label="Tür.Nr." size={4}>
                        <Input onChange={setMemberParam("doorNo")} value={member?.doorNo}/>
                    </Item>}
                    {member?.id && <Item type="full" label="PLZ" size={4} required={true}>
                        <Input onChange={setMemberParam("zip")} value={member?.zip} required/>
                    </Item>}
                    {member?.id && <Item type="full" label="Ort" size={4} required={true}>
                        <Input onChange={setMemberParam("city")} value={member?.city} required/>
                    </Item>}
                    {member?.id && <Item type="full" label="Land" size={4} required={true}>
                        <Select type="number" onChange={setMemberParam("country")} value={member?.country}
                                options={profile?.countries?.map(x => ({
                                    label: x.name,
                                    value: x.id
                                }))}/>
                    </Item>}

                    {member?.id && <Item type="full" label={<strong>Email</strong>} size={4} required={true}>
                        <Input onChange={setMemberParam("email")} value={member?.email}  maxLength={60}/>
                    </Item>}

                    {member?.id && <Item type="full" label="Tel/Mobil" size={4}>
                        <Input onChange={setMemberParam("mobile")} value={member?.mobile} maxLength={50} />
                    </Item>}
                    {member?.id && <Item type="full" label="TelFestnetz" size={4}>
                        <Input onChange={setMemberParam("tel_festnetz")} value={member?.tel_festnetz} maxLength={50} />
                    </Item>}
                    {member?.id && <Item type="full" label="TelFirma" size={4}>
                        <Input onChange={setMemberParam("tel_firma")} value={member?.tel_firma} maxLength={50} />
                    </Item>}


                    {member?.id && <Item type="full" label="Zusatzfeld 1 (max 50 Zeichen)" size={4}>
                        <Input onChange={setMemberParam("add_1")} value={member?.add_1} maxLength={50} />
                    </Item>}
                    {member?.id && <Item type="full" label="Zusatzfeld 2 (max 50 Zeichen)" size={4}>
                        <Input onChange={setMemberParam("add_2")} value={member?.add_2} maxLength={50} />
                    </Item>}
                    {member?.id && <Item type="full" label="Zusatzfeld 3 (max 50 Zeichen)" size={4}>
                        <Input onChange={setMemberParam("add_3")} value={member?.add_3} maxLength={50} />
                    </Item>}
                    {member?.id && <Item type="full" label="Zusatzfeld 4 (max 50 Zeichen)" size={4}>
                        <Input onChange={setMemberParam("add_4")} value={member?.add_4} maxLength={60}/>
                    </Item>}
                    {member?.id && <Item type="full" label="Zusatzfeld 5 (max 50 Zeichen)" size={4}>
                        <Input onChange={setMemberParam("add_5")} value={member?.add_5} maxLength={50} />
                    </Item>}
                    {member?.id && <Item type="full" label="Zusatzfeld 6 (readonly)" size={6}>
                        <Input disabled={true} value={member?.tel_4} />
                    </Item>}
                    {member?.id && <Item type="full" label="Zusatzfeld 7 (readonly)" size={6}>
                        <Input   disabled={true} value={member?.tel_5} />
                    </Item>}

                    {member?.id && <Item type="full" label="weite Notizen (max 60 Zeichen)" size={12}>
                        <Input onChange={setMemberParam("email2")} value={member?.email2} />
                    </Item>}




                    {/*{member?.isActive != false ?(*/}
                    {/*    <Button type="submit" width="full" label="Speichern" disabled={loading}/>*/}
                    {/*    ):<></>*/}
                    {/*}*/}
                    {member?.verstorben !== 1 ? (
                        <Button type="submit" width="full" label="Speichern" disabled={loading} className={styles.speichern}/>
                        ) : <></>}
                </Form>
                </ItemNavGroup>}
                {!Capacitor.isNativePlatform() && (
                    <ImageCropDialog
                        isOpen={isCropDialogOpen}
                        onClose={() => {
                            setIsCropDialogOpen(false);
                            setInitialImageUrl(null); // Clear the initial image when closing
                        }}
                        onSave={async (blob) => {
                            setIsUploadingImage(true);
                            try {
                                const formData = new FormData();
                                formData.append('file', blob, 'profile.jpg');

                                await ProfileService.uploadProfilePicture(member?.uuid!, formData);
                                setAvatarKey(prev => prev + 1);
                                setImageExists(true);
                            } finally {
                                setIsUploadingImage(false);
                                setIsCropDialogOpen(false);
                                setInitialImageUrl(null); // Clear the initial image after saving
                            }
                        }}
                        initialImage={initialImageUrl} // Add this prop
                        aspectRatio={7/9}
                        cropShape="rect"
                        minWidth={350}
                        title="Profilbild bearbeiten"
                    />
                )}
            </>
        </>
    );
};
