import React, { useEffect, useMemo, useState } from 'react';
import classNames from "classnames";
import styles from './ImageUrl.module.scss';

interface Thumbnails {
    xs?: string;
    sm?: string;
    md?: string;
}

interface FileParam {
    name: string;
    mime: string;
    thumbnails?: Thumbnails;
    original?: string;
    createdAt: string;
}

interface IImageUrlProps {
    fileParam: FileParam;
    size?: keyof Thumbnails;
    alt?: string;
    onError?: () => void;
    className?: string;
    baseUrl: string;
}

const ImageUrl: React.FC<IImageUrlProps> = ({
                                                fileParam,
                                                size = 'md',
                                                alt,
                                                onError,
                                                className,
                                                baseUrl
                                            }) => {
    const [active, setActive] = useState(false);
    const [failed, setFailed] = useState(false);

    const isImage = fileParam.mime.startsWith('image/');

    // Memoize the src and download URLs
    const urls = useMemo(() => {
        if (!fileParam?.name) return { src: undefined, download: undefined };

        if (isImage) {
            const baseImageUrl = `${baseUrl}/${fileParam.name}?view=inline`;
            return {
                src: size && fileParam.thumbnails?.[size]
                    ? `${baseImageUrl}&size=${size}`
                    : baseImageUrl,
                download: `${baseUrl}/${fileParam.name}?view=attachment`
            };
        } else {
            return {
                src: undefined,
                download: `${baseUrl}/${fileParam.name}`  // For non-images, default to download
            };
        }
    }, [fileParam, size, baseUrl, isImage]);

    useEffect(() => {
        setFailed(false);
        setActive(false);
    }, [urls.src]);

    const onErrorDefault = () => {
        setFailed(true);
        onError && onError();
    };

    // For non-image files, render a clickable element that triggers download
    if (!isImage) {
        const getFileIcon = (mime: string) => {
            if (mime.includes('pdf')) return '📄';
            if (mime.includes('word')) return '📝';
            if (mime.includes('excel') || mime.includes('spreadsheet')) return '📊';
            if (mime.includes('powerpoint') || mime.includes('presentation')) return '📑';
            if (mime.includes('text')) return '📃';
            if (mime.includes('zip') || mime.includes('compressed')) return '🗜️';
            return '📎';
        };

        return (
            <a
                href={urls.download}
                className={classNames(styles.fileLink, className)}
                download={fileParam.original || fileParam.name}
            >
                <div className={styles.fileContainer}>
                    <span className={styles.fileIcon}>{getFileIcon(fileParam.mime)}</span>
                    <span className={styles.fileName}>
                        {fileParam.original || fileParam.name}
                    </span>
                </div>
            </a>
        );
    }

    // For images that failed to load or missing src
    if (failed || !urls.src) {
        return (
            <div className={classNames(className, styles.errorContainer)}>
                Failed to load image
            </div>
        );
    }

    // For images
    return (
        <div className={styles.imageContainer}>
            <img
                className={classNames(className, styles.image, active && styles.active)}
                src={urls.src}
                loading="lazy"
                alt={alt || fileParam.original || fileParam.name}
                onError={onErrorDefault}
                onLoad={() => setActive(true)}
            />
            <a
                href={urls.download}
                download={fileParam.original || fileParam.name}
                className={styles.downloadButton}
                title="Download original"
            >
                ⬇️
            </a>
        </div>
    );
};

export default ImageUrl;