import React from 'react';
import styles from './Input.module.scss';
import classNames from "classnames";

interface IInputAreaProps {
    onChange?: (v: string) => any;
    label?: string;
    name?: string;
    placeholder?: string;
    disabled?: boolean;
    defaultValue?: string;
    value?: string | null;
    className?: string;
    required?: boolean;
    rows?: number;
    maxLength?: number;
}

export const InputArea: React.FC<IInputAreaProps> = ({
                                                         name,
                                                         label,
                                                         placeholder,
                                                         disabled,
                                                         onChange,
                                                         defaultValue,
                                                         value,
                                                         className,
                                                         required,
                                                         rows,
                                                         maxLength,
                                                     }) => {
    return (
        <>
            {label && <label className={styles.label}>{label}</label>}
            <textarea
                className={classNames(styles.input, className)}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                defaultValue={defaultValue}
                value={value || (defaultValue ? undefined : "")}
                onChange={onChange ? (e) => onChange(e.target.value) : undefined}
                required={required}
                rows={rows ? rows : 3}
                maxLength={maxLength}
            />
        </>
    );
};