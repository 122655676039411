// src/components/admin/organization/organization_detail.tsx
import React, { useEffect, useState } from 'react';
import { IPageLinkProps } from "../../page.props";
import { TabLayout } from "../../foundation/tab_layout";
import { faBuildingUser, faList, faAward } from "@fortawesome/free-solid-svg-icons";
import { useParamId, useParamRole } from "../../useParam";
import { useSearchParams } from "react-router-dom";
import { OrganizationService } from "../../../api/organization";
import { Loading } from "../../foundation/Loading";
import { BackButton } from "../../form";
import OrganizationDetailGeneral from "./organization_detail_general";
import OrganizationFunctions from "./organization_functions";
import OrganizationTrainings from "./organization_trainings";

const OrganizationDetail: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    const [loading, setLoading] = useState(true);
    const [organization, setOrganization] = useState<any | null>(null);
    const role = useParamRole(user);
    const paramId = useParamId();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('t');

    // If the id is null, set it to role.orgId
    let id: number | null = paramId;
    if (page != 'new' && id === null) {
        id = role.orgId;
    }

    useEffect(() => {
        if (role) {
            if (page === 'new') {
                setOrganization({ id: 0 });
                setLoading(false);
            } else if (id) {
                const fetchOrganization = async () => {
                    try {
                        setLoading(true);
                        const org = await OrganizationService.get(role.orgId, id || role.orgId);
                        setOrganization(org);
                    } catch (error) {
                        console.error(error);
                    } finally {
                        setLoading(false);
                    }
                };

                fetchOrganization();
            } else {
                setLoading(false);
            }
        }
    }, [role, id, page]);

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            {organization && organization.id !== 0 && (
                <BackButton label="Zurück zur Übersicht" navigateTo={gotoUrl} />
            )}

            <TabLayout
                items={[
                    {
                        label: 'Stamm\u00ADdaten',
                        icon: faBuildingUser,
                        component: <OrganizationDetailGeneral gotoUrl={gotoUrl} user={user} profile={profile} refresh={refresh} setRefresh={setRefresh} />,
                    },
                    ...(organization && organization.id !== 0
                        ? [
                            {
                                label: 'Aus\u00ADbil\u00ADdungen',
                                icon: faAward,
                                component: <OrganizationTrainings gotoUrl={gotoUrl} user={user} profile={profile} refresh={refresh} setRefresh={setRefresh} />,
                            },
                            {
                                label: 'Funk\u00ADtionen & Lizenzen',
                                icon: faList,
                                component: <OrganizationFunctions gotoUrl={gotoUrl} user={user} profile={profile} refresh={refresh} setRefresh={setRefresh} />,
                            },
                        ]
                        : []),
                ]}
            />
        </>
    );
};

export default OrganizationDetail;