import React, { useMemo,useState, useEffect } from "react";
import styles from "./application_desktop.module.scss";
import {HeaderDesktop} from "./header_desktop";
import {IPageProps} from "../page.props";
import {ReactChildrenType} from "../../core/children";
import   {NavigateFunction, useLocation, useNavigate} from "react-router-dom";
import {Button, Select} from "../../components/form";
import {GroupAdminRoles} from "../../util/roles";
import {useParamOrg, useParamRole} from "../useParam";
import {ProfileService} from "../../api";
import packageJson from '../../../package.json';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useHelp } from '../help/HelpContext';


interface IAppDesktopProps extends IPageProps {
    children?: ReactChildrenType;
    menu?: (navigate: NavigateFunction) => ReactChildrenType;
}

// application_desktop.tsx
export const ApplicationDesktop: React.FC<IAppDesktopProps> = ({children, user, profile, menu}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const role = useParamRole(user);
    const org = useParamOrg();
    const [hasSelectedOrg, setHasSelectedOrg] = useState(true);
    const version = packageJson.version || undefined;
    const [hasAuthorized, setHasAuthorized] = useState(false);
    const { openHelp } = useHelp();

    // Log when org changes
    useEffect(() => {
        if (org) {
            setHasSelectedOrg(true);
        } else {
            setHasSelectedOrg(false);
        }
    }, [org]);

    const adminRoles = useMemo(() => GroupAdminRoles(user), [user]);
    const orgRoles = useMemo(() =>
            org ? adminRoles[Object.keys(adminRoles).find(x => adminRoles[x]?.find(y => y.orgId === org)) || ''] || [] : [],
        [adminRoles, org, user]
    );
    const sortedOptions = useMemo(() => {
        if (!adminRoles) return [];

        return Object.values(adminRoles)
            .filter(roles => Array.isArray(roles))
            .reduce((acc, roles) => {
                roles.forEach(role => {
                    if (role && !acc.some(r => r.orgId === role.orgId)) {
                        acc.push(role);
                    }
                });
                return acc;
            }, [])
            .sort((a, b) => {
                if (a.orgLevel === b.orgLevel) {
                    return a.orgName.localeCompare(b.orgName);
                }
                return a.orgLevel - b.orgLevel;
            })
            .map(org => ({
                label: org.orgName + ' / ' + org.orgId,
                value: org.orgId
            }));
    }, [adminRoles]);

    const isProfilePage = location.pathname === '/admin/profile';

    const handleBack = () => {
        navigate(-1);
    };

    const handleOrgChange = (id: number) => {
        navigate(`/${id}`);
    };

    const disabled = orgRoles.length === 0;
    const authorizeRole = async (funkMg: string) => {
        const form = await ProfileService.getForm(org, Number(funkMg));
        setHasAuthorized(true);
        navigate(`/${org}/${funkMg}/${form}`);
    }
    return (
        <React.Fragment>
            <HeaderDesktop
                user={user}
                profile={profile}
                hasSelectedOrg={hasSelectedOrg}
                menu={isProfilePage ? (
                    <Button
                        label="Zurück"
                        onClick={handleBack}
                        width="full"
                    />
                ) : (
                    menu && menu(navigate)
                )}
            />
            <div className={styles.app}>
                {!children ? (
                    <div className={styles.center}>

                        {!isProfilePage && (
                            <>
                                {/*JUDOJAMA 2025*/}
                                <div className={styles.image}></div>

                                {sortedOptions.length > 0 && !org && !isProfilePage && (
                                    <div className={styles.item}>
                                        <Select
                                            placeholder={'Wähle deinen Verein/Verband'}
                                            type={'number'}
                                            value={org}
                                            className={styles.select}
                                            onChange={handleOrgChange}  // Use the new handler
                                            options={sortedOptions}
                                        />
                                    </div>
                                )}
                                {hasSelectedOrg && org && !disabled ? ( // Only show Form selector when org is selected
                                    <Select
                                        placeholder={'wähle Formular'}
                                        value={role?.funkMgId}
                                        className={styles.select}
                                        onChange={authorizeRole}
                                        options={orgRoles?.sort((a, b) => {
                                            const numA = parseInt(a.functionName.replace(/^F/, ''), 10);
                                            const numB = parseInt(b.functionName.replace(/^F/, ''), 10);
                                            return numA - numB;
                                        }).map(x => ({
                                            label: x.functionName,
                                            value: x.funkMgId,
                                        }))}
                                        disabled={disabled}
                                    />
                                ) : null}
                                <div>
                                    <Button
                                        className={styles.helplink}
                                        width={"full"}
                                        label={"🛟 Hilfe"}
                                        onClick={() => openHelp('vereine')}
                                    />
                                        <div className={styles.version}>Version: {version}</div>
                                </div>
                            </>
                        )}
                    </div>
                ) : children}
            </div>
        </React.Fragment>
    );
};