// src/components/admin/organization/organization_detail_general.tsx
import React, {useEffect, useState} from 'react';
import {IAdminOrganization} from "../../../api/models";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {Button, DateInput, DTS, Form, Input, Item, Label, PDS, Segment, Select} from "../../form";
import {useParamId, useParamRole} from "../../useParam";
import {toaster} from "../../../core/toaster";
import {OrganizationService} from "../../../api/organization";
import {Loading} from "../../foundation/Loading";
import {useNavigate} from "react-router-dom";
import {useSearchParams} from "react-router-dom";

import {IPageLinkProps} from "../../page.props";
import styles from "./organization_detail.module.scss";

const OrganizationDetailGeneral: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [start, setStart] = useState<Date | null>(null);
    const [end, setEnd] = useState<Date | null>(null);
    const [zvr, setZvr] = useState<number | null>(null);
    const [dvr, setDvr] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [short, setShort] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [houseNumber, setHouseNumber] = useState<string>("");
    const [zip, setZip] = useState<number | null>(null);
    const [city, setCity] = useState<string>("");
    const [dachverband, setDachverband] = useState<string>("");
    const [altAddress, setAltAddress] = useState<string>("");
    const [altStreet, setAltStreet] = useState<string>("");
    const [altHouseNumber, setAltHouseNumber] = useState<string>("");
    const [altZip, setAltZip] = useState<number | null>(null);
    const [altCity, setAltCity] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [homepage, setHomepage] = useState<string>("");
    const [phoneOffice, setPhoneOffice] = useState<string>("");
    const [faxOffice, setFaxOffice] = useState<string>("");
    const [judopass, setJudopass] = useState<boolean>(false);
    const role = useParamRole(user);
    const paramId = useParamId();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('t');

    // If the id is null, set it to role.orgId
    let id: number | null = paramId;
    if (page != 'new' && id === null) {
        id = role.orgId;
    }

    // Landesverbände
    const [parent, setParent] = useState<number | undefined>(undefined);
    const parentOptions = profile.organizations
        .filter(org => (org.orgLevel == 2 || org.orgLevel == 1))
        .map(org => ({
            label: org.nameShort,
            value: org.id
        }));

    useEffect(() => {
        if (role) {
            if (page === 'new') {
                setParent(role.orgLevel === 2 ? role.orgId : undefined);
                setStart(null);
                setEnd(null);
                setZvr(null);
                setDvr('');
                setName('');
                setShort('');
                setStreet('');
                setHouseNumber('');
                setZip(null);
                setCity('');
                setDachverband('');
                setAltAddress('');
                setAltStreet('');
                setAltHouseNumber('');
                setAltZip(null);
                setAltCity('');
                setEmail('');
                setHomepage('');
                setPhoneOffice('');
                setFaxOffice('');
                setJudopass(false);
                setLoading(false);
            } else if (id) {
                const fetchOrganization = async () => {
                    try {
                        setLoading(true);
                        const org = await OrganizationService.get(role.orgId, id || role.orgId);
                        setParent(org.parent);
                        setStart(PDS(org.start));
                        setEnd(PDS(org.end));
                        setZvr(org.zvr);
                        setDvr(org.dvr || '');
                        setName(org.name);
                        setShort(org.short);
                        setStreet(org.street);
                        setHouseNumber(org.houseNumber);
                        setZip(org.zip);
                        setCity(org.city);
                        setDachverband(org.dachverband);
                        setAltAddress(org.altAddress || '');
                        setAltStreet(org.altStreet || '');
                        setAltHouseNumber(org.altHouseNumber || '');
                        setAltZip(org?.altZip ?? null);
                        setAltCity(org.altCity || '');
                        setEmail(org.email);
                        setHomepage(org.homepage || '');
                        setPhoneOffice(org.phoneOffice || '');
                        setFaxOffice(org.faxOffice || '');
                        setJudopass(org.judopass);
                    } catch (error) {
                        console.error(error);
                    } finally {
                        setLoading(false);
                    }
                };

                fetchOrganization();
            } else {
                setLoading(false);
            }
        }
    }, [role, id, page]);

    const onSave = () => {
        let errorMessage = 'Fehler beim Speichern';  // Default message
        toaster(async () => {
            console.log('Starting save operation...');
            setLoading(true);
            const data = {
                id,
                parent,
                start: start ? DTS(start) : null,
                end: end === null || end === undefined ? null : DTS(end),
                zvr,
                dvr,
                name,
                short,
                street,
                houseNumber,
                zip,
                city,
                dachverband,
                altAddress,
                altStreet,
                altHouseNumber,
                altZip,
                altCity,
                email,
                homepage,
                phoneOffice,
                faxOffice,
                judopass
            } as IAdminOrganization

            try {
                if (id) {
                    await OrganizationService.update(role.orgId, id || role.orgId, data);
                    if (setRefresh) {
                        setRefresh(prev => prev + 1);
                    }
                } else {
                    const newOrg = await OrganizationService.create(role.orgId, data);
                    navigate(`${gotoUrl}/${newOrg.id}`);
                    if (setRefresh) {
                        setRefresh(prev => prev + 1);
                    }
                }
            } catch (error: any) {
                console.error('Error saving organization:', error);
                if (error.result?.error) {
                    errorMessage = `Fehler beim Speichern: ${error.result.error}`;
                } else if (error.message) {
                    errorMessage = `Fehler beim Speichern: ${error.message}`;
                }
                throw new Error(errorMessage);
            }
        }, {
            success: id ? 'Organisation aktualisiert' : 'Organisation erstellt',
            failure: errorMessage
        }, () => {
            setLoading(false);
        });
    }

    const disabled = (role.orgLevel > 2);
    const disabledOejv = (role.orgLevel > 1);

    const selectedParentOption = parentOptions.find(option => option.value === parent);

    return (
        <>
            {loading && <Loading/>}
            <ItemNavGroup label={`Stammdaten | ${short}`}>
                <Form>
                    <Item type="full" label="Name" size={12}>
                        <Input value={name} onChange={setName} required disabled={disabled}/>
                    </Item>

                    <Item type="list" label="Org-ID" size={2}>
                        <Input value={id} disabled={true}/>
                    </Item>
                    <Item type="full" label="Verband" size={4}>
                        {role.orgLevel === 1 && id === undefined ? (
                            // OJV admin creating new org: show dropdown
                            <Select value={parent} onChange={setParent} options={parentOptions} disabled={false}/>
                        ) : role.orgLevel === 2 && id === undefined ? (
                            // LV admin creating new org: set parent to current orgId
                            <Input value={role.orgName} disabled={true}/>
                        ) : (
                            // Editing existing org: show parent name
                            <Input value={selectedParentOption ? selectedParentOption.label : role.orgName} disabled={true}/>
                        )}
                    </Item>
                    <Item type="full" label="Kurzbezeichnung" size={6}>
                        <Input value={short} onChange={setShort} disabled={disabled}/>
                    </Item>

                    <Item type="full" label="Gruendungsdatum" size={6}>
                        <DateInput value={start} onChange={setStart} required disabled={disabled}               />
                    </Item>
                    <Item type="full" label="Vereinsende" size={6}>
                        <DateInput value={end} onChange={setEnd} disabled={disabledOejv}/>
                    </Item>
                    <Item type="full" label="ZVR Nummer" size={6}>
                        <Input type="number" value={zvr} onChange={setZvr} required disabled={disabled}/>
                    </Item>
                    <Item type="full" label="DVR Nummer" size={6}>
                        <Input value={dvr} onChange={setDvr} disabled={disabled}/>
                    </Item>
                    <Item type="full" label="Strasse" size={8}>
                        <Input value={street} onChange={setStreet} disabled={disabled}/>
                    </Item>
                    <Item type="full" label="Hausnummer" size={4}>
                        <Input value={houseNumber} onChange={setHouseNumber} required disabled={disabled}/>
                    </Item>
                    <Item type="full" label="PLZ" size={3}>
                        <Input type="number" value={zip} onChange={setZip} required disabled={disabled}/>
                    </Item>
                    <Item type="full" label="Ort" size={9}>
                        <Input value={city} onChange={setCity} disabled={disabled}/>
                    </Item>
                    <Item type="full" label="Dachverband" size={12}>
                        <Select value={dachverband} onChange={setDachverband} options={[
                            {label: 'Keiner', value: '--'},
                            {label: 'ASVÖ', value: 'ASVÖ'},
                            {label: 'ASKÖ', value: 'ASKÖ'},
                            {label: 'UNION', value: 'UNION'},
                        ]} disabled={disabled}/>
                    </Item>
                </Form>
            </ItemNavGroup>
            <ItemNavGroup label={`Optionale Zustelladresse`}>
                <Form>
                    <Item type="full" label="Name Postempfaenger (c/o)">
                        <Input value={altAddress} onChange={setAltAddress}/>
                    </Item>
                    <Item type="full" label="Strasse" size={8}>
                        <Input value={altStreet} onChange={setAltStreet}/>
                    </Item>
                    <Item type="full" label="Hausnummer" size={4}>
                        <Input value={altHouseNumber} onChange={setAltHouseNumber}/>
                    </Item>
                    <Item type="full" label="PLZ" size={3}>
                        <Input type="number" value={altZip} onChange={setAltZip}/>
                    </Item>
                    <Item type="full" label="Ort" size={9}>
                        <Input value={altCity} onChange={setAltCity}/>
                    </Item>
                </Form>
            </ItemNavGroup>
            <ItemNavGroup label={`Kontaktdaten`}>
                <Form>
                    <Item type="full" label="Email" size={12}>
                        <Input value={email} onChange={setEmail}/>
                    </Item>
                    <Item type="full" label="Homepage" size={12}>
                        <Input value={homepage} onChange={setHomepage}/>
                    </Item>
                    <Item type="full" label="Telefon(Buero)" size={8}>
                        <Input value={phoneOffice} onChange={setPhoneOffice}/>
                    </Item>
                </Form>
            </ItemNavGroup>
            <Button type="button" width="full" label="Speichern" onClick={onSave}
                    className={styles.saveButton} />
        </>
    )
};

export default OrganizationDetailGeneral;