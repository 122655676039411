import React from 'react';
import { Document, Font, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import 'moment/locale/de';
import styles from './licence_detail_candidates.module.scss';
import { ILicenceCandidate, ILicenceOrder } from "../../../api/models";
import {Button} from "../../form";

import judoLogo from '../../../theme/editions/judoaustria/images/judo_logo.png';

// Interface for order details
interface OrderDetails {
    orgName: string;
    items: number;
    created: string;
    completed: string | undefined;
    status: string;
}
// Format date for PDF
const formatPdfDate = (dateString: string): string => {
    if (!dateString) return '';
    return moment(dateString).format('DD.MM.YYYY');
};

// Define interface for OrderSummaryPDF props
interface OrderSummaryPDFProps {
    order: ILicenceOrder | null;
    orderDetails: OrderDetails;
    candidates: ILicenceCandidate[];
}

// Define styles for PDF with increased left margin and additional columns
const pdfStyles = StyleSheet.create({
    page: {
        padding: 40,
        paddingTop: 60,
        paddingLeft: 65, // Increase left padding to 25mm (approximately 45 points)
        fontSize: 12,
        position: 'relative', // For positioning the page number
    },
    header: {
        fontSize: 18,
        marginTop:15,
        marginBottom: 10,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    summary: {
        marginBottom: 20,
        padding: 10,
        borderBottom: '1pt solid #ccc',
    },
    summaryRow: {
        flexDirection: 'row',
        marginBottom: 5,
    },
    summaryLabel: {
        width: '30%',
        fontWeight: 'bold',
    },
    summaryValue: {
        width: '70%',
    },
    table: {
        width: '100%',
    },
    tableHeader: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        borderBottomStyle: 'solid',
        padding: 5,
        backgroundColor: '#f0f0f0',
        fontWeight: 'bold',
    },
    tableRow: {
        flexDirection: 'row',
        padding: 5,
    },
    tableRowEven: {
        backgroundColor: '#f1f1f1',
    },
    tableRowOdd: {
        backgroundColor: '#ffffff',
    },
    // Add index column
    indexCol: {
        width: '6%',
        textAlign: 'right',
        paddingRight: 5,
    },
    // Adjust other column widths
    nameCol: {
        width: '30%',
    },
    firstnameCol: {
        width: '30%',
    },
    dateCol: {
        width: '15%',
    },
    // Add JAMA-ID column
    idCol: {
        width: '17%',
    },
    // Styles for page numbers and filename
    pageNumber: {
        position: 'absolute',
        bottom: 20,
        right: 20,
        fontSize: 10,
        color: '#666',
    },
    fileName: {
        position: 'absolute',
        bottom: 20,
        left: 45, // Maintain the increased left margin
        fontSize: 8,
        color: '#666',
    },
    // Style for the logo in the top right corner
    logo: {
        position: 'absolute',
        top: 20,
        right: 20,
        width: '70px', // approximately 25mm
    },
});

// Create a PDF document component for order summary
export const OrderSummaryPDF: React.FC<OrderSummaryPDFProps> = ({ order, orderDetails, candidates }) => {
    // Filter candidates that are ordered
    const orderedCandidates = candidates.filter((jc: ILicenceCandidate) => jc.ordered);

    // Define different number of candidates per page based on whether it's the first page (with header) or not
    const FIRST_PAGE_CANDIDATES = 21; // Fewer candidates on first page due to header/summary
    const OTHER_PAGES_CANDIDATES = 29; // More candidates on subsequent pages

    // Calculate how many total pages we need
    const calculateTotalPages = (): number => {
        if (orderedCandidates.length <= FIRST_PAGE_CANDIDATES) {
            return 1; // Everything fits on the first page
        } else {
            // First page + additional pages for remaining candidates
            const remainingCandidates = orderedCandidates.length - FIRST_PAGE_CANDIDATES;
            return 1 + Math.ceil(remainingCandidates / OTHER_PAGES_CANDIDATES);
        }
    };

    const totalPages = calculateTotalPages();

    // Function to get candidates for a specific page
    const getCandidatesForPage = (pageIndex: number): ILicenceCandidate[] => {
        if (pageIndex === 0) {
            // First page takes fewer candidates
            return orderedCandidates.slice(0, FIRST_PAGE_CANDIDATES);
        } else {
            // Calculate start index for subsequent pages
            const startIndex = FIRST_PAGE_CANDIDATES + (pageIndex - 1) * OTHER_PAGES_CANDIDATES;
            return orderedCandidates.slice(
                startIndex,
                startIndex + OTHER_PAGES_CANDIDATES
            );
        }
    };

    // Function to calculate the absolute index for a candidate
    const getAbsoluteIndex = (pageIndex: number, localIndex: number): number => {
        if (pageIndex === 0) {
            return localIndex + 1; // 1-based indexing
        } else {
            return FIRST_PAGE_CANDIDATES + (pageIndex - 1) * OTHER_PAGES_CANDIDATES + localIndex + 1;
        }
    };

    // Generate a meaningful filename based on order information
    const generateFileName = (): string => {
        const orderId = order?.id || 'unknown';
        const orgName = orderDetails.orgName.replace(/\s+/g, '-').toLowerCase();
        const today = moment().format('YYYY-MM-DD');
        return `bestellung-${orderId}-${orgName}-${today}.pdf`;
    };

    const fileName = generateFileName();

    return (
        <Document>
            {Array.from({ length: totalPages }, (_, pageIndex) => {
                // Get candidates for this page
                const pageItems = getCandidatesForPage(pageIndex);

                return (
                    <Page
                        key={`page_${pageIndex}`}
                        size="A4"
                        style={pdfStyles.page}
                    >
                        {/* Logo in the top right corner (on every page) */}
                        <Image
                            src={judoLogo}
                            style={pdfStyles.logo}
                            fixed
                        />

                        {/* Only show header and summary on first page */}
                        {pageIndex === 0 && (
                            <>
                                <Text style={pdfStyles.header}>Judocardbestellung Nr.:  {order?.id} - Zusammenfassung</Text>

                                <View style={pdfStyles.summary}>
                                    <View style={pdfStyles.summaryRow}>
                                        <Text style={pdfStyles.summaryLabel}>Organisation:</Text>
                                        <Text style={pdfStyles.summaryValue}>{orderDetails.orgName}</Text>
                                    </View>
                                    <View style={pdfStyles.summaryRow}>
                                        <Text style={pdfStyles.summaryLabel}>Bestellnummer:</Text>
                                        <Text style={pdfStyles.summaryValue}>#{order?.id}</Text>
                                    </View>
                                    <View style={pdfStyles.summaryRow}>
                                        <Text style={pdfStyles.summaryLabel}>Anzahl:</Text>
                                        <Text style={pdfStyles.summaryValue}>{orderDetails.items}</Text>
                                    </View>
                                    <View style={pdfStyles.summaryRow}>
                                        <Text style={pdfStyles.summaryLabel}>Status:</Text>
                                        <Text style={pdfStyles.summaryValue}>{orderDetails.status}</Text>
                                    </View>
                                    <View style={pdfStyles.summaryRow}>
                                        <Text style={pdfStyles.summaryLabel}>Erstellt:</Text>
                                        <Text style={pdfStyles.summaryValue}>{orderDetails.created} - {order?.creator}</Text>
                                    </View>
                                    {orderDetails.completed && (
                                        <View style={pdfStyles.summaryRow}>
                                            <Text style={pdfStyles.summaryLabel}>Abgeschlossen:</Text>
                                            <Text style={pdfStyles.summaryValue}>{orderDetails.completed}</Text>
                                        </View>
                                    )}
                                    {order?.kyuExamId && (
                                        <View style={pdfStyles.summaryRow}>
                                            <Text style={pdfStyles.summaryLabel}>KYU Bestellung:</Text>
                                            <Text style={pdfStyles.summaryValue}>#{order.kyuExamId}</Text>
                                        </View>
                                    )}
                                </View>
                            </>
                        )}

                        {/* Table header on each page */}
                        <View style={pdfStyles.table}>
                            <View style={pdfStyles.tableHeader}>
                                <Text style={pdfStyles.indexCol}>Nr.</Text>
                                <Text style={pdfStyles.nameCol}>Nachname</Text>
                                <Text style={pdfStyles.firstnameCol}>Vorname</Text>
                                <Text style={pdfStyles.dateCol}>Geb.Dat.</Text>
                                <Text style={pdfStyles.idCol}>JAMA-ID</Text>
                            </View>

                            {/* Show items for this page */}
                            {pageItems.map((jc: ILicenceCandidate, index: number) => {
                                // Calculate the absolute index across all pages
                                const absoluteIndex = getAbsoluteIndex(pageIndex, index);

                                return (
                                    <View
                                        key={jc.mgId}
                                        style={[
                                            pdfStyles.tableRow,
                                            index % 2 === 0 ? pdfStyles.tableRowEven : pdfStyles.tableRowOdd
                                        ]}
                                    >
                                        <Text style={pdfStyles.indexCol}>{absoluteIndex}.</Text>
                                        <Text style={pdfStyles.nameCol}>{jc.surname}</Text>
                                        <Text style={pdfStyles.firstnameCol}>{jc.firstname}</Text>
                                        <Text style={pdfStyles.dateCol}>{formatPdfDate(jc.birthdate)}</Text>
                                        <Text style={pdfStyles.idCol}>{jc.mgId}</Text>
                                    </View>
                                );
                            })}
                        </View>

                        {/* Filename at the bottom-left of each page */}
                        <Text style={pdfStyles.fileName} fixed>
                            {fileName}
                        </Text>

                        {/* Page number at the bottom-right of each page */}
                        <Text
                            style={pdfStyles.pageNumber}
                            render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`}
                            fixed
                        />
                    </Page>
                );
            })}
        </Document>
    );
};

// Define interface for OrderSummaryPDFViewer props
interface OrderSummaryPDFViewerProps {
    order: ILicenceOrder | null;
    orderDetails: OrderDetails;
    candidates: ILicenceCandidate[];
    onClose: () => void;
}

// PDF Viewer Component with modal
export const OrderSummaryPDFViewer: React.FC<OrderSummaryPDFViewerProps> = ({ order, orderDetails, candidates, onClose }) => {
    return (
        <div className={styles.pdfViewer}>
            <div className={styles.pdfViewerHeader}>
                <h3>PDF Vorschau</h3>
                <Button
                    className={styles.closePdfButton}
                    onClick={onClose}
                >
                    <FontAwesomeIcon icon={faRemove} />Schließen
                </Button>
            </div>
            <div className={styles.pdfViewerContent}>
                <PDFViewer width="100%" height="100%">
                    <OrderSummaryPDF
                        order={order}
                        orderDetails={orderDetails}
                        candidates={candidates.filter((jc: ILicenceCandidate) => jc.ordered)}
                    />
                </PDFViewer>
            </div>
        </div>
    );
};