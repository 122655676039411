import React from 'react';
import { IUserModel } from "../../../api/models";

interface IMemberListProps {
    user: IUserModel;
}

const MemberListComponent: React.FC<IMemberListProps> = ({ user }) => {
    return (
        <div>
            <h2>Mitgliederliste</h2>
            <p>Diese Ansicht zeigt alle Mitglieder mit Lizenzen.</p>
            {/* Add your member list implementation here */}
        </div>
    );
};

export default MemberListComponent;