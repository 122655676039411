import React, {useRef, useState} from 'react';
import {withDesktop} from "../../api/withDesktop";
import Licence from "../../components/admin/licences/licence";
import {IPageProps} from "../../components/page.props";
import {Layout} from "../../components/foundation/layout";
import {LicenceDetail} from "../../components/admin/licences/licence_detail";
import {useParamRole} from "../../components/useParam";
import moment from 'moment';
import printStyles from '../../theme/print.module.scss';

export const DesktopLicencesPage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const gotoUrl = `/${role?.orgId}/${role?.funkMgId}/licences`;
    const [refresh, setRefresh] = useState<number>(0);

    // Add state for date filtering
    const [dateFrom, setDateFrom] = useState<Date|null>(moment().subtract(1, 'year').toDate());
    const [dateTo, setDateTo] = useState<Date|null>(moment().add(1, 'month').toDate());
    const [filteredOrders, setFilteredOrders] = useState<any[]>([]);

    return (
        <Layout
            listClassName={printStyles['hide-for-print']}
            list={
                <Licence
                    {...props}
                    gotoUrl={gotoUrl}
                    refresh={refresh}
                    onOrdersFiltered={(orders: any[]) => setFilteredOrders(orders)}
                />
            } page={
                <LicenceDetail
                    {...props}
                    gotoUrl={gotoUrl}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    filteredOrders={filteredOrders}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                />
            }
        />
    );
};
export default withDesktop(DesktopLicencesPage)