    // src/components/help/HelpWindow.tsx
    import React, { useEffect, useState } from 'react';
    import ReactMarkdown from 'react-markdown';
    import styles from './HelpWindow.module.scss';
    import markdownStyles from './markdown.module.scss';
    import {Button} from '../form/Button';

    interface HelpWindowProps {
        section?: string;
        page?: string;
        anchor?: string;
        onClose?: () => void;
        isMobile?: boolean;
    }

    export const HelpWindow: React.FC<HelpWindowProps> = ({
                                                              section,
                                                              page,
                                                              anchor,
                                                              onClose,
                                                              isMobile
                                                          }) => {
        const [content, setContent] = useState<string>('');
        const [isLoading, setIsLoading] = useState(true);
        const [error, setError] = useState<string | null>(null);

        useEffect(() => {
            const loadContent = async () => {
                setIsLoading(true);
                setError(null);
                try {
                    const helpPath = page
                        ? `/help/sections/${section}/${page}.md`
                        : `/help/sections/${section}/index.md`;

                    console.log('Fetching help content from:', helpPath);

                    const response = await fetch(helpPath, {
                        headers: {
                            'Accept': 'text/markdown,text/plain',
                            'Cache-Control': 'no-cache'
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Failed to load help content (${response.status})`);
                    }

                    const text = await response.text();

                    if (text.trim().startsWith('<!DOCTYPE html>')) {
                        throw new Error('Invalid help content received');
                    }

                    setContent(text);
                } catch (error) {
                    console.error('Help content loading error:', error);
                    setError(error instanceof Error ? error.message : 'Failed to load help content');
                } finally {
                    setIsLoading(false);
                }
            };

            if (section) {
                loadContent();
            }
        }, [section, page]);

        // Function to handle internal navigation
        const handleInternalLink = (href: string) => {
            console.log('Handling link:', href); // Debug logging

            if (href.startsWith('/help/sections/')) {
                // Clean up any double slashes and normalize the path
                const cleanPath = href.replace(/\/+/g, '/');
                const parts = cleanPath.split('/').filter(Boolean); // Remove empty parts

                // parts should be ['help', 'sections', 'vereine', 'F51.md']
                if (parts.length >= 4) {
                    const newSection = parts[2];
                    const newPage = parts[3].replace('.md', '');

                    console.log('Loading new section:', newSection, 'page:', newPage);

                    // Load the content
                    const loadContent = async () => {
                        try {
                            const response = await fetch(`/help/sections/${newSection}/${newPage}.md`, {
                                headers: {
                                    'Accept': 'text/markdown,text/plain',
                                    'Cache-Control': 'no-cache'
                                }
                            });

                            if (!response.ok) throw new Error('Failed to load content');

                            const text = await response.text();
                            setContent(text);
                        } catch (error) {
                            console.error('Error loading content:', error);
                            setError('Failed to load content');
                        }
                    };

                    loadContent();
                }
                return false; // Prevent default link behavior
            }
            return true; // Allow external links
        };

        const resolveImagePath = (src: string) => {
            if (src.startsWith('./')) {
                // Remove the leading './' and construct the full path
                const imagePath = src.substring(2);
                return `/help/sections/${section}/${imagePath}`;
            }
            return src;
        };


        return (
            <div className={`${styles.helpWindow} ${isMobile ? styles.mobile : ''}`}>

                {onClose && (
                    <Button
                        className={styles.closeButton}
                        onClick={onClose}
                        label={"schließen"}
                    />
                )}
                <div className={styles.helpContent}>
                    {isLoading ? (
                        <div className={styles.loading}>
                            <div className={styles.spinner}></div>
                            <span>Laden...</span>
                        </div>
                    ) : error ? (
                        <div className={styles.errorContent}>
                            <p>{error}</p>
                        </div>
                    ) : (
                        <div className={styles.markdownContent}>
                            <ReactMarkdown
                                components={{
                                    img: ({src, alt}) => (
                                        <img
                                            src={src ? resolveImagePath(src) : ''}
                                            alt={alt || ''}
                                            className={styles.helpImage}
                                        />
                                    ),
                                    a: ({href, children}) => {
                                        if (!href) return <>{children}</>;
                                        return (
                                            <a
                                                href={href}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleInternalLink(href);
                                                }}
                                            >
                                                {children}
                                            </a>
                                        );
                                    },
                                }}
                            >
                                {content}
                            </ReactMarkdown>
                        </div>
                    )}
                </div>
            </div>
        );
    };