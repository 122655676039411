import React, {useState, useEffect, useRef, useMemo} from "react";
import ReactCrop, {Crop, PixelCrop} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import styles from './ImageCropDialog.module.scss';

interface ImageCropDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (blob: Blob) => Promise<void>;
    aspectRatio?: number;
    cropShape?: 'rect' | 'round';
    minWidth?: number;
    minHeight?: number;
    title?: string;
    initialImage?: string | null;
}

export const ImageCropDialog: React.FC<ImageCropDialogProps> = ({
                                                                    isOpen,
                                                                    onClose,
                                                                    onSave,
                                                                    aspectRatio = 1,
                                                                    cropShape = 'round',
                                                                    minWidth = 350,
                                                                    minHeight = 450,
                                                                    title = 'Bild zuschneiden',
                                                                    initialImage = null
                                                                }) => {
    const [imgSrc, setImgSrc] = useState<string>('');
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const imgRef = useRef<HTMLImageElement>(null);
    const [isLoading, setIsLoading] = useState(false);

    // Handle initial image when dialog opens
    useEffect(() => {
        if (isOpen && initialImage) {
            setImgSrc(initialImage);
        } else if (!isOpen) {
            setImgSrc(''); // Clear image when dialog closes
        }
    }, [isOpen, initialImage]);

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (isOpen && event.key === 'Escape') {
                setImgSrc('');
                onClose();
            }
        };

        // Add event listener when component mounts or isOpen changes
        window.addEventListener('keydown', handleEscKey);

        // Cleanup event listener when component unmounts or isOpen changes
        return () => {
            window.removeEventListener('keydown', handleEscKey);
        };
    }, [isOpen, onClose]);

    // Function to set initial crop
    const setInitialCrop = (width: number, height: number) => {
        // Calculate target dimensions maintaining aspect ratio
        const targetWidth = minWidth;
        const targetHeight = minHeight;

        // Calculate scale to fit desired dimensions
        const scaleX = targetWidth / width;
        const scaleY = targetHeight / height;
        const scale = Math.max(scaleX, scaleY);

        // Calculate dimensions that maintain aspect ratio
        const cropWidth = targetWidth / scale;
        const cropHeight = targetHeight / scale;

        // Center the crop
        const x = (width - cropWidth) / 2;
        const y = (height - cropHeight) / 2;

        // Convert to percentages for ReactCrop
        const cropPercent: Crop = {
            unit: '%',
            x: (x / width) * 100,
            y: (y / height) * 100,
            width: (cropWidth / width) * 100,
            height: (cropHeight / height) * 100
        };

        setCrop(cropPercent);
        setCompletedCrop({
            x: x,
            y: y,
            width: cropWidth,
            height: cropHeight,
            unit: 'px'
        });
    };

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImgSrc(reader.result?.toString() || '');
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // Set initial crop when image loads
    useEffect(() => {
        if (imgSrc && imgRef.current) {
            const img = imgRef.current;
            if (img.complete) {
                setInitialCrop(img.width, img.height);
            } else {
                img.onload = () => {
                    setInitialCrop(img.width, img.height);
                };
            }
        }
    }, [imgSrc]);

    const handleSave = async () => {
        try {
            setIsLoading(true);
            if (!imgRef.current || !completedCrop) {
                throw new Error('No image or crop selected');
            }

            const canvas = document.createElement('canvas');
            const image = imgRef.current;
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;

            canvas.width = completedCrop.width;
            canvas.height = completedCrop.height;

            const ctx = canvas.getContext('2d');
            if (!ctx) {
                throw new Error('No 2d context');
            }

            ctx.drawImage(
                image,
                completedCrop.x * scaleX,
                completedCrop.y * scaleY,
                completedCrop.width * scaleX,
                completedCrop.height * scaleY,
                0,
                0,
                completedCrop.width,
                completedCrop.height
            );

            canvas.toBlob(
                async (blob) => {
                    if (!blob) {
                        throw new Error('Canvas is empty');
                    }
                    await onSave(blob);
                    setImgSrc('');
                    onClose();
                },
                'image/jpeg',
                0.95
            );
        } catch (err) {
            console.error('Error saving cropped image:', err);
        } finally {
            setIsLoading(false);
        }
    };

    return isOpen ? (
        <div className={styles.overlay}>
            <div className={styles.dialog}>
                <div className={styles.header}>
                    <h3>{title}</h3>
                    <button
                        className={styles.closeButton}
                        onClick={() => {
                            setImgSrc('');
                            onClose();
                        }}
                        type="button"
                    >
                        ×
                    </button>
                </div>

                <div className={styles.content}>
                    {!imgSrc ? (
                        <div className={styles.uploadArea}>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={onSelectFile}
                                className={styles.fileInput}
                            />
                            <div className={styles.uploadText}>
                                Klicken oder Bild hierher ziehen
                            </div>
                        </div>
                    ) : (
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspectRatio}
                            minWidth={minWidth}
                            minHeight={minHeight}
                            circularCrop={cropShape === 'round'}
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                                style={{ maxHeight: '70vh' }}
                            />
                        </ReactCrop>
                    )}
                </div>

                <div className={styles.actions}>
                    <button
                        className={styles.cancelButton}
                        onClick={() => {
                            setImgSrc('');
                            onClose();
                        }}
                        type="button"
                    >
                        Abbrechen
                    </button>
                    <button
                        className={styles.saveButton}
                        onClick={handleSave}
                        disabled={!completedCrop || isLoading}
                        type="button"
                    >
                        {isLoading ? 'Wird gespeichert...' : 'Speichern'}
                    </button>
                </div>
            </div>
        </div>
    ) : null;
};