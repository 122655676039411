import React, {useRef, useState} from "react";
import {getAuth} from "../../api/http";
import {ReactChildrenType} from "../../core/children";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
//  import { DNA } from 'react-loader-spinner';

interface IAuthenticatedLinkProps {
    url: string;
    filename: string;
    children: ReactChildrenType;
    className?: string;
}

export const AuthenticatedLink: React.FC<IAuthenticatedLinkProps> = ({url, filename, children, className}) => {
    const link = useRef<HTMLAnchorElement>(null);
    const [loading, setLoading] = useState(false);

    const handleAction = async () => {
        if (link.current?.href) {
            return
        }

        const result = await fetch(url, {
            headers: {authorization: getAuth()}
        })

        const blob = await result.blob()
        const href = window.URL.createObjectURL(blob)

        if (link.current) {
            link.current.download = filename
            link.current.href = href

            link.current.click()
        }
    }
    return (
        <>
            <a role='button' className={className} ref={link} onClick={handleAction}>
                {loading ?
                    <FontAwesomeIcon icon={faSpinner} spin />
                    // <DNA
                    //     visible={true}
                    //     height="40"
                    //     width="40"
                    //     ariaLabel="downloading"
                    //     wrapperStyle={{}}
                    //     wrapperClass="dna-wrapper"
                    // />
                    : children}
            </a>
        </>
    );
};

