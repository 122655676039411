import { IOrganizationRole } from '../../../api/models/OrganizationModel';
import React, { useEffect, useState, useMemo } from 'react';
import { OrganizationService } from '../../../api/organization';
import {Loading} from "../../foundation/Loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import styles from './organization_table.module.scss';


interface IRoleGroup {
    groupName: string;
    roles: IOrganizationRole[];
}

interface RolesTableProps {
    orgId: number;
    adminOrgId: number;
}

export const RolesTable: React.FC<RolesTableProps> = ({ orgId, adminOrgId }) => {
    const [roles, setRoles] = useState<IOrganizationRole[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState<{
        key: keyof IOrganizationRole;
        direction: 'asc' | 'desc';
    } | null>(null);

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                setLoading(true);
                const data = await OrganizationService.getRoles(adminOrgId, orgId);
                setRoles(data);
                setError(null);
            } catch (err) {
                setError('Failed to load roles');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchRoles();
    }, [orgId, adminOrgId]);

    const requestSort = (key: keyof IOrganizationRole) => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key: keyof IOrganizationRole) => {
        if (!sortConfig || sortConfig.key !== key) {
            return <FontAwesomeIcon icon={faSort} className={styles.sortIcon} />;
        }
        return sortConfig.direction === 'asc' ?
            <FontAwesomeIcon icon={faSortUp} className={styles.sortIcon} /> :
            <FontAwesomeIcon icon={faSortDown} className={styles.sortIcon} />;
    };

    const sortData = (data: IOrganizationRole[], key: keyof IOrganizationRole, direction: 'asc' | 'desc') => {
        return [...data].sort((a, b) => {
            // When sorting by function, use the funkSortBy field
            if (key === 'funkSortBy') {
                return direction === 'asc' ? a.funkSortBy - b.funkSortBy : b.funkSortBy - a.funkSortBy;
            }

            // For lastName (which includes firstName in display)
            if (key === 'lastName') {
                const nameA = `${a.lastName} ${a.firstName}`;
                const nameB = `${b.lastName} ${b.firstName}`;
                return direction === 'asc' ?
                    nameA.localeCompare(nameB) :
                    nameB.localeCompare(nameA);
            }

            // Default sorting for other fields
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const groupedRoles = useMemo(() => {
        let filteredRoles = roles;

        if (searchTerm) {
            const search = searchTerm.toLowerCase();
            filteredRoles = roles.filter(role =>
                role.lastName.toLowerCase().includes(search) ||
                role.firstName.toLowerCase().includes(search) ||
                role.functionDesc.toLowerCase().includes(search) ||
                role.functionGroup.toLowerCase().includes(search)
            );
        }

        // First group the roles
        const groups: { [key: string]: IOrganizationRole[] } = {};
        filteredRoles.forEach(role => {
            if (!groups[role.functionGroup]) {
                groups[role.functionGroup] = [];
            }
            groups[role.functionGroup].push(role);
        });

        // Convert to array and sort groups by funkGruppSortierung
        return Object.entries(groups)
            .sort(([, rolesA], [, rolesB]) => {
                // Get the funkGruppSortierung from the first role in each group
                // (all roles in a group should have the same funkGruppSortierung)
                const sortA = rolesA[0]?.funkGruppSortierung || 0;
                const sortB = rolesB[0]?.funkGruppSortierung || 0;
                return sortA - sortB;
            })
            .map(([groupName, groupRoles]): IRoleGroup => ({
                groupName,
                roles: sortConfig
                    ? sortData(groupRoles, sortConfig.key, sortConfig.direction)
                    : groupRoles.sort((a, b) => a.funkSortBy - b.funkSortBy) // Default sort by funkSortBy
            }));
    }, [roles, searchTerm, sortConfig]);


    if (loading) return <Loading />;
    if (error) return <div className={styles.error}>{error}</div>;



    return (
        <div className={styles.rolesContainer}>
            {/* ... search bar ... */}
            {groupedRoles.map(group => (
                <div key={group.groupName} className={styles.groupSection}>
                    <h3 className={styles.groupHeader}>{group.groupName}</h3>
                    <div className={styles.headerRow}>
                        <div className={styles.header} onClick={() => requestSort('lastName')}>
                            Name / Jahrgang {getSortIcon('lastName')}
                        </div>
                        <div className={styles.header} onClick={() => requestSort('funkSortBy')}>
                            Funktion {getSortIcon('funkSortBy')}
                        </div>
                    </div>
                    <div>
                        {group.roles.map((role, index) => (
                            <div key={`${role.lastName}-${role.firstName}-${index}`} className={styles.roleCard}>
                                <div className={styles.roleCardContent}>
                                    <div className={styles.roleField}>
                                        <span className={styles.label}>Name / Jahrgang:</span>
                                        <span className={styles.value}>
                                            {role.lastName.toUpperCase()} {role.firstName} / {role.jahrgang}
                                        </span>
                                    </div>
                                    <div className={styles.roleField}>
                                        <span className={styles.label}>Funktion:</span>
                                        <span className={styles.value}>{role.functionDesc}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};