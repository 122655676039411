import { useNavigate } from "react-router-dom";
import React from "react";
import styles from './BackButton.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "./Button";
import type { IButtonProps } from "./Button";

interface IBackButtonProps extends Omit<IButtonProps, 'children'> {
    navigateTo?: string;
}

export const BackButton: React.FC<IBackButtonProps> = ({
                                                           label = 'zurück',
                                                           navigateTo,
                                                           className,
                                                           ...buttonProps
                                                       }) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        if (navigateTo) {
            navigate(navigateTo);
        } else {
            navigate(-1);
        }
    };

    return (
        <Button
            {...buttonProps}
            className={`${styles.backButton} ${className || ''}`}
            onClick={handleGoBack}
        >
            <span>
                <FontAwesomeIcon icon={faChevronLeft} /> {label}
            </span>
        </Button>
    );
};