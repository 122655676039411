    import React, {useEffect, useMemo, useState} from 'react';
import {IPageLinkProps} from "../../page.props";
import {MemberService} from "../../../api/member";
import {useParamId, useParamOrg, useParamRole} from "../../useParam";
import {IMember, ITraining} from "../../../api/models/MemberModel";
import {Button, DateInput, DTS, FD, FDS, Form, Item, Select} from "../../form";
import {Row} from "../../form/row";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {toaster} from "../../../core/toaster";
import styles from "./member_detail.module.scss";
import { BeltColorStripes } from '../../foundation/BeltColorStripes';
import {IBeltColor} from "../../../api/models";
import {getOrgLevelFromId} from "../../../util/roles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash,faSpinner} from "@fortawesome/free-solid-svg-icons";
    import classNames from "classnames";

export const MemberDetailTraining: React.FC<IPageLinkProps> = ({user, profile, refresh, setRefresh}) => {

    const role = useParamRole(user);
    const orgId = useParamOrg();
    const id = useParamId();
    const [member, setMember] = useState<IMember | null>();
    const [trainings, setTrainings] = useState<ITraining[]>([])
    const [newDateFrom, setNewDateFrom] = useState<Date | null>(new Date(Date.now()));
    const [newDateTo, setNewDateTo] = useState<Date | null>(null);
    const [newType, setNewType] = useState();
    const [newSubType, setNewSubType] = useState<string>();
    const [isDeletingTraining, setIsDeletingTraining] = useState<string | null>(null);

    const OJV = 1;
    const LV = 2;

    useEffect(() => {
        MemberService.get(role.orgId, id||0).then((m) => {
            setMember(m);
        });
    }, [id, role, refresh]);


    useEffect(() => {
        MemberService.trainings(role.orgId, id||0).then((m) => {
            setTrainings(m);
        });
    }, [id, role, refresh]);

    const trainingGroups = useMemo(() => {

        if (!profile || !profile.trainingGroups) {
            return [];
        }

        return profile.trainingGroups
            .sort((a, b) => Number(a.groupId) - Number(b.groupId))
            .map(x => {
                if (!x || !x.trainings) return [];

                return x.trainings
                    .sort((a, b) => {
                        // Sort by the numeric sort field
                        return Number(a.sort || 0) - Number(b.sort || 0);
                    })
                    .map(y => ({...y, group: x.groupName}));
            })
            .flat()
            .filter(x => {
                if (!x) return false;
                return x.editAtOrgLevel ? x.editAtOrgLevel.includes(role.orgLevel) : x.editAtOrgLevel == null;
            })
            .map(x => ({
                label: `${x.group} | ${x.name} | ${x.description || ''}`,
                value: x.id
            }));

    }, [profile, role.orgId]);

    //console.log('++trainingGroups',trainingGroups)

    const canEditTraining = (trainingId: number) => {
        return trainingGroups.some(group => group.value === trainingId);
    };

    const assign = () => {
        if (newType) {
            toaster(async () => {
                await MemberService.assignTraining(
                    role.orgId,
                    id||0,
                    role.orgId,
                    newType,
                    DTS(newDateFrom as Date),
                    newDateTo ? DTS(newDateTo): undefined,
                    isGroup4Selected && newSubType ? { danType: newSubType as "P" | "V" | "A" | "E" } : undefined
                )
                setRefresh && setRefresh((refresh || 0) + 1);
            }, {success: 'Ausbildung hinzugefügt', failure: 'Fehler beim hinzufügen!'})
        }
    }

    const isGroup4Selected = useMemo(() => {
        const selectedTraining = profile.trainingGroups.flatMap(g => g.trainings).find(t => t.id === newType);
        return selectedTraining ? profile.trainingGroups.some(g => g.trainings.includes(selectedTraining) && g.groupId === 4) : false;
    }, [newType, profile.trainingGroups]);

        // console.log('profile',profile);
        // console.log('orgLevel',role.orgLevel);

    const subTypeOptions = useMemo(() => {
        return [
            { label: 'Prüfung', value: 'P' }, // früher: 1
            { label: 'Verleihung', value: 'V' }, // 2
            { label: 'Anerkennung', value: 'A' }, // 3
            { label: 'Ehrung', value: 'E' }, // 4
        ];
    }, []);

    const getLabelForValue = (value: string) => {
        const option = subTypeOptions.find(option => option.value === value);
        return option ? option.label : undefined;
    };

    const deleteTraining = (uuid: string) => {
        if (!uuid) return;

        const performDelete = async () => {
            setIsDeletingTraining(uuid);
            try {
                await MemberService.deleteTraining(role.orgId, uuid);
                setRefresh && setRefresh((refresh || 0) + 1);
            } catch (error) {
                console.error('Error deleting training:', error);
                throw error;
            } finally {
                setIsDeletingTraining(null);
            }
        };

        toaster(performDelete, {
            success: 'Ausbildung erfolgreich gelöscht',
            failure: 'Fehler beim Löschen der Ausbildung'
        });
    };

    if (member?.id) {
        return (
            <>
                <div className={styles.scrollContainer}>

            {member?.isActive == true ?(
                <ItemNavGroup label={"Neue Ausbildung"}>
                    <Form>
                        <Item size={2} type={"full"} label={"Von"}  className={styles.formheader}>
                            <DateInput value={newDateFrom} onChange={setNewDateFrom}/>
                        </Item>
                        <Item size={2} type={"full"} label={"Bis"} className={styles.formheader}>
                            <DateInput value={newDateTo} onChange={setNewDateTo} minDate={newDateFrom as Date}/>
                        </Item>
                        <Item size={6} type={"full"} label={"Type"} className={styles.formheader}>
                            <Select freeHeight={true} value={newType} options={trainingGroups} onChange={setNewType}/>
                        </Item>
                        {isGroup4Selected ? (
                            <Item size={2} type={"full"} label={"DAN"} className={styles.formheader}>
                                <Select freeHeight={true} className={styles.dropDown} value={newSubType} options={subTypeOptions} onChange={setNewSubType}/>
                            </Item>
                        ) : ''}
                        <Button label={"Hinzufügen"} width={"full"} onClick={assign}/>
                    </Form>
                </ItemNavGroup>
            ):''}
                <ItemNavGroup label={"Ausbildungen"} className={classNames({
                    [styles.spacerTop]: true,
                    [styles.spacerLast]: true
                })}
                >
                {/*<Row type="header" className={styles.headerRow}>*/}
                {/*    <Item size={3} label="Von"/>*/}
                {/*    <Item size={3} label="Bis"/>*/}
                {/*    <Item size={5} label="Name"/>*/}
                {/*    <Item size={1} label=""/>*/}
                {/*</Row>*/}
                {trainings?.map((training) => (
                    <Row key={`training-${training.id}`} className={classNames({[styles.mobileRow]:true,[styles.ausbildung]:true})}>
                        <Item size={3} data-label="Von">
                            {FDS(training.dateFrom)} {training?.dateTo ? '→' + FDS(training.dateTo) : ''}
                        </Item>
                        <Item size={7} >
                            <div>
                                {training.name} {training.amgParams?.danType && `(${getLabelForValue(training.amgParams?.danType)})`}
                                <BeltColorStripes
                                    colors={((user.sysconfig.BELTCOLORS[training.beltColor as keyof typeof user.sysconfig.BELTCOLORS] as unknown) as IBeltColor)?.colors || []}
                                    height={"1rem"}
                                    width={"10rem"}
                                />
                                {training.pruefer?.['Vorsitz'] && (
                                    <div>
                                        Vorsitz: {training.pruefer['Vorsitz']}
                                    </div>
                                )}
                                {training.pruefer?.['1.Beisitz'] && (
                                    <div>
                                        1. Beisitz: {training.pruefer['1.Beisitz']}
                                    </div>
                                )}
                                {training.pruefer?.['2.Beisitz'] && (
                                    <div>
                                        2. Beisitz: {training.pruefer['2.Beisitz']}
                                    </div>
                                )}
                            </div>
                        </Item>
                        <Item size={2}>
                            {training.amgUuid && canEditTraining(training.id) ? (
                                <Button
                                    onClick={() => deleteTraining(training.amgUuid)}
                                    tooltip={"Ausbildung löschen? HINWEIS: Dadurch geht die Verknüpfung zur hinterlegten Prüfung verloren."}
                                    className={styles.deleteButton}
                                    disabled={isDeletingTraining === training.amgUuid}
                                >
                                    <FontAwesomeIcon
                                        icon={isDeletingTraining === training.amgUuid ? faSpinner : faTrash}
                                        className={`${styles.deleteButton} ${isDeletingTraining === training.amgUuid ? styles.spin : ''}`}
                                    />
                                </Button>
                            ) : ''}
                        </Item>
                    </Row>
                ))}
                </ItemNavGroup>
                </div>
            </>
        );
    } else {
        return <p>Noch nicht verfǔgber</p>
    }
};