            import React, {useState} from 'react';
import {withDesktop} from "../../api/withDesktop";
import {IPageProps} from "../../components/page.props";
import {useParamRole} from "../../components/useParam";
import {Layout} from "../../components/foundation/layout";
import Notification         from "../../components/admin/notification/notification"
import {NotificationDetail} from "../../components/admin/notification/notification_detail";
import League from "../../components/admin/league/league";
import {LeagueTeamEinzelBegeg} from "../../components/admin/league/leagueTeamEinzelBegeg";

export const DesktopLeaguePage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const gotoUrl = `/${role?.orgId}/${role?.funkMgId}/league`
    const [refresh, setRefresh] = useState<number>(0)
    return (
        <Layout
            list={
                <League {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />
            }
            page={
                <LeagueTeamEinzelBegeg {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />
            }
        />
    );
};

export default withDesktop(DesktopLeaguePage)