import {ITrainerJudoCard, ITrainerJudocardFilter} from "./models/TrainerJudoCard";
import {AuthService} from "./auth";
import {MetaService} from "./meta";
import {HttpService} from "./http";
import {Constants} from "./constants";

const GET_TRAINER_JUDOCARDS = `${Constants.JAMA_SERVER_API}/trainer/judocards`;

/**
 * Service class for retrieving trainer judocards.
 */
export class JudocardService {
    /**
     * Retrieves trainer judocards based on the given parameters.
     *
     * @param {number} org - The organization ID.
     * @param {ITrainerJudocardFilter} filter - The filter object containing the criteria to filter trainer judocards.
     * @param {number} [offset=0] - The number of items to skip before starting to return items.
     * @param {number} [limit=50] - The maximum number of items to return.
     *
     * @returns {Promise<ITrainerJudoCard[]>} - A promise that resolves to an array of trainer judocards.
     */
    public static async getTrainerJudocards(org: number, filter: ITrainerJudocardFilter, offset: number = 0, limit: number = 50): Promise<ITrainerJudoCard[]> {
        // await AuthService.refreshJwt();

        const {active, competition, medical, gender, name, age} = filter;

        const ageObject = MetaService.metaAges.find(x => x.name === age);
        console.log('age:', age, ageObject);
        console.log(medical)

        const params = {
            active,
            competition,
            medical: medical === true ? 'true' : medical === false ? 'false' : undefined,
            gender,
            name,
            ageMin: ageObject?.lower,
            ageMax: ageObject?.upper,
        }

        // @ts-ignore
        const query = Object.keys(params).filter(x => params[x]).map(x => `${x}=${params[x]}`).join('&');

        const res = await HttpService.get<ITrainerJudoCard[]>(`${GET_TRAINER_JUDOCARDS}/${org}?offset=${offset}&limit=${limit}&${query}`, true)
        // console.log(res);
        return (res || []);
    }

}