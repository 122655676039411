import React, { useEffect, useMemo, useState, useCallback } from "react";
import { ItemNavGroup } from "../../form/ItemNavGroup";
import { ItemNav } from "../../form/ItemNav";
import { IKyuExam } from "../../../api/models";
import {DateBetween, FD, DS, Item, DateInput, PDS} from "../../form";
import { faAdd, faUser } from "@fortawesome/free-solid-svg-icons";
import { KyuService } from "../../../api";
import classNames from "classnames";
import { IPageLinkProps } from "../../page.props";
import styles from './kyu.module.scss';
import { useParamId, useParamRole } from "../../useParam";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loading } from "../../foundation/Loading";
import { useNavigate } from "react-router-dom";
import printStyles from '../../../theme/print.module.scss';
import { Input } from "../../form";
import moment from 'moment';

interface KyuProps extends IPageLinkProps {
    refresh: number;
    triggerRefresh: () => void;
    onExamsFiltered?: (exams: IKyuExam[], from: Date|null, to: Date|null) => void;  // Make optional
}

const Kyu: React.FC<KyuProps> = ({
                                     user,
                                     profile,
                                     gotoUrl,
                                     refresh,
                                     triggerRefresh,
                                     onExamsFiltered
                                 }) => {
    const [loading, setLoading] = useState(true);
    const [exams, setExams] = useState<IKyuExam[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showOnlyOpen, setShowOnlyOpen] = useState(true); // Default to true (checked)

    // Define fixed min and max dates
    const minAllowedDate = moment('2007-01-01').toDate();
    const maxAllowedDate = moment().add(1, 'month').toDate();

    // Set initial states with defaults within allowed range
    const [dateFrom, setDateFrom] = useState<Date|null>(moment().subtract(1, 'year').toDate());
    const [dateTo, setDateTo] = useState<Date|null>(moment().add(1, 'month').toDate());

    const id = useParamId();
    const role = useParamRole(user);
    const navigate = useNavigate();

    // Filtered exams with date range
    const filteredExams = useMemo(() => {
        return exams.filter(e => {
            // First apply search term filter
            const matchesSearch = !searchTerm ||
                e.id.toString().includes(searchTerm) ||
                e.org?.toLowerCase().includes(searchTerm.toLowerCase());

            // Then apply date filter
            const examDate = e.date ? new Date(e.date) : null;
            const matchesDate = examDate && dateFrom && dateTo ?
                examDate >= dateFrom && examDate <= dateTo :
                true;

            // Apply status filter if showOnlyOpen is true
            const matchesStatus = !showOnlyOpen || e.status === 'ABGESCHLOSSEN';

            return matchesSearch && matchesDate && matchesStatus;
        });
    }, [exams, searchTerm, dateFrom, dateTo, showOnlyOpen]);

    // Second filtered set for statistics (excluding 'ERSTELLT')
    const filteredExamsForStats = useMemo(() => {
        return filteredExams.filter(e => e.status !== 'ERSTELLT');
    }, [filteredExams]);

    const refreshExams = useCallback(() => {
        //console.log('Kyu: refreshExams called');
        setLoading(true);
        if (role != null) {
            //console.log('Kyu: Fetching exams for org:', role.orgId);
            KyuService.exams(role?.orgId)
                .then((exam) => {
                    //console.log('Kyu: Fetched exams:', exam);
                    setExams(exam);
                })
                .catch(error => {
                    console.error('Kyu: Error fetching exams:', error);
                })
                .finally(() => {
                    setLoading(false);
                    //console.log('Kyu: Finished refreshing exams');
                });
        } else {
            console.log('Kyu: No role available, skipping exam fetch');
            setLoading(false);
        }
    }, [role]);


    // Initial load
    useEffect(() => {
        refreshExams();
    }, [refreshExams, refresh]);

    // Filter effect
    useEffect(() => {
        if (onExamsFiltered) {
            onExamsFiltered(filteredExamsForStats, dateFrom, dateTo);
        }
    }, [filteredExamsForStats, dateFrom, dateTo, onExamsFiltered]);


// In src/components/admin/kyu/kyu.tsx
    return (
        <>
            <div className={printStyles['hide-for-print']}>
                {loading ? (
                    <Loading/>
                ) : (
                    <ItemNavGroup label={role?.orgName}>
                        <div className={styles.search}>
                            <Input
                                placeholder="Suche nach Prüfungs-Nr. oder Verein..."
                                value={searchTerm}
                                onChange={setSearchTerm}
                                className={styles.searchInput}
                            />
                        </div>
                        <div className={styles.dateFilter}>
                            <div className={styles.dateInputs}>
                                <div className={styles.dateInput}>
                                    <label>Von:</label>
                                    <DateInput
                                        value={dateFrom}
                                        onChange={(date: Date | null) => {
                                            setDateFrom(date);
                                        }}
                                        minDate={minAllowedDate}
                                        maxDate={dateTo || new Date()} // Use dateTo if it exists, otherwise use today's date
                                        placeholderText="Von Datum..."
                                    />
                                </div>
                                <div className={styles.dateInput}>
                                    <label>Bis:</label>
                                    <DateInput
                                        value={dateTo}
                                        onChange={(date: Date | null) => setDateTo(date)}
                                        minDate={dateFrom || minAllowedDate}
                                        maxDate={maxAllowedDate}
                                        placeholderText="Bis Datum..."
                                    />
                                </div>
                            </div>
                            {/*{role?.orgId <= 10 && (*/}
                                <div className={styles.matchesStatus}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={showOnlyOpen}
                                            onChange={(e) => setShowOnlyOpen(e.target.checked)}
                                        />
                                        Zeige nur offene
                                    </label>
                                </div>
                            {/*)}*/}
                        </div>

                        {role?.orgId > 10 ? (
                            <ItemNav
                                key="add-new"  // Add key here
                                label="Neue Prüfung hinzufügen"
                                className={styles.add}
                                icon={faAdd}
                                href={`${gotoUrl}/0?t=new`}
                            />
                        ) : (
                            <></>
                        )}

                        {filteredExams?.length > 0 ? (
                            filteredExams.map((e) => (
                                (e.orgId === role?.orgId || e.status != 'ERSTELLT') ? (
                                    <ItemNav
                                        key={e.id}  // Make sure this key is present
                                        href={`${gotoUrl}/${e.id}?t=candidates`}
                                        selected={id === e.id}
                                        className={styles.item}
                                    >
                                        <div className={classNames(styles.entry)}>
                                            <div className={styles.date}>{FD(e.date)}</div>
                                            <div className={styles.kyu_id}>Nr. {e.id}</div>
                                            {e.status !== 'ERSTELLT' && (
                                                <div className={styles.teilnehmer}>
                                                    <FontAwesomeIcon icon={faUser}/> {e.items}
                                                </div>
                                            )}
                                        </div>
                                        {e.orgId !== role?.orgId || e.orgId <= 10 ? (
                                            <div>
                                                <div className={styles.org}>{e.org}</div>
                                                <div className={styles.vorsitz}>Vorsitz: {e.examChairMain}</div>
                                                <div className={styles.editor}>Editor: {e.editor}</div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className={styles.vorsitz}>Vorsitz: {e.examChairMain}</div>
                                                <div className={styles.editor}>Editor: {e.editor}</div>
                                            </div>
                                        )}
                                        <div className={classNames(styles.status, styles[e.status?.toLowerCase() || 'freigegeben_alt'])}>
                                            {e.status ? (e.status === 'ABGESCHLOSSEN' ? ' URKUNDEN' : e.status) : 'FREIGEGEBEN'}
                                        </div>
                                    </ItemNav>
                                ) : (
                                    <React.Fragment key={`empty-${e.id}`}></React.Fragment>  // Add key for empty fragments
                                )
                            ))
                        ) : (
                            <Item
                                key="no-items"  // Add key here
                                type="list"
                                label={searchTerm || dateFrom || dateTo ?
                                    "Keine Ergebnisse gefunden" :
                                    "Keine Termine verfügbar"}
                            />
                        )}
                    </ItemNavGroup>
                )}
            </div>
        </>
    );
};

export default Kyu;