import {withFilter} from "../../../api/withFilter";
import {ItemNav} from "../../../components/form/ItemNav";
import React, {useMemo} from "react";
import {ItemNavGroup} from "../../../components/form/ItemNavGroup";
import {faBuilding, faPerson, faRightFromBracket, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {IOrgFunction, IUserModel} from "../../../api/models";
import PullToRefresh from "react-simple-pull-to-refresh";
import {AuthService, CacheService, IAuthProfile, LocalService} from "../../../api";
import {GroupAdminRoles, GroupTrainerRoles} from "../../../util/roles";
import preval from "preval.macro";
import styles from './more.module.scss';

const edition = preval`
require('dotenv').config();
module.exports = process.env.JAMA_EDITION
`

interface IMorePageProps {
    user: IUserModel;
    refresh: () => Promise<void>;
}

const More: React.FC<IMorePageProps> = ({user, refresh}) => {
    const navigate = useNavigate()
    const logout = async () => {
        await AuthService.logout();
        window.location.href = '/login';

    }
    const setUser = async (profile: IAuthProfile) => {
        if (profile.userId === LocalService.getCurrentUserId()) {
            navigate('/more/profile')
        } else {
            LocalService.setProfile(profile);
            await CacheService.clear();
            window.location.href = '/more/profile';
        }
    }

    const adminRoles = useMemo(() => GroupAdminRoles(user), [user]);
    const trainerRoles = useMemo(() => GroupTrainerRoles(user), [user]);
    //const tunierAdminRoles = user.rolesOrg.map(x => x.tunierAdmin).flat().filter(x => x != null) as IOrgFunction[];

    const sortKeys = (roles: {[p: string]: IOrgFunction[]}) =>  (a: string, b: string): number => {
        const aa = roles[a].find(()=>true);
        const bb = roles[b].find(()=>true);
        if (aa?.orgLevel === bb?.orgLevel) {
            return aa?.orgName.localeCompare(bb?.orgName||'') || 0; // This will sort names alphabetically
        }
        return (aa?.orgLevel || 0) - (bb?.orgLevel || 0); // This sorts by orgLevel ascending
    }

    return <>
        {user && (
            // <PullToRefresh onRefresh={() => refresh && refresh()}>
                <>
                    <ItemNavGroup label={"Profile & Konten"}>

                        {LocalService.getProfiles().map((profile: IAuthProfile) => (
                            <ItemNav
                                primary={LocalService.getCurrentUserId() === profile.userId}
                                key={profile.userId}
                                onClick={() => setUser(profile)}
                                className={styles.account}
                            >
                                {profile.name}
                            </ItemNav>
                        ))}
                        <ItemNav
                            className={styles.addAccount}
                            label={"PartnerKonto hinzufügen"}
                            href={"/login"}
                            icon={faUserPlus}
                        />

                        <ItemNav
                            label={"Log out"}
                            onClick={logout}
                            icon={faRightFromBracket}
                        />

                    </ItemNavGroup>
                    {edition === 'judoaustria' && <>
                        {trainerRoles && Object.keys(trainerRoles).length > 0 && (
                            <ItemNavGroup label={"Trainer"} className={styles.itemsWrapper}>
                                {Object.keys(trainerRoles).map(rk => {
                                    const r = trainerRoles[rk].find(() => true)
                                    return (
                                        <ItemNav
                                            className={r?.orgLevel === 1 ? styles.trlevel1 : r?.orgLevel === 2 ? styles.trlevel2 : styles.trlevel3}
                                            key={r?.funkMgId}
                                            label={`${r?.orgName} `}
                                            icon={faPerson}
                                            href={`/more/trainer/${r?.orgId}`}
                                        >
                                            <></>
                                        </ItemNav>
                                    )
                                })}
                            </ItemNavGroup>
                        )}
                        {adminRoles && Object.keys(adminRoles).length > 0 && (
                            <ItemNavGroup label={"Org Admin"} className={styles.itemsWrapper}>
                                {Object.keys(adminRoles).sort(sortKeys(adminRoles)).map(rk => {
                                    const r = adminRoles[rk].find(() => true)
                                    return (
                                        <ItemNav
                                            className={r?.orgLevel === 1 ? styles.level1 : r?.orgLevel === 2 ? styles.level2 : styles.level3}
                                            label={`${r?.orgName}`}
                                            icon={faBuilding}
                                            href={`/more/organization/${r?.orgId}`}
                                            key={r?.funkMgId}
                                        >
                                            <></>
                                        </ItemNav>
                                    )
                                })}
                            </ItemNavGroup>
                        )}
                    </>}
                </>
            // </PullToRefresh>
        )}
    </>
}

export default withFilter('More', {}, More);
