import React, {useEffect, useMemo, useState} from 'react';
import image from "../../theme/images/profileImage.png";
import classNames from "classnames";
import styles from './AvatarImage.module.scss';

interface IAvatarImageProps {
    src?: string;
    alt?: string;
    onError?: () => void;
    className?: string;
}

const AvatarImage: React.FC<IAvatarImageProps> = ({src, alt, onError, className}) => {
    const [active, setActive] = useState(false);
    const [failed, setFailed] = useState(false);

    const imgSrc = useMemo(() => {
        if (!src) return undefined;
        return new URL(src, window.location.origin).toString();
    }, [src]);

    useEffect(() => {
        // Reset states when src changes
        setFailed(false);
        setActive(false);
    }, [imgSrc]);

    const onErrorDefault = () => {
        setFailed(true);
        onError && onError();
    };

    const onLoadSuccess = () => {
        setActive(true);
    };

    return (
        <>
            {(!failed && imgSrc) ? (
                <img
                    className={classNames(className, styles.image, active && styles.active)}
                    src={imgSrc}
                    loading="eager"
                    alt={alt || 'Profile Image'}
                    onError={onErrorDefault}
                    onLoad={onLoadSuccess}
                    decoding="async"
                />
            ) : (
                <img
                    className={classNames(className, styles.image, active && styles.active)}
                    src={image}
                    loading="eager"
                    alt={alt || 'Default Profile Image'}
                    onLoad={onLoadSuccess}
                />
            )}
        </>
    );
};

export default AvatarImage;