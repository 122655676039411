import {Capacitor} from "@capacitor/core";
import {HttpService} from "./http";
import {AuthService} from "./auth";
import {Constants} from "./constants";

const POST_APN = `${Constants.JAMA_SERVER_API}/push/apn`;
const POST_GCM = `${Constants.JAMA_SERVER_API}/push/gcm`;

/**
 * Represents a service for setting the push token for the current user.
 */
export class PushService {

    /**
     * Sets the push token for the current user.
     *
     * @param {string} token - The push token to set.
     * @return {Promise<boolean>} - A promise that resolves to true if the push token is set successfully, or rejects with an error.
     */
    public static async setPushToken(token: string): Promise<boolean> {
        // await AuthService.refreshJwt();
        if (Capacitor.getPlatform() === 'ios') {
            await HttpService.post2<any, boolean>(`${POST_APN}`, {token: token}, true);
        } else if (Capacitor.getPlatform() === 'android') {
            await HttpService.post2<any, boolean>(`${POST_GCM}`, {token: token}, true);
        }
        return true;
    }

}