import {IUserModelEdit} from "./models/UserModel";
import {HttpService} from "./http";
import {AuthService} from "./auth";
import {Constants} from "./constants";
import { LocalService } from "./local";  // Add this import
import {ITrainerApplyResponse} from "./models/TrainerLicenseModel";

const TRAINER_APPLY = `${Constants.JAMA_SERVER_API_V2}/user/:org/trainer`;
const FORM = `${Constants.JAMA_SERVER_API_V2}/admin/:org/form/`;
const PROFILE = `${Constants.JAMA_SERVER_API_V2}/admin/profile`;

const UPDATE_USER = `${Constants.JAMA_SERVER_API}/updateUser`;
const R = (r: string, org: number) => r.replace('/:org/', `/${org}/`);

/**
 * A class that provides methods for editing user profiles and uploading profile pictures.
 */
export class ProfileService {
    /**
     * Edit the user profile.
     *
     * @param {IUserModelEdit} profile - The profile to be updated.
     * @returns {Promise<undefined>} A promise that resolves once the profile is updated.
     */
    public static async editProfile(profile: IUserModelEdit): Promise<undefined> {
        await HttpService.post2<IUserModelEdit, any>(UPDATE_USER, profile, true, false);
        //console.log(profile);
        return;
    }

    public static async getForm(org: number, roleMg: number): Promise<string> {
        return await HttpService.get<string>(R(`${FORM}${roleMg}`, org), true, false);
    }

    /**
     * Uploads a profile picture and verifies its availability
     * @param uuid The user's UUID
     * @param data FormData containing the image
     * @returns Promise<boolean> indicating success
     */
    public static async uploadProfilePicture(uuid: string, data: FormData): Promise<boolean> {
        try {
            // Ensure JWT is fresh before upload
            // await AuthService.refreshJwt();

            // Attempt upload
            const uploadResult = await HttpService.postBinaryWithoutJsonResponse(
                `${Constants.JAMA_SERVER_API_V2}/web/profile/image/${uuid}`,
                data
            );

            if (!uploadResult.ok) {
                console.error('Upload failed:', uploadResult.statusText);
                return false;
            }

            // Function to verify image with retries
            const verifyImage = async (retries = 3, delay = 1000): Promise<boolean> => {
                for (let i = 0; i < retries; i++) {
                    // Wait between attempts
                    await new Promise(resolve => setTimeout(resolve, delay));

                    try {
                        // Try to load the image directly
                        const img = new Image();
                        const loadPromise = new Promise<boolean>((resolve) => {
                            img.onload = () => resolve(true);
                            img.onerror = () => resolve(false);
                        });

                        // Add authorization header via fetch
                        const response = await fetch(
                            `${Constants.JAMA_SERVER_API_V2}/web/profile/image/${uuid}`,
                            {
                                headers: {
                                    'Authorization': `Bearer ${LocalService.getAuth()?.jwtToken}`
                                }
                            }
                        );

                        if (!response.ok) {
                            console.log(`Verification attempt ${i + 1} failed (status: ${response.status}), retrying...`);
                            continue;
                        }

                        // Convert the response to a blob URL
                        const blob = await response.blob();
                        img.src = URL.createObjectURL(blob);

                        // Wait for image to load or fail
                        const success = await loadPromise;
                        URL.revokeObjectURL(img.src); // Clean up

                        if (success) {
                            return true;
                        }
                    } catch (error) {
                        console.log(`Verification attempt ${i + 1} error:`, error);
                    }
                }
                return false;
            };

            // Verify with retries
            if (await verifyImage(5, 2000)) { // Increased retries and delay
                // Dispatch event for successful upload
                window.dispatchEvent(new CustomEvent('profileImageUpdated', {
                    detail: { uuid }
                }));
                return true;
            }

            console.error('Image verification failed after retries');
            return false;

        } catch (error) {
            console.error('Profile picture upload failed:', error);
            return false;
        }
    }


    public static async trainerApply(org: number, categoryId: number) {
        // await AuthService.refreshJwt();
        console.log(categoryId);
        //return !!await HttpService.post2(R(TRAINER_APPLY, org), {categoryId})
        const response: ITrainerApplyResponse = await HttpService.post2(R(TRAINER_APPLY, org), {categoryId});
        return response?.id || null;
    }

    /**
     * Deletes a profile picture for a given UUID
     * @param uuid The UUID of the profile image to delete
     * @returns Promise<boolean> true if deletion was successful
     */
    public static async deleteProfilePicture(uuid: string): Promise<boolean> {
        // await AuthService.refreshJwt();
        return await HttpService.delete<boolean>(`${Constants.JAMA_SERVER_API_V2}/web/profile/image/${uuid}`);
    }
}