import React, {useCallback, useEffect, useRef, useState} from "react";
import {TapAndDrag} from "./TapAndDrag";
import {Vec2, clamp01, lerp, clamp} from "../../util";
import {Button} from "../form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShare} from "@fortawesome/free-solid-svg-icons";
import styles from './ImageView.module.scss';
import {Share} from "@capacitor/share";

interface IImageViewProps {
    name?: string;
    src?: string;
    srcPrev?: string;
    srcNext?: string;
    onNext?: () => void;
    onPrev?: () => void;
}

const useAnimationFrame = (callback: (deltaTime: number, frame: number) => void) => {
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = useRef<number>(0);
    const previousTimeRef = useRef<number>(0);
    const [frame, setFrame] = useState(0);
    const animate = useCallback((time: number) => {
        if (previousTimeRef.current != null) {
            const deltaTime = time - previousTimeRef.current;
            callback(deltaTime / 1000, frame)
            setFrame(frame + 1)
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    }, [callback, frame])

    React.useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, [animate]); // Make sure the effect runs only once
}

interface ITransform {
    pos: Vec2
    scale: number;
    alpha: number;
}


export const ImageView: React.FC<IImageViewProps> = ({
                                                         name,
                                                         src,
                                                         srcNext,
                                                         srcPrev,
                                                         onPrev,
                                                         onNext
                                                     }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [screen, setScreen] = useState(new Vec2(1, 1));
    const [img, setImg] = useState<HTMLImageElement | undefined>(undefined)
    const [imgNext, setImgNext] = useState<HTMLImageElement | undefined>(undefined)
    const [imgPrev, setImgPrev] = useState<HTMLImageElement | undefined>(undefined)
    const [transform, setTransform] = useState<ITransform>({pos: new Vec2(), scale: 1, alpha: 0})
    const [targetTransform, setTargetTransform] = useState<ITransform>({pos: new Vec2(), scale: 1, alpha: 1})
    const [frame, setFrame] = useState(0);
    const [doPrev, setDoPrev] = useState(false);
    const [doNext, setDoNext] = useState(false);

    /*useEffect(() => {
        console.log(srcPrev, src, srcNext);
    }, [src, srcNext, srcPrev]);*/


    const getImagePos = (_pos: Vec2, _screenSize: Vec2, _imgSize: Vec2, _scale: number) => {

        const imgAspect = _imgSize.aspect();
        const screenAspect = _screenSize.aspect();
        // Minimum dimensions
        const MIN_WIDTH = 350;
        const MIN_HEIGHT = 450;

        const cs = _screenSize.multiply(0.5);

        enum EOrientation {
            Portrait,
            PortraitInverse,
            Landscape,
            LandscapeInverse
        }

        const variation = imgAspect < screenAspect ?
            (imgAspect < 1 ? EOrientation.Portrait : EOrientation.Landscape) :
            (imgAspect < 1 ? EOrientation.PortraitInverse : EOrientation.LandscapeInverse);

        let ds: Vec2;
        switch (variation) {
            case EOrientation.Portrait:
                ds = Vec2.lerp(new Vec2(_screenSize.y * imgAspect, _screenSize.y), new Vec2(_screenSize.x, _screenSize.x / imgAspect), _scale)
                break;
            case EOrientation.PortraitInverse:
                ds = Vec2.lerp(new Vec2(_screenSize.x, _screenSize.x / imgAspect), new Vec2(_screenSize.y * imgAspect, _screenSize.y), _scale)
                break;
            case EOrientation.Landscape:
                ds = Vec2.lerp(new Vec2(_screenSize.y * imgAspect, _screenSize.y), new Vec2(_screenSize.x, _screenSize.x / imgAspect), _scale)
                break;
            case EOrientation.LandscapeInverse:
                ds = Vec2.lerp(new Vec2(_screenSize.x, _screenSize.x / imgAspect), new Vec2(_screenSize.y * imgAspect, _screenSize.y), _scale)
                break;
        }

        // Enforce minimum dimensions while maintaining aspect ratio
        if (ds.x < MIN_WIDTH || ds.y < MIN_HEIGHT) {
            const scaleX = MIN_WIDTH / ds.x;
            const scaleY = MIN_HEIGHT / ds.y;
            const scale = Math.max(scaleX, scaleY);
            ds = ds.multiply(scale);
        }

        const ci = ds.multiply(0.5);
        const dp = _pos.add(cs.subtract(ci));

        return {dp, ds, cs};
    }


    useAnimationFrame((deltaTime, f: number) => {
        // console.log(deltaTime);
        //console.log(transform.alpha, targetTransform.alpha);
        const t = transform;
        t.pos = new Vec2(
            lerp(transform.pos.x, targetTransform.pos.x, deltaTime * 16),
            lerp(transform.pos.y, targetTransform.pos.y, deltaTime * 16)
        )
        t.scale = lerp(transform.scale, targetTransform.scale, deltaTime * 8);
        t.alpha = clamp01(lerp(transform.alpha, targetTransform.alpha, deltaTime * 8));
        t.alpha = t.alpha > 0.99 ? 1 : t.alpha < 0.01 ? 0 : t.alpha;
        // console.log(t.pos.x, t.pos.y);
        setTransform(t);
        setFrame(f);

    })

    useEffect(() => {
        // console.log(canvasRef);
        if (canvasRef.current != null) {
            const canvas = canvasRef.current;
            // console.log(canvas.parentElement?.offsetWidth, canvas.parentElement?.offsetHeight);
            setScreen(new Vec2(canvas.parentElement?.offsetWidth || 1, canvas.parentElement?.offsetHeight || 1));
            // const ctx = canvas.getContext("2d");
            canvas.width = canvas.parentElement?.offsetWidth || 1;
            canvas.height = canvas.parentElement?.offsetHeight || 1
        }

    }, [canvasRef]);


    useEffect(() => {
        const ctx = canvasRef.current?.getContext("2d");
        if (ctx != null) {


            //console.log('xxx')
            ctx.globalAlpha = transform.alpha;
            //if (Math.abs(transform.alpha - targetTransform.alpha) > 0.001) {
            //}
            //ctx.globalAlpha = 0.2;

            ctx.clearRect(0, 0, screen.x, screen.y);
            if (img != null) {

                const scale = clamp01(transform.scale - 1);


                const imgCenter = getImagePos(
                    transform.pos,
                    screen,
                    new Vec2(img.width, img.height),
                    scale,
                );


                if (imgPrev) {
                    //console.log("draw", dp.x, -(ds.x - screen.x));
                    if (imgCenter.dp.x > (screen.x * 0.1)) {
                        //console.log('prev');
                        if (!doPrev) {
                            setDoPrev(true);
                        }
                    } else {
                        setDoPrev(false);
                    }
                }

                if (imgNext) {
                    if (imgCenter.dp.x < -(imgCenter.ds.x - screen.x) - screen.x * 0.1) {
                        //console.log('next');
                        if (!doNext) {
                            setDoNext(true);
                        }
                    } else {
                        setDoNext(false);
                    }
                }

                ctx.drawImage(img,
                    0, 0,
                    img.width, img.height,
                    imgCenter.dp.x, imgCenter.dp.y,
                    imgCenter.ds.x, imgCenter.ds.y,
                );

                if (imgPrev) {
                    const imgPrevPos = getImagePos(
                        transform.pos,
                        screen,
                        new Vec2(imgPrev.width, imgPrev.height),
                        scale,
                    );
                    ctx.drawImage(imgPrev,
                        0, 0,
                        imgPrev.width, imgPrev.height,
                        imgPrevPos.dp.x - imgCenter.ds.x - .1 * screen.x, imgPrevPos.dp.y,
                        imgPrevPos.ds.x, imgPrevPos.ds.y,
                    );
                    /*if (doPrev) {
                        targetTransform.pos = new Vec2(+ imgCenter.ds.x,0)
                    }*/
                }
                if (imgNext) {
                    const imgNextPos = getImagePos(
                        transform.pos,
                        screen,
                        new Vec2(imgNext.width, imgNext.height),
                        scale,
                    );
                    ctx.drawImage(imgNext,
                        0, 0,
                        imgNext.width, imgNext.height,
                        imgNextPos.dp.x + imgCenter.ds.x + 0.1 * screen.x, imgNextPos.dp.y,
                        imgNextPos.ds.x, imgNextPos.ds.y,
                    );
                }

                ctx.drawImage(img,
                    0, 0,
                    img.width, img.height,
                    imgCenter.dp.x, imgCenter.dp.y,
                    imgCenter.ds.x, imgCenter.ds.y,
                );
            }
        }
    }, [transform, targetTransform, frame, screen, img, doPrev, doNext, imgPrev, imgNext]);


    useEffect(() => {

        if (src != null) {
            const image = new Image();
            image.src = src;

            const t = targetTransform;

            t.alpha = 0;
            setTargetTransform(t);

            image.onload = () => {

                setImg(image)
                const t = targetTransform;
                t.alpha = 1;
                setTargetTransform(t);
                //onDragEnd();

                centerImage(true)

                //console.log(src);
            }
        }
        if (srcPrev != null) {
            const image = new Image();
            image.src = srcPrev;
            if (!srcPrev) setImgPrev(undefined);
            // centerImage()
            image.onload = () => {

                setImgPrev(image)
            }
        } else {
            console.log(srcPrev)
            if (imgPrev) {
                setImgPrev(undefined);
            }
        }
        if (srcNext != null) {
            const image = new Image();
            image.src = srcNext;
            if (!srcNext) setImgNext(undefined);

            image.onload = () => {
                setImgNext(image)
            }
        } else {
            if (imgNext) {
                setImgNext(undefined);
            }
        }

    }, [src, srcNext, srcPrev]);

    const centerImage = (force: boolean) => {
        targetTransform.pos = new Vec2();
        setTargetTransform(targetTransform);
        if (force) {
            transform.pos = new Vec2();
            setTransform(transform)
        }
    }


    const fitImage = () => {
        if (img != null) {
            //const aspect = img.width / img.height;
            const scale = clamp01(transform.scale - 1);

            const imgSize = new Vec2(img.width, img.height);
            const {dp, ds} = getImagePos(
                transform.pos,
                screen,
                imgSize,
                scale,
            );

            if ((ds.x - screen.x) < 0) {
                targetTransform.pos = new Vec2(0, 0);
            } else {

                if (targetTransform.pos.x > 0.5 * (ds.x - screen.x)) {
                    targetTransform.pos = new Vec2(0.5 * (ds.x - screen.x), 0)
                } else if (targetTransform.pos.x < -0.5 * (ds.x - screen.x)) {
                    targetTransform.pos = new Vec2(-0.5 * (ds.x - screen.x))
                }
            }

            //targetTransform.pos = new Vec2(clamp(targetTransform.pos.x, -ds.x*.5 - screen.x * 0.5, ds.x * .25), 0);
            setTargetTransform(targetTransform);
        }
    }

    const onSingleTap = () => {
        // do nothing
        targetTransform.scale = 1;
        centerImage(false)
    }

    const onDoubleTap = () => {
        if (targetTransform.scale > 1) {
            targetTransform.scale = 1;
            centerImage(false)
        } else {
            targetTransform.scale = 2;

            fitImage();
        }
    }

    const onDrag = (pos: Vec2) => {
        targetTransform.pos = new Vec2(targetTransform.pos.x + pos.x, 0);
        setTargetTransform(targetTransform)
    }

    const onDragEnd = () => {
        if (doPrev) {
            onPrev && onPrev();
            setDoPrev(false);

        } else if (doNext) {
            onNext && onNext();
            setDoNext(false);

        }// else {

            if (targetTransform.scale === 1) {
                centerImage(false)
            } else {
                fitImage()
            }
        //}
    }

    const onPinch = (s: number) => {
        /*targetTransform.scale = clamp(targetTransform.scale + (s - 1), 1, 2);
        setTargetTransform(targetTransform);*/
    }

    const shareImage = async () => {
        if (src) {
            await Share.share({
                title: name || 'Jama Gallery',
                url: src,
            });
        }
    }

    return (
        <TapAndDrag
            className={styles.wrapper}
            onSingleTap={onSingleTap}
            onDoubleTap={onDoubleTap}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            onPinch={onPinch}
        >
            <canvas
                ref={canvasRef}
                width={screen.x}
                height={screen.y}
            />
            <Button
                className={styles.share}
                onClick={shareImage}
            >
                <FontAwesomeIcon icon={faShare}/>
            </Button>
        </TapAndDrag>
    );
}