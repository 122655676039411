import {IAuthProfile, IVerifyPinResult, LocalService} from "./local";
import {Observable, Subject} from "rxjs";
import {HttpService} from "./http";
import {Constants} from "./constants";
import {CacheService} from "./cache";
import {IUserModel} from "./models/UserModel";
import {IUserProfile} from "./models/AuthModel";

//const UPDATE_TOKEN = `${Constants.JAMA_SERVER_API_V2}/users/refresh`;
const SIGNUP_USER = `${Constants.JAMA_SERVER_API_V2}/auth/reqpin`;
const GET_PROFILE = `${Constants.JAMA_SERVER_API_V2}/auth/profile`;
const JWT_REFRESH = `${Constants.JAMA_SERVER_API_V2}/auth/refresh`;
const VERIFY_PIN = `${Constants.JAMA_SERVER_API_V2}/auth/verifypin`;
const VERIFY_PASSWORD = `${Constants.JAMA_SERVER_API_V2}/auth/password`;
const GET_USER = `${Constants.JAMA_SERVER_API_V2}/web/getUser`;

interface IVerifyPin {
    email: string;
    pin: string;
}

interface IVerifyPassword {
    email: string;
    password: string;
}

interface RefreshRequest {
    jwtToken: string;
    refreshToken: string;
}



export class AuthService {
    private static _authorization: Subject<boolean> = new Subject<boolean>();
    private static refreshPromise: Promise<void> | null = null;

    public static async authorize(email: string | null = null, pin: string | null = null): Promise<boolean> {
        let auth = false;
        if (email != null && pin != null) {
            const res = await HttpService.post2<IVerifyPin, IVerifyPinResult>(VERIFY_PIN, {email, pin}, false, false);
            if (res != null && res.jwtToken != null && res.jwtToken.length > 0) {
                const token = AuthService.parseJwt(res.jwtToken)

                LocalService.setProfile({
                    name: '',
                    userId: token.userId,
                    auth: res,
                });
                CacheService.clear();
                const user = await AuthService.getUser();
                if (user) {
                    console.log('setprofile', user, token);
                    LocalService.setProfile({
                        name: `${user?.title || ''} ${user?.givenName} ${user?.familyName}`.trimStart(),
                        userId: token.userId,
                        auth: res,
                    });
                    auth = true;
                }
            }
        } else {
            auth = !(LocalService.getAuth() == null || LocalService.getAuth()?.jwtToken.length === 0);
        }
        setTimeout(() => AuthService._authorization.next(auth), 500);
        return auth;
    }

    public static async authorizePassword(email: string, password: string): Promise<boolean> {
        let auth = false;
        if (email != null && password != null) {
            const res = await HttpService.post2<IVerifyPassword, IVerifyPinResult>(VERIFY_PASSWORD, {email, password}, false, false);
            //console.log(res)
            if (res != null && res.jwtToken != null && res.jwtToken.length > 0) {
                const token = AuthService.parseJwt(res.jwtToken)
                //console.log(token);
                LocalService.setProfile({
                    name: '',
                    userId: token.userId,
                    auth: res,
                });
                await CacheService.clear();
                const user = await AuthService.getUser();
                if (user) {
                    console.log('setprofile', user, token);
                    LocalService.setProfile({
                        name: `${user?.title || ''} ${user?.givenName} ${user?.familyName}`.trimStart(),
                        userId: token.userId,
                        auth: res,
                    });
                    auth = true;
                }
            }
        } else {
            auth = !(LocalService.getAuth() == null || LocalService.getAuth()?.jwtToken.length === 0);
        }
        setTimeout(() => AuthService._authorization.next(auth), 500);
        return auth;
    }

    public static async refreshJwt(): Promise<void> {
        if (this.refreshPromise) {
            return this.refreshPromise;
        }

        const profile = LocalService.getProfile();
        if (!profile?.auth?.refreshToken || !profile?.auth?.jwtToken) {
            return Promise.reject(new Error('No refresh token available'));
        }

        this.refreshPromise = (async () => {
            try {
                const res = await HttpService.post2<RefreshRequest, IVerifyPinResult>(
                    JWT_REFRESH,
                    {
                        jwtToken: profile.auth.jwtToken,
                        refreshToken: profile.auth.refreshToken
                    },
                    false,
                    false
                );

                if (!res?.jwtToken) {
                    throw new Error('Invalid refresh token response');
                }

                profile.auth = {
                    jwtToken: res.jwtToken,
                    refreshToken: res.refreshToken,
                    exp: res.exp,
                    date: new Date().toISOString(),
                    message: res.message || 'Token refreshed'
                };
                LocalService.setProfile(profile);

            } catch (error) {
                LocalService.removeProfile(profile.userId);
                throw error;
            } finally {
                this.refreshPromise = null;
            }
        })();

        return this.refreshPromise;
    }

    public static async logout(): Promise<void> {
        const profile = LocalService.getProfile();
        if (profile) {
            LocalService.removeProfile(profile.userId);
        }
        await CacheService.clear();
        this._authorization.next(false);
    }

    public static async signup(email: string): Promise<boolean> {
        try {
            const res = await HttpService.post2<{ email: string }, any>(
                SIGNUP_USER,
                { email },
                false,
                false
            );
            return !!res;
        } catch (error) {
            console.error('Signup failed:', error);
            return false;
        }
    }

    public static get authorizationObservable(): Observable<boolean> {
        return AuthService._authorization.asObservable();
    }

    public static async getUser(): Promise<IUserModel | null> {
        try {
            // console.log('getUser - Starting...');
            // console.log('getUser - Auth Token:', LocalService.getAuth()?.jwtToken);

            const res = await HttpService.get<IUserModel>(GET_USER, true, true);
            // console.log('getUser - API Response:', res);

            if (!res) {
                console.log('getUser - No response from API');
                return null;
            }

            return res;
        } catch (error) {
            console.error('getUser - Error:', error);
            return null;
        }
    }

    public static async getProfile(): Promise<IUserProfile> {
        return await HttpService.get<IUserProfile>(GET_PROFILE, true);
    }

    private static parseJwt(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    public static async resetPassword(token: string, email: string, password: string): Promise<IVerifyPassword> {
        return HttpService.put<IVerifyPassword>(`${Constants.JAMA_SERVER_API}/auth/reset_password/${token}`, {
            email, password
        });
    }

    public static async requestPassword(email: string): Promise<any> {
        return HttpService.put<any>(`${Constants.JAMA_SERVER_API}/auth/request_password/${email}`, {});
    }
}