import React, {useState, useEffect} from 'react';
import {Tab} from "./Tab";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import styles from './tab_layout.module.scss'

export interface ITabLayoutItem {
    label: string;
    icon: IconProp;
    component: React.ReactElement;
}

interface ITabLayoutProps {
    items: ITabLayoutItem[];
}

export const TabLayout: React.FC<ITabLayoutProps> = ({items = []}) => {  // Provide default empty array
    const [selected, setSelected] = useState<number>(0);

    // Reset selected index when items change
    useEffect(() => {
        if (!items?.length || selected >= items.length) {
            setSelected(0);
        }
    }, [items, selected]);

    // Safely get the current item
    const currentItem = items?.[selected];

    // Don't render if no items
    if (!items?.length) {
        return null;
    }

    return (
        <div className={styles.layout}>
            <div className={styles.header}>
                {items.map((item, i) => (
                    <Tab
                        key={`tab-${i}`}
                        icon={item.icon}
                        mode={'compressed'}
                        label={item.label}
                        active={selected === i}
                        onClick={() => setSelected(i)}
                    />
                ))}
            </div>
            {currentItem?.component}
        </div>
    );
};