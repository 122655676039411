    // /src/components/admin/league/leagueTeamEinzelBegeg.tsx
    import React, { useEffect, useState } from 'react';
    import { useParams } from 'react-router-dom';
    import { ItemNavGroup } from "../../form/ItemNavGroup";
    import { Loading } from "../../foundation/Loading";
    import { IPageLinkProps } from "../../page.props";
    import { useParamRole } from "../../useParam";
    import { LeagueService } from "../../../api/league";
    import { ILeagueTeamBegeg, ILeagueTeamEinzelBegeg, ILeagueReferee } from "../../../api/models/LeagueModel";
    import styles from './leagueTeamEinzelBegeg.module.scss';
    import {Button, FormatMatchDate, Select} from "../../form";
    import { MatchTable } from './MatchTable';
    import { calculateUBW, calculateSieg } from './league.utils';
    import { toaster, toastError } from "../../../core/toaster";

    // Decision match winner options
    type DecisionMatchWinner = 'home' | 'away' | null;

    // Interface for referee assignment
    interface IRefereeAssignment {
        kampfrichter1_mg_id: number | null;
        kampfrichter2_mg_id: number | null;
        kampfrichter3_mg_id: number | null;
    }

    export const LeagueTeamEinzelBegeg: React.FC<IPageLinkProps> = ({ user, refresh, setRefresh }) => {

        /*
        * 1.State declarations (useState)
        * 2.Effect hooks (useEffect)
        * 3.Handler/callback functions
        * 4.Helper/computation functions
        * 5.Return statement (JSX)
        * */

        const [matches, setMatches] = useState<ILeagueTeamEinzelBegeg[]>([]);
        const [editableMatches, setEditableMatches] = useState<ILeagueTeamEinzelBegeg[]>([]);
        const [matchDetail, setMatchDetail] = useState<ILeagueTeamBegeg | null>(null);
        const [loading, setLoading] = useState(true);
        const [isEditing, setIsEditing] = useState(false);
        const [homeTeamFighters, setHomeTeamFighters] = useState<string[]>([]);
        const [awayTeamFighters, setAwayTeamFighters] = useState<string[]>([]);
        const [referees, setReferees] = useState<ILeagueReferee[]>([]);
        const [refereesLoading, setRefereesLoading] = useState(false);
        const [selectedReferees, setSelectedReferees] = useState<IRefereeAssignment>({
            kampfrichter1_mg_id: null,
            kampfrichter2_mg_id: null,
            kampfrichter3_mg_id: null
        });
        const [isSaving, setIsSaving] = useState(false);
        const role = useParamRole(user);
        const params = useParams();
        const matchId = params.id ? parseInt(params.id) : 0;
        const [refereesInitialized, setRefereesInitialized] = useState(false);
        const [decisionMatchWinner, setDecisionMatchWinner] = useState<DecisionMatchWinner>(null);

        // Format referee options for the Select component
        const refereeOptions = referees.map(referee => ({
            label: `${referee.lastName} ${referee.firstName} (${referee.jahrgang})`,
            value: referee.mg_id
        }));

        // Get data based on edit state
        const displayMatches = isEditing ? editableMatches : matches;

        // Sort matches by durchgang (round) and laufno (match number)
        const sortedMatches = [...displayMatches].sort((a, b) => {
            if (a.durchgang !== b.durchgang) {
                return   a.durchgang - b.durchgang;
            }
            return a.laufno - b.laufno;
        });

        // Group matches by durchgang (round)
        const matchesByRound = sortedMatches.reduce((acc, match) => {
            if (!acc[match.durchgang]) {
                acc[match.durchgang] = [];
            }
            acc[match.durchgang].push(match);
            return acc;
        }, {} as Record<number, ILeagueTeamEinzelBegeg[]>);

        // Get list of rounds in ascending order
        const rounds = Object.keys(matchesByRound).map(Number).sort((a, b) => a - b);

        // Add this function to the LeagueTeamEinzelBegeg component
        const initializeRefereeAssignments = async () => {
            if (!role?.orgId || !matchId || !matchDetail) return;

            // Only proceed if at least one referee is assigned
            if (!matchDetail.kampfrichter1_mg_id && !matchDetail.kampfrichter2_mg_id && !matchDetail.kampfrichter3_mg_id) {
                console.log("No referee assignments to initialize");
                return;
            }

            console.log("Initializing referee assignments from matchDetail");

            // Create a payload with all referee assignments from matchDetail
            const payload = {
                ...(matchDetail.kampfrichter1_mg_id ? { kampfrichter1_mg_id: matchDetail.kampfrichter1_mg_id } : {}),
                ...(matchDetail.kampfrichter2_mg_id ? { kampfrichter2_mg_id: matchDetail.kampfrichter2_mg_id } : {}),
                ...(matchDetail.kampfrichter3_mg_id ? { kampfrichter3_mg_id: matchDetail.kampfrichter3_mg_id } : {})
            };

            // Only proceed if the payload is not empty
            if (Object.keys(payload).length === 0) {
                console.log("No referee assignments to initialize (empty payload)");
                return;
            }

            try {
                const success = await LeagueService.updateBlBegegnung(role.orgId, matchId, payload);
                if (success) {
                    console.log("Successfully initialized referee assignments:", payload);
                } else {
                    console.error("Failed to initialize referee assignments");
                }
            } catch (error) {
                toastError('Fehler beim Speichern');
                console.error("Error initializing referee assignments:", error);
            }
        };

        //

        // Fetch match data
        useEffect(() => {
            const fetchData = async () => {
                if (role?.orgId && matchId) {
                    setLoading(true);
                    try {
                        // Fetch the team match details - fixed by adding both required parameters
                        const currentMatch = await LeagueService.begegOne(role.orgId, role.orgId, 2025, matchId);
                        setMatchDetail(currentMatch);

                        // Fetch individual matches
                        const individualMatches = await LeagueService.getEinzelBegegnung(role.orgId, matchId);

                        // Extract unique fighter names and sort them
                        const uniqueHomeFighters = Array.from(
                            new Set(individualMatches.map(match => match.name_heim).filter(Boolean))
                        ).sort();

                        const uniqueAwayFighters = Array.from(
                            new Set(individualMatches.map(match => match.name_ausw).filter(Boolean))
                        ).sort();

                        setHomeTeamFighters(uniqueHomeFighters);
                        setAwayTeamFighters(uniqueAwayFighters);

                        // Calculate UBW for each match
                        const matchesWithUBW = individualMatches.map(match => {
                            const ubw_h = match.ubw_h || calculateUBW(
                                match.yuko_h,
                                match.wazaari_h,
                                match.ippon_h,
                                match.shido_a,
                                match.hsm_a
                            );

                            const ubw_a = match.ubw_a || calculateUBW(
                                match.yuko_a,
                                match.wazaari_a,
                                match.ippon_a,
                                match.shido_h,
                                match.hsm_h
                            );

                            // Use sieg values from the API if they exist
                            // Only calculate if they don't exist
                            const sieg_h = match.sieg_h !== undefined ? match.sieg_h : calculateSieg(ubw_h, ubw_a).sieg_h;
                            const sieg_a = match.sieg_a !== undefined ? match.sieg_a : calculateSieg(ubw_h, ubw_a).sieg_a;

                            return {
                                ...match,
                                ubw_h,
                                ubw_a,
                                sieg_h,
                                sieg_a
                            };
                        });

                        setMatches(matchesWithUBW);
                        setEditableMatches([...matchesWithUBW]); // Clone for editing

                    } catch (error) {
                        console.error('Error fetching match data:', error);
                    } finally {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            };

            fetchData();
        }, [role?.orgId, matchId, refresh]);

        // 2. Modify the useEffect to check if we've already initialized
        useEffect(() => {
            if (matchDetail && !refereesInitialized) {
                // Only check if the server needs updating if referees aren't already initialized
                checkAndUpdateReferees();
            }
        }, [matchDetail, refereesInitialized]);

    // 3. Create a new function that checks if an update is actually needed
        const checkAndUpdateReferees = async () => {
            if (!role?.orgId || !matchId || !matchDetail) return;

            // Only proceed if at least one referee is assigned
            if (!matchDetail.kampfrichter1_mg_id && !matchDetail.kampfrichter2_mg_id && !matchDetail.kampfrichter3_mg_id) {
                console.log("No referee assignments to initialize");
                setRefereesInitialized(true);
                return;
            }

            console.log("Setting referee assignments in component state");

            // First, update the component state
            setSelectedReferees({
                kampfrichter1_mg_id: matchDetail.kampfrichter1_mg_id,
                kampfrichter2_mg_id: matchDetail.kampfrichter2_mg_id,
                kampfrichter3_mg_id: matchDetail.kampfrichter3_mg_id
            });

            // Mark as initialized so we don't keep checking
            setRefereesInitialized(true);
        };

        // Fetch referees
        useEffect(() => {
            const fetchReferees = async () => {
                if (role?.orgId) {
                    setRefereesLoading(true);
                    try {
                        const refereeData = await LeagueService.getReferees(role.orgId);
                        setReferees(refereeData);

                        if (matchDetail) {
                            setSelectedReferees({
                                kampfrichter1_mg_id: matchDetail.kampfrichter1_mg_id,
                                kampfrichter2_mg_id: matchDetail.kampfrichter2_mg_id,
                                kampfrichter3_mg_id: matchDetail.kampfrichter3_mg_id
                            });
                        }
                    } catch (error) {
                        console.error('Error fetching referees:', error);
                    } finally {
                        setRefereesLoading(false);
                    }
                }
            };

            fetchReferees();
        }, [role?.orgId, matchDetail]);

        useEffect(() => {
            if (matchDetail && !refereesInitialized) {
                // Only update the local state without sending PATCH requests
                if (matchDetail.kampfrichter1_mg_id || matchDetail.kampfrichter2_mg_id || matchDetail.kampfrichter3_mg_id) {
                    console.log("Setting initial referee values from matchDetail");
                    setSelectedReferees({
                        kampfrichter1_mg_id: matchDetail.kampfrichter1_mg_id,
                        kampfrichter2_mg_id: matchDetail.kampfrichter2_mg_id,
                        kampfrichter3_mg_id: matchDetail.kampfrichter3_mg_id
                    });
                }
                setRefereesInitialized(true);
            }
        }, [matchDetail, refereesInitialized]);
        // useEffect(() => {
        //     if (matchDetail?.kampfrichter1_mg_id && refereeOptions.length > 0) {
        //         console.log("Referee to find:", matchDetail.kampfrichter1_mg_id);
        //         console.log("Available referees:", refereeOptions);
        //         console.log("Found referee:", refereeOptions.find(x => Number(x.value) === Number(matchDetail.kampfrichter1_mg_id)));
        //     }
        // }, [matchDetail?.kampfrichter1_mg_id, refereeOptions]);



        // HANDLER

        // Handle decision match winner change
        const handleDecisionMatchChange = (winner: DecisionMatchWinner) => {
            setDecisionMatchWinner(winner);
        };

        // Handle referee selection change
        const handleRefereeChange = async (refereeType: keyof IRefereeAssignment, refereeId: number | null) => {
            console.log(`Changing ${refereeType} to:`, refereeId);

            // Update local state - can now handle null values when unselecting
            setSelectedReferees(prev => ({
                ...prev,
                [refereeType]: refereeId
            }));
        };


        // Updated function for LeagueTeamEinzelBegeg.tsx
        const updateSiegBasedOnUBW = (updatedMatch: ILeagueTeamEinzelBegeg, field: string, previousValue: number, newValue: number) => {
            // Only process if this is a UBW field
            const isUbwField = field === 'ubw_h' || field === 'ubw_a';

            if (isUbwField) {
                // Check if UBW just reached 100 (was previously less than 100)
                const reachedFullUbw = previousValue < 100 && newValue >= 100;

                // Check if UBW just dropped below 100 (was previously 100 or more)
                const droppedBelowFullUbw = previousValue >= 100 && newValue < 100;

                if (reachedFullUbw) {
                    // When reaching 100 UBW, set the corresponding side as winner
                    if (field === 'ubw_h') {
                        // Home team reached 100 UBW first, they win
                        return {
                            ...updatedMatch,
                            sieg_h: 1,
                            sieg_a: 0
                        };
                    } else if (field === 'ubw_a') {
                        // Away team reached 100 UBW first, they win
                        return {
                            ...updatedMatch,
                            sieg_h: 0,
                            sieg_a: 1
                        };
                    }
                } else if (droppedBelowFullUbw) {
                    // When dropping below 100 UBW, reset both sieg values to 0
                    return {
                        ...updatedMatch,
                        sieg_h: 0,
                        sieg_a: 0
                    };
                }
            }

            return updatedMatch;
        };


        // Handle field changes
        const handleFieldChange = (matchId: number, field: string, value: number | string) => {
            const updatedMatches = editableMatches.map(match => {
                if (match.einzelbegeg_id === matchId) {
                    // Handle string values (fighter names) and fighter IDs
                    if (field === 'name_heim' || field === 'name_ausw' ||
                        field === 'bl_teams_mg_id_heim' || field === 'bl_teams_mg_id_ausw') {
                        // For fighter IDs, ensure the value is a number
                        if (field === 'bl_teams_mg_id_heim' || field === 'bl_teams_mg_id_ausw') {
                            const numericValue = typeof value === 'string' ? parseInt(value) : value;
                            return { ...match, [field]: numericValue };
                        }
                        // For fighter names, keep as string
                        return { ...match, [field]: value };
                    }

                    // Handle numeric values (scores)
                    const numericValue = typeof value === 'string' ? parseInt(value) : value;
                    let updatedMatch = { ...match, [field]: numericValue };

                    // Store the previous value before updating for UBW comparison
                    const previousValue = match[field as keyof ILeagueTeamEinzelBegeg] as number;

                    // Special handling for linked Sieg values
                    if (field === 'sieg_h' && numericValue === 1) {
                        // If home team wins, away team must lose
                        updatedMatch = { ...updatedMatch, sieg_a: 0 };
                    } else if (field === 'sieg_a' && numericValue === 1) {
                        // If away team wins, home team must lose
                        updatedMatch = { ...updatedMatch, sieg_h: 0 };
                    }

                    // Handle direct UBW changes
                    if (field === 'ubw_h' || field === 'ubw_a') {
                        // Check if UBW reached/left 100 and update sieg values accordingly
                        updatedMatch = updateSiegBasedOnUBW(updatedMatch, field, previousValue, numericValue);

                        // Only recalculate sieg values if either side has reached 100 UBW
                        const ubw_h = field === 'ubw_h' ? numericValue : updatedMatch.ubw_h;
                        const ubw_a = field === 'ubw_a' ? numericValue : updatedMatch.ubw_a;

                        if (ubw_h >= 100 || ubw_a >= 100) {
                            const { sieg_h, sieg_a } = calculateSieg(ubw_h, ubw_a);
                            updatedMatch = {
                                ...updatedMatch,
                                sieg_h,
                                sieg_a
                            };
                        }

                        return updatedMatch;
                    }

                    // Recalculate UBW when scoring fields change
                    if (['ippon_h', 'wazaari_h', 'yuko_h', 'shido_h', 'hsm_h', 'ippon_a', 'wazaari_a', 'yuko_a', 'shido_a', 'hsm_a'].includes(field)) {
                        // Calculate the new UBW values
                        const ubw_h = calculateUBW(
                            field === 'ippon_h' ? numericValue : updatedMatch.ippon_h,
                            field === 'wazaari_h' ? numericValue : updatedMatch.wazaari_h,
                            field === 'yuko_h' ? numericValue : updatedMatch.yuko_h,
                            field === 'shido_a' ? numericValue : updatedMatch.shido_a,
                            field === 'hsm_a' ? numericValue : updatedMatch.hsm_a
                        );

                        const ubw_a = calculateUBW(
                            field === 'ippon_a' ? numericValue : updatedMatch.ippon_a,
                            field === 'wazaari_a' ? numericValue : updatedMatch.wazaari_a,
                            field === 'yuko_a' ? numericValue : updatedMatch.yuko_a,
                            field === 'shido_h' ? numericValue : updatedMatch.shido_h,
                            field === 'hsm_h' ? numericValue : updatedMatch.hsm_h
                        );

                        // Store previous UBW values for comparison
                        const previousUbwH = updatedMatch.ubw_h;
                        const previousUbwA = updatedMatch.ubw_a;

                        updatedMatch = {
                            ...updatedMatch,
                            ubw_h,
                            ubw_a
                        };

                        // Check if UBW just reached 100 for either side and update sieg values
                        if (previousUbwH < 100 && ubw_h >= 100) {
                            updatedMatch = {
                                ...updatedMatch,
                                sieg_h: 1,
                                sieg_a: 0
                            };
                        } else if (previousUbwA < 100 && ubw_a >= 100) {
                            updatedMatch = {
                                ...updatedMatch,
                                sieg_h: 0,
                                sieg_a: 1
                            };
                        } else if ((previousUbwH >= 100 && ubw_h < 100) || (previousUbwA >= 100 && ubw_a < 100)) {
                            // If either UBW dropped below 100, reset sieg values
                            updatedMatch = {
                                ...updatedMatch,
                                sieg_h: 0,
                                sieg_a: 0
                            };
                        } else if (ubw_h >= 100 || ubw_a >= 100) {
                            // Only auto-calculate sieg values if at least one side has reached 100 UBW
                            const { sieg_h, sieg_a } = calculateSieg(ubw_h, ubw_a);
                            updatedMatch = {
                                ...updatedMatch,
                                sieg_h,
                                sieg_a
                            };
                        }
                    }

                    return updatedMatch;
                }
                return match;
            });
            // Log the updated match for the ID field to verify it's being set correctly
            if (field === 'bl_teams_mg_id_heim' || field === 'bl_teams_mg_id_ausw') {
                console.log(`Updated ${field} to ${value} for match ${matchId}`,
                    updatedMatches.find(m => m.einzelbegeg_id === matchId));
            }
            setEditableMatches(updatedMatches);
        };

        // Modified handleSaveChanges that returns a Promise
        const handleSaveChanges = async () => {
            if (isSaving) return false; // Prevent multiple submissions

            try {
                if (role?.orgId && matchId) {
                    setIsSaving(true);

                    if (selectedReferees.kampfrichter1_mg_id !== null ||
                        selectedReferees.kampfrichter2_mg_id !== null ||
                        selectedReferees.kampfrichter3_mg_id !== null) {

                        const refereePayload = {
                            ...(selectedReferees.kampfrichter1_mg_id !== null ? { kampfrichter1_mg_id: selectedReferees.kampfrichter1_mg_id } : {}),
                            ...(selectedReferees.kampfrichter2_mg_id !== null ? { kampfrichter2_mg_id: selectedReferees.kampfrichter2_mg_id } : {}),
                            ...(selectedReferees.kampfrichter3_mg_id !== null ? { kampfrichter3_mg_id: selectedReferees.kampfrichter3_mg_id } : {}),
                            ...(decisionMatchWinner !== null ? { decisionMatchWinner } : {})
                        };

                        if (Object.keys(refereePayload).length > 0) {
                            console.log("Updating referee assignments:", refereePayload);
                            try {
                                const refereeSuccess = await LeagueService.updateBlBegegnung(role.orgId, matchId, refereePayload);
                                if (!refereeSuccess) {
                                    console.error("Failed to update referee assignments");
                                    toastError("Fehler beim Aktualisieren der Kampfrichter");
                                }
                            } catch (error) {
                                console.error("Error updating referee assignments:", error);
                                toastError("Fehler beim Aktualisieren der Kampfrichter");
                            }
                        }
                    } else if (decisionMatchWinner !== null) {
                        // If no referee assignments but there is a decision match winner
                        const decisionPayload = { decisionMatchWinner } as any;
                        try {
                            const decisionSuccess = await LeagueService.updateBlBegegnung(role.orgId, matchId, decisionPayload);
                            if (!decisionSuccess) {
                                console.error("Failed to update decision match winner");
                                toastError("Fehler beim Aktualisieren des Entscheidungskampf");
                            }
                        } catch (error) {
                            console.error("Error updating decision match winner:", error);
                            toastError("Fehler beim Aktualisieren des Entscheidungskampf");
                        }
                    }

                    // Then, update the individual match data
                    console.log('##Data being saved:', editableMatches);

                    // Use the toaster function for the async operation
                    const success = await toaster(
                        async () => {
                            try {
                                const result = await LeagueService.updateEinzelbegegnung(
                                    role.orgId,
                                    matchId,
                                    editableMatches
                                );

                                // If the result is false, we need to throw an error to trigger the toaster's error handling
                                if (!result) {
                                    throw new Error('Failed to update match data');
                                }

                                return result;
                            } catch (error) {
                                console.error("API call failed:", error);
                                // Re-throw the error to be caught by the toaster
                                throw error;
                            }
                        },
                        {
                            success: 'Änderungen wurden erfolgreich gespeichert',
                            failure: 'Fehler beim Speichern der Daten'
                        },
                        () => {
                            // This runs regardless of success or failure
                            setIsSaving(false);
                        }
                    );

                    // Update local state with the edited data only if successful
                    if (success) {
                        setMatches([...editableMatches]);
                        // Exit edit mode but maintain the selected referees in state
                        setIsEditing(true); // Keep in editing mode instead of closing it
                        return true; // Indicate success
                    }
                    return false; // Indicate failure
                }
            } catch (error) {
                console.error('Error saving match data:', error);
                toastError('Fehler beim Speichern der Daten');
                setIsSaving(false);
                return false; // Indicate failure
            }

            return false; // Default return for cases not handled above
        };

        const handleSubmitMatchResults = async () => {
            if (isSaving) return; // Prevent multiple submissions

            try {
                // First, save all changes
                console.log('Saving changes before submitting match results...');
                const saveSuccessful = await handleSaveChanges();

                // Only proceed with submitting match results if save was successful
                if (saveSuccessful && role?.orgId && matchId) {
                    setIsSaving(true);

                    // Check if there are any fighters selected in any match
                    const hasFighters = editableMatches.some(match =>
                        (match.name_heim && match.name_heim.trim()) ||
                        (match.name_ausw && match.name_ausw.trim())
                    );

                    // Calculate the total sieg and UBW values from all matches
                    const totalResults = editableMatches.reduce(
                        (totals, match) => {
                            return {
                                sieg_h: totals.sieg_h + match.sieg_h,
                                sieg_a: totals.sieg_a + match.sieg_a,
                                ubw_h: totals.ubw_h + match.ubw_h,
                                ubw_a: totals.ubw_a + match.ubw_a,
                                shido_h: totals.shido_h + match.shido_h,
                                shido_a: totals.shido_a + match.shido_a
                            };
                        },
                        { sieg_h: 0, sieg_a: 0, ubw_h: 0, ubw_a: 0, shido_h: 0, shido_a: 0 }
                    );

                    // Initialize points
                    let punkte_h = 0;
                    let punkte_a = 0;

                    // Only calculate points if there are fighters selected
                    if (hasFighters) {
                        // Standard point calculation logic
                        if (totalResults.sieg_h > totalResults.sieg_a) {
                            punkte_h = 2;
                            punkte_a = 0;
                        } else if (totalResults.sieg_a > totalResults.sieg_h) {
                            punkte_h = 0;
                            punkte_a = 2;
                        } else {
                            // It's a tie - base case is 1 point each
                            punkte_h = 1;
                            punkte_a = 1;

                            // Add bonus point for decision match winner
                            if (decisionMatchWinner === 'home') {
                                punkte_h += 1;
                            } else if (decisionMatchWinner === 'away') {
                                punkte_a += 1;
                            }
                        }
                    } else {
                        // No fighters selected, set both points to 0
                        punkte_h = 0;
                        punkte_a = 0;
                    }

                    console.log('Calculated match results:', {
                        ...totalResults,
                        punkte_h,
                        punkte_a,
                        decisionMatchWinner,
                        hasFighters
                    });

                    // Use the toaster function for the async operation
                    const success = await toaster(
                        async () => {
                            // Create payload with basic match data
                            const payload = {
                                erg_siege_h: totalResults.sieg_h,
                                erg_siege_a: totalResults.sieg_a,
                                erg_ubw_h: totalResults.ubw_h,
                                erg_ubw_a: totalResults.ubw_a,
                                erg_shido_h: totalResults.shido_h,
                                erg_shido_a: totalResults.shido_a,
                                erg_punkte_h: punkte_h,
                                erg_punkte_a: punkte_a,
                                // Include referee assignments
                                kampfrichter1_mg_id: selectedReferees.kampfrichter1_mg_id,
                                kampfrichter2_mg_id: selectedReferees.kampfrichter2_mg_id,
                                kampfrichter3_mg_id: selectedReferees.kampfrichter3_mg_id,
                                ...(decisionMatchWinner ? { decisionMatchWinner } : { decisionMatchWinner: null })
                            };

                            // Make the API call with the complete payload
                            await LeagueService.updateBlBegegnung(
                                role.orgId,
                                matchId,
                                payload
                            );
                            return true;
                        },
                        {
                            success: 'Ergebnisse wurden erfolgreich in Tabelle übernommen',
                            failure: 'Fehler beim Übernehmen der Ergebnisse in Tabelle'
                        },
                        () => {
                            // This runs regardless of success or failure
                            setIsSaving(false);
                        }
                    );

                    // If successful, update the matchDetail object with the new values
                    if (success && matchDetail) {
                        const updatedMatchDetail = {
                            ...matchDetail,
                            erg_siege_h: totalResults.sieg_h,
                            erg_siege_a: totalResults.sieg_a,
                            erg_ubw_h: totalResults.ubw_h,
                            erg_ubw_a: totalResults.ubw_a,
                            erg_shido_h: totalResults.shido_h,
                            erg_shido_a: totalResults.shido_a,
                            erg_punkte_h: punkte_h,
                            erg_punkte_a: punkte_a,
                            // Also update referee information in the matchDetail
                            kampfrichter1_mg_id: selectedReferees.kampfrichter1_mg_id,
                            kampfrichter2_mg_id: selectedReferees.kampfrichter2_mg_id,
                            kampfrichter3_mg_id: selectedReferees.kampfrichter3_mg_id
                        };

                        // Also update the erg_entscheid in matchDetail
                        if (decisionMatchWinner) {
                            updatedMatchDetail.erg_entscheid = {
                                winner: decisionMatchWinner
                            };
                        } else {
                            updatedMatchDetail.erg_entscheid = null;
                        }

                        setMatchDetail(updatedMatchDetail);
                        console.log('Match results updated successfully and reflected in UI');
                    }
                }
            } catch (error) {
                console.error('Error in handleSubmitMatchResults:', error);
                toastError('Fehler beim Übernehmen der Ergebnisse in Tabelle');
                setIsSaving(false);
            }
        };



        //

        useEffect(() => {
            if (matchDetail && matchDetail.erg_entscheid && matchDetail.erg_entscheid.winner) {
                // Only initialize if there's an actual winner set in the API data
                setDecisionMatchWinner(matchDetail.erg_entscheid.winner);
            } else {
                // Explicitly set to null if no winner is set in the API
                setDecisionMatchWinner(null);
            }
            console.log('+++        matchDetail', matchDetail);
        }, [matchDetail]);

        if (loading) {
            return <Loading />;
        }



        return (
            <ItemNavGroup label={matchDetail ? `${matchDetail.teamname_h} vs ${matchDetail.teamname_a}` : "New Match"}>
                <div>
                    <div className={styles.matchHeader}>
                        <div className={styles.headerTeams}>
                            <div className={styles.headerTeam}>{matchDetail?.teamname_h || "Heim"}</div>
                            <div className={styles.headerScore}>
                                {(matchDetail && matchDetail.erg_siege_h !== undefined && matchDetail.erg_siege_a !== undefined) ?
                                    `${matchDetail.erg_siege_h}:${matchDetail.erg_siege_a} (${matchDetail.erg_ubw_h}:${matchDetail.erg_ubw_a})` :
                                    "0:0 (0:0)"}
                            </div>

                            <div className={styles.headerTeam}>{matchDetail?.teamname_a || "Auswärts"}</div>
                        </div>
                        <div className={styles.matchDetails}>
                            <div className={styles.matchDetail}>
                                <strong>Liga:</strong> {matchDetail?.liga || ""}
                            </div>
                            <div className={styles.matchDetail}>
                                <strong>Runde:</strong> {matchDetail?.runde || ""} {matchDetail?.playoff && `(${matchDetail.playoff})`}
                            </div>
                            <div className={styles.matchDetail}>
                                <strong>Ort:</strong> {matchDetail?.ort || ""}
                            </div>
                            <div className={styles.matchDetail}>
                                <strong>Datum/Uhrzeit:</strong> {matchDetail ? FormatMatchDate(matchDetail.kampfbeginn, matchDetail.uhrzeit) : ""}
                            </div>
                        </div>
                    </div>

                    <div className={styles.actionButtons}>
                        {/* Edit/Save buttons */}
                        {isEditing ? (
                            <>  <div>
                                <div className={styles.refereeSelectionContainer}>
                                    <div className={styles.refereeSelect}>
                                        <label>Hauptkampfrichter:</label>
                                        <Select
                                            options={refereeOptions}
                                            value={selectedReferees.kampfrichter1_mg_id}
                                            onChange={(value) => handleRefereeChange('kampfrichter1_mg_id', value)}
                                            disabled={refereesLoading}
                                            placeholder="Hauptkampfrichter suchen"
                                            className={styles.refereeDropdown}
                                            onInputChange={(input) => console.log('Searching:', input)}
                                            type="number"
                                            isClearable={true}
                                        />
                                    </div>
                                    <div className={styles.refereeSelect}>
                                        <label>2. Kampfrichter:</label>
                                        <Select
                                            options={refereeOptions}
                                            value={selectedReferees.kampfrichter2_mg_id}
                                            onChange={(value) => handleRefereeChange('kampfrichter2_mg_id', value)}
                                            disabled={refereesLoading}
                                            placeholder="2. Kampfrichter suchen"
                                            className={styles.refereeDropdown}
                                            onInputChange={(input) => console.log('Searching:', input)}
                                            type="number"
                                            isClearable={true}
                                        />
                                    </div>
                                    <div className={styles.refereeSelect}>
                                        <label>3. Kampfrichter:</label>
                                        <Select
                                            options={refereeOptions}
                                            value={selectedReferees.kampfrichter3_mg_id}
                                            onChange={(value) => handleRefereeChange('kampfrichter3_mg_id', value)}
                                            disabled={refereesLoading}
                                            placeholder="3. Kampfrichter suchen"
                                            className={styles.refereeDropdown}
                                            onInputChange={(input) => console.log('Searching:', input)}
                                            type="number"
                                            isClearable={true}
                                        />
                                    </div>
                                </div>
                                <Button
                                    className={`${styles.actionButton} ${styles.saveButton}`}
                                    onClick={handleSaveChanges}
                                >
                                    Speichern
                                </Button>
                                <Button
                                    className={`${styles.actionButton} ${styles.cancelButton}`}
                                    onClick={() => {
                                        setEditableMatches([...matches]);
                                        setIsEditing(false);
                                    }}
                                >
                                    Exit
                                </Button>
                            </div>
                            </>
                        ) : (
                            <div>
                                <div className={styles.refereeSelectionContainer}>
                                    <div className={styles.refereeSelect}>
                                        <label>Hauptkampfrichter:</label>
                                        <span className={styles.refereeValue}>
                                            {selectedReferees.kampfrichter1_mg_id ?
                                                refereeOptions.find(r => r.value === Number(selectedReferees.kampfrichter1_mg_id))?.label || 'Not selected'
                                                : 'Not selected'}
                                        </span>
                                    </div>
                                    <div className={styles.refereeSelect}>
                                        <label>2. Kampfrichter:</label>
                                        <span className={styles.refereeValue}>
                                            {selectedReferees.kampfrichter2_mg_id ?
                                                refereeOptions.find(r => r.value === Number(selectedReferees.kampfrichter2_mg_id))?.label || 'Not selected'
                                                : 'Not selected'}
                                        </span>
                                    </div>
                                    <div className={styles.refereeSelect}>
                                        <label>3. Kampfrichter:</label>
                                        <span className={styles.refereeValue}>
                                            {selectedReferees.kampfrichter3_mg_id ?
                                                refereeOptions.find(r => r.value === Number(selectedReferees.kampfrichter3_mg_id))?.label || 'Not selected'
                                                : 'Not selected'}
                                        </span>
                                    </div>
                                </div>
                                <Button
                                    className={`${styles.actionButton} ${styles.editButton}`}
                                    onClick={() => setIsEditing(true)}
                                >
                                    Eingabe
                                </Button>
                            </div>
                        )}

                    </div>
                </div>

                {/* Use the extracted MatchTable component */}
                <div>
                    <MatchTable
                        rounds={rounds}
                        matchesByRound={matchesByRound}
                        homeTeamFighters={homeTeamFighters}
                        awayTeamFighters={awayTeamFighters}
                        isEditing={isEditing}
                        handleFieldChange={handleFieldChange}
                        matchDetail={matchDetail}
                        decisionMatchWinner={decisionMatchWinner}                 // Add this
                        handleDecisionMatchWinner={handleDecisionMatchChange}     // Add this
                    />
                    {isEditing && (
                        <>
                            <Button
                                className={`${styles.actionButton} ${styles.resultButton}`}
                                onClick={handleSubmitMatchResults}
                                disabled={isSaving}
                            >
                            Ergebnisse in Tabelle übernehmen
                            </Button>
                        </>
                    )}
                </div>
            </ItemNavGroup>
        );
    };