// src/components/help/HelpContext.tsx
import React, { createContext, useContext, useState } from 'react';
import { HelpWindow } from './HelpWindow';
import { ErrorBoundary } from '../ErrorBoundary';

interface HelpContextType {
    //openHelp: (section?: string, anchor?: string) => void;
    openHelp: (section: string, page?: string, anchor?: string) => void;
    closeHelp: () => void;
    isHelpOpen: boolean;
    currentSection?: string;
    currentPage?: string;
    currentAnchor?: string;
}

const HelpContext = createContext<HelpContextType | undefined>(undefined);

export const HelpProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isHelpOpen, setIsHelpOpen] = useState(false);
    const [currentSection, setCurrentSection] = useState<string>();
    const [currentPage, setCurrentPage] = useState<string>();
    const [currentAnchor, setCurrentAnchor] = useState<string>();

    const openHelp = (section: string, page?: string, anchor?: string) => {
        setCurrentSection(section);
        setCurrentPage(page);
        setCurrentAnchor(anchor);
        setIsHelpOpen(true);
    };

    const closeHelp = () => {
        setIsHelpOpen(false);
    };

    return (
        <HelpContext.Provider
            value={{
                openHelp,
                closeHelp,
                isHelpOpen,
                currentSection,
                currentAnchor
            }}
        >
            {children}
            {isHelpOpen && (
                <ErrorBoundary>
                    <HelpWindow
                        section={currentSection}
                        anchor={currentAnchor}
                        onClose={closeHelp}
                        isMobile={window.innerWidth <= 768}
                    />
                </ErrorBoundary>
            )}
        </HelpContext.Provider>
    );
};

export const useHelp = () => {
    const context = useContext(HelpContext);
    if (context === undefined) {
        throw new Error('useHelp must be used within a HelpProvider');
    }
    return context;
};