import JsBarcode from 'jsbarcode';


export class Barcode {
    public static generate(value: string, color: string = '#000', width: number= 2, height: number=20, fontSize: number=8) {
        const xmlSerializer = new XMLSerializer();
        // const document = new DOMImplementation().createDocument('http://www.w3.org/1999/xhtml', 'html', null);
        const svgData = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        // fixme just for test
        JsBarcode(svgData, value, {
            xmlDocument: document,
            height: height,
            fontSize: fontSize,
            margin: 1,
            width: width,
            lineColor: color,
            displayValue: true,
        });
        return xmlSerializer.serializeToString(svgData);
    }
}