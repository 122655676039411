import React from "react";
import {Card, Content, Header} from "../../components/foundation";
import {Link} from "react-router-dom";
import {withDesktop} from "../../api/withDesktop";
import styles from "./login.module.scss";


const Forbidden: React.FC = () => {
    return (<>

    <Content state={"active"}>
        <div className={styles.center}>
            <Card className={styles.forbidden}>
                <h2>Anmeldung JudoJAMA</h2>
                <Link to={"/login"}>
                    <p>Melde dich bitte <b>hier</b> an!</p>
                </Link>
            </Card>
            <a
                href="https://alt.judojama.org"
                className={styles.oldjama}
                rel="noopener noreferrer"
                target="_blank"
            >
                    <p>altes Jama unter: https://alt.judojama.org (auf gleicher Datenbasis)</p>
            </a>

        </div>
    </Content>

</>)
}

export default withDesktop(Forbidden);