
import React, { useEffect, useState } from 'react';
import { Image } from '@react-pdf/renderer';
import JsBarcode from 'jsbarcode';

const PDFBarcode: React.FC<{ value: string }> = ({ value }) => {
    const [barcodeUrl, setBarcodeUrl] = useState<string>('');

    useEffect(() => {
        // Create a high-resolution canvas element
        const canvas = document.createElement('canvas');

        // Set higher canvas resolution (multiply size by device pixel ratio)
        const scale = window.devicePixelRatio || 2; // Use at least 2x resolution
        const width = 300 * scale;
        const height = 100 * scale;

        canvas.width = width;
        canvas.height = height;
        canvas.style.width = `${width / scale}px`;
        canvas.style.height = `${height / scale}px`;

        // Get context and scale it
        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.scale(scale, scale);
        }

        try {
            // Generate the barcode with improved settings
            JsBarcode(canvas, value, {
                format: "CODE128",
                displayValue: false,
                fontSize: 8,
                height: 70,
                width: 2, // Wider bars for better readability
                margin: 1,
                background: "#ffffff",
                lineColor: "#000000",
                textMargin: 8,
                font: "monospace",
                fontOptions: "bold",
                // High quality rendering
                valid: (valid) => {
                    if (!valid) console.error("Invalid barcode value:", value);
                }
            });

            // Convert canvas to high-quality PNG
            const dataUrl = canvas.toDataURL('image/png', 1.0); // 1.0 = highest quality
            setBarcodeUrl(dataUrl);
        } catch (error) {
            console.error('Error generating barcode:', error);
            setBarcodeUrl('');
        }
    }, [value]);

    // If we have a barcode URL, render it as an image
    if (barcodeUrl) {
        return <Image src={barcodeUrl} style={{ width: '100%', height: 30 }} />;
    }

    // Fallback
    return <Image src="" style={{ width: '100%', height: 30, backgroundColor: '#f0f0f0' }} />;
};

export default PDFBarcode;