import React from 'react';
import { IPageLinkProps } from "../../page.props";
import { ItemNavGroup } from "../../form/ItemNavGroup";
import { Form, Item } from "../../form";
import { RolesTable } from "./organization_functionsTable";
import {useParamId, useParamRole} from "../../useParam";  // Add this import

const OrganizationFunctions: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    // We need organizationId and adminOrgId
    const organizationId = useParamId(); // or however you get the organization ID
    const role = useParamRole(user);; // or however you get the admin org ID

    console.log("adminOrgId:", role.orgId);
    console.log("organizationId:", organizationId);


    return (
        <>
            <ItemNavGroup label="Funktionen & Lizenzen">
                <Form>
                    <Item type="full">
                        <RolesTable
                            adminOrgId={role.orgId}
                            orgId={organizationId || role.orgId}
                        />
                    </Item>
                </Form>
            </ItemNavGroup>
        </>
    );
};

export default OrganizationFunctions;