

import judo_en from "./judoaustria/en/en";
import judo_de from "./judoaustria/de/de";
import archery_en from "./archeryaustria/en/en";
import archery_de from "./archeryaustria/de/de"
import { Ii18n } from "./app";
import preval from "preval.macro";

const edition = preval`
require('dotenv').config();
module.exports = process.env.JAMA_EDITION
`

type TLanguages = "en" | "de";

export function getLang(): TLanguages {
    return (localStorage.getItem("lang") || "de") as TLanguages;
}

export function setLang(lang: TLanguages) {
    localStorage.setItem("lang", lang);
}

const index = () => ({
    en: edition === 'archeryaustria' ? archery_en : judo_en,
    de: edition === 'archeryaustria' ? archery_de : judo_de,
}[getLang()] as Ii18n);

export default index;
