import React, { useState }  from 'react';
import {Card} from '../../foundation';
import {IPageLinkProps} from "../../page.props";
import {Button} from "../../form";
import {useNavigate} from "react-router-dom";
import styles from "./trainer.module.scss";
import {LicenceService} from "../../../api/licence";
import {ProfileService} from "../../../api";

export const ProfileTrainer: React.FC<IPageLinkProps> = ({user, profile, gotoUrl}) => {
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState([false, false, false, false, false, false]); // Extended for all checkboxes

    const handleCheckboxChange = (index: number) => {
        const newIsChecked = [...isChecked];
        newIsChecked[index] = !newIsChecked[index];
        setIsChecked(newIsChecked);
    }

    const apply = async() => {
        const id = await ProfileService.trainerApply(0, 10)
        navigate(`${gotoUrl}`)
        window.location.reload();
    }

    return (
        <Card className={styles.card}>
            <div className={styles.trainer}>
                <h2>EHRENKODEX</h2>
                <p>Für alle Personen, die Ehren-, Neben- oder Hauptberuflich im organisierten Sport in Österreich tätig
                    sind.
                </p>
                <p><strong>Ich verpflichte mich, ...</strong></p>
                <ul>
                    <li>Die Würde der Sportlerinnen und Sportler zu respektieren, unabhängig von Alter, Geschlecht, sexueller Orientierung,
                        sozialer und ethnischer Herkunft, Weltanschauung, Religion, politischer Überzeugung oder wirtschaftlicher Stellung,
                        alle mir anvertrauten Sportlerinnen und Sportler gleich und fair zu behandeln, keinerlei Gewalt gegenüber den mir
                        anvertrauten Athletinnen und Athleten anzuwenden, insbesondere keine sexuelle Gewalt oder sexualisierte Übergriffe
                        in Worten, Gesten, Handlungen oder Taten, die persönlichen Grenzen und individuellen Empfindungen zu Nähe und Distanz
                        der mir anvertrauten Personen zu achten und mich dementsprechend respektvoll zu verhalten,
                    </li>
                </ul>
                <div className="checkbox-container">
                    <input type="checkbox" id="checkbox1" checked={isChecked[0]} onChange={() => handleCheckboxChange(0)} disabled={isChecked[0]} />
                    <label htmlFor="checkbox1"><strong> zustimmen</strong></label>
                </div>
                {isChecked[0] && (
                    <>
                        <ul>
                            <li>
                                mich bei Konflikten um offene, gerechte und humane Lösungen zu bemühen,
                                die Eigenverantwortlichkeit und die Selbstständigkeit der Sportlerinnen und Sportler zu unterstützen,
                                auch im Hinblick auf deren späteres Leben, die Anforderungen des Sports in Training und Wettkampf mit
                                den Belastungen des sozialen Umfeldes, insbesondere von Familie, Schule, Ausbildung und Beruf,
                                bestmöglich in Einklang zu bringen,
                            </li>
                        </ul>

                        <div className="checkbox-container">
                            <input type="checkbox" id="checkbox2" checked={isChecked[1]} onChange={() => handleCheckboxChange(1)} disabled={isChecked[1]} />
                            <label htmlFor="checkbox2"><strong> zustimmen</strong></label>
                        </div>
                    </>
                )}
                {isChecked[1] && (
                    <>

                    <p>ein pädagogisch verantwortliches Handeln anzustreben, insbesondere...</p>
                        <ul>
                            <li>
                                die Selbstbestimmung der mir anvertrauten Sportlerinnen und Sportler zu fördern,</li>
                            <li>
                                Sportlerinnen und Sportler in Entscheidungen, die diese persönlich betreffen, mit einzubeziehen,</li>
                            <li>verfügbare Informationen zur Entwicklung und Optimierung der Leistung von Sportlerinnen und Sportlern an diese weiterzugeben und
                                bei Minderjährigen die Interessen der Erziehungsberechtigten zu berücksichtigen</li>
                             <li>Sportlerinnen und Sportler darüber hinaus zu sozialem Verhalten in der Trainingsgemeinschaft, zu fairem Verhalten
                                innerhalb und außerhalb des Wettkampfes und zum nötigen Respekt gegenüber allen anderen in das Sportgeschehen
                                eingebundenen Personen sowie zum verantwortungsbewussten Umgang mit der Natur und der Mitwelt anzuregen,
                            </li>
                        </ul>

                        <div className="checkbox-container">
                            <input type="checkbox" id="checkbox3" checked={isChecked[2]} onChange={() => handleCheckboxChange(2)} disabled={isChecked[2]} />
                            <label htmlFor="checkbox3"><strong> zustimmen</strong></label>
                        </div>
                    </>
                )}

                {isChecked[2] && (
                    <>
                        <ul>
                            <li>
                                anzuerkennen, dass das Interesse der Athletinnen und Athleten, ihre Gesundheit und ihr Wohlbefinden über den Interessen
                                und den Erfolgszielen der Trainerinnen und Trainer, der Instruktorinnen und Instruktoren sowie der eigenen Sportorganisation stehen</li>
                                <li>alle meine Trainingsmaßnahmen dem Alter, der Erfahrung sowie dem aktuellen physischen und psychischen Zustand
                                    der Sportlerinnen und Sportler anzupassen,</li>
                                <li>nach bestem Wissen und Gewissen den Gebrauch verbotener Mittel (Doping) zu unterbinden und Suchtgefahren (Drogen-, Nikotin-
                                    und Alkoholmissbrauch) vorzubeugen. Ich werde durch gezielte Aufklärung und unter Wahrnehmung meiner Vorbildfunktion negativen
                                    Entwicklungen entgegenwirken.</li>
                        </ul>

                        <div className="checkbox-container">
                            <input type="checkbox" id="checkbox4" checked={isChecked[3]} onChange={() => handleCheckboxChange(3)} disabled={isChecked[3]} />
                            <label htmlFor="checkbox4"><strong> zustimmen</strong></label>
                        </div>
                    </>
                )}

                {isChecked[3] && (
                    <>
                        <p>Ich verplfichte mich das <strong>"Bekenntnis für Respekt und gegen Gewalt"</strong> des Österreichischen Judoverbands in meiner gesamten Tätigkeit zu achten.</p>
                        <h3>Bekenntnis für Respekt und gegen Gewalt</h3>

                        <p>Der Österreichische Judoverband verurteilt jegliche Form von Gewalt, unabhängig davon, ob sie
                            körperlicher, seelischer oder sexualisierter Art ist.
                        </p>

                        <p>Der Österreichische Judoverband und seine Mitglieder verpflichten sich,</p>

                        <ul>
                            <li>die Würde aller zu respektieren, unabhängig von Alter, Geschlecht, sexueller Orientierung,
                                sozialer, ethnischer und kultureller Herkunft, Weltanschauung, Religion, politischer Überzeugung
                                oder wirtschaftlicher Stellung, sowie Diskriminierung jeglicher Art entgegenzuwirken,
                            </li>
                            <li>alle fair zu behandeln,</li>
                            <li>keinerlei physische oder psychische Gewalt anzuwenden (insbesondere keine sexuelle Gewalt oder
                                sexualisierte Übergriffe in Worten, Gesten, Handlungen und Taten),
                            </li>
                            <li>die persönlichen Grenzen und individuellen Empfindungen zu Nähe und Distanz zu achten und sich
                                dementsprechend respektvoll zu verhalten,
                            </li>
                        </ul>

                        <div className="checkbox-container">
                            <input type="checkbox" id="checkbox5" checked={isChecked[4]} onChange={() => handleCheckboxChange(4)} disabled={isChecked[4]} />
                            <label htmlFor="checkbox5"><strong> zustimmen</strong></label>
                        </div>
                    </>
                )}

                {isChecked[4] && (
                    <>
                        <ul>
                            <li>sich bei Konflikten um offene, gerechte und humane Lösungen zu bemühen,</li>
                            <li>die Eigenverantwortlichkeit und die Selbständigkeit zu unterstützen,</li>
                            <li>ein pädagogisch verantwortliches Handeln anzustreben,</li>
                            <li>soziales und faires Verhalten und den nötigen Respekt gegenüber anderen zu leben,</li>
                            <li>anzuerkennen, dass das Interesse jedes und jeder Einzelnen, seine/ihre Gesundheit und sein/ihr
                                Wohlbefinden über den Interessen und den Erfolgszielen des Österreichischen Judoverbands stehen,
                            </li>
                            <li>Maßnahmen dem Alter, der Erfahrung sowie dem aktuellen physischen und psychischen Zustand
                                anzupassen,
                            </li>
                            <li>nach bestem Wissen und Gewissen den Gebrauch verbotener Mittel (Doping) zu unterbinden und
                                Suchtgefahren (Drogen-, Nikotin- und Alkoholmissbrauch) vorzubeugen,
                            </li>
                            <li>durch gezielte Aufklärung und unter Wahrnehmung der Vorbildfunktion negativen Entwicklungen
                                entgegenzuwirken sowie
                            </li>
                            <li>die im Österreichischen Judoverband gültigen Regeln in Hinblick auf den Schutz der Privatsphäre,
                                die Kommunikationskultur, das 6-Augen-Prinzip bzw. das Prinzip der offenen Tür einzuhalten.
                            </li>
                        </ul>

                        <div className="checkbox-container">
                            <input type="checkbox" id="checkbox6" checked={isChecked[5]} onChange={() => handleCheckboxChange(5)} disabled={isChecked[5]} />
                            <label htmlFor="checkbox6"><strong> zustimmen</strong></label>
                        </div>
                    </>
                )}

                {isChecked[5] && (
                    <div className={styles['button-container']}>
                        <Button width='full' label={'Zustimmen'} onClick={apply}/>
                    </div>
                )}
                <p> </p>
            </div>
        </Card>
    );
};