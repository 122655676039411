import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {IOrgFunction, IUserModel, IUserProfile} from './api/models';

/* Components */
import {Application, Tab, Tabs} from './components/foundation';
import {
    faNewspaper,
    faImages,
    faCalendarDays,
    faSignIn,
    faIdCard,
    faBars
} from "@fortawesome/free-solid-svg-icons";
import PublicNews from "./pages/mobile/news/PublicNews";
import MemberNews from "./pages/mobile/news/MemberNews";
import NewsDetail from "./pages/mobile/news/NewsDetail";
import Gallery from "./pages/mobile/gallery/Gallery";
import GalleryDetail from "./pages/mobile/gallery/GalleryDetail";
import Login from "./pages/mobile/auth/Login";
import Signup from "./pages/mobile/auth/Signup";
import MemberCard from "./pages/mobile/member/Card";
import MemberProfile from './pages/mobile/more/profile/Profile'
import MemberProfileEdit from './pages/mobile/more/profile/ProfileEdit'

import Tournament from "./pages/mobile/tournament/judo/Tournament";
import TournamentDetail from "./pages/mobile/tournament/judo/TournamentDetail";

import {Forbidden} from "./pages/mobile/Forbidden";
import {withAuth} from "./api/withAuth";
import More from "./pages/mobile/more/More";
import {Toaster} from "./core/toaster";
import {Navigate} from "react-router";


import { AppForm } from './pages/mobile/more/admin/AppForm';
import ProfileTrainer from "./pages/mobile/more/profile/ProfileTrainer";
import styles from './app__mobile.module.scss';

import AdminTournament from "./pages/mobile/more/admin/tournament/AdminTournament";
import AdminOrg from "./pages/mobile/more/admin/organization/AdminOrg";
import AdminTrainer from "./pages/mobile/more/admin/trainer/AdminTrainer";
import AdminTrainerJudoCards from "./pages/mobile/more/admin/trainer/AdminTrainerJudoCards";
import AdminTrainerNomination from "./pages/mobile/more/admin/trainer/AdminTrainerNomination";
import AdminTrainerKyu from "./pages/mobile/more/admin/trainer/AdminTrainerKyu";
import AdminOrgLicence from "./pages/mobile/more/admin/organization/licence/AdminOrgLicence";
import AdminOrgLicenceOrder from "./pages/mobile/more/admin/organization/licence/AdminOrgLicenceOrder";
import AdminOrgLicenceSubmit from "./pages/mobile/more/admin/organization/licence/AdminOrgLicenceSubmit";
import AdminOrgLicenceApprove from "./pages/mobile/more/admin/organization/licence/AdminOrgLicenceApprove";
import AdminOrgLicenceNew from "./pages/mobile/more/admin/organization/licence/AdminOrgLicenceNew";
import AdminOrgLicenceDelete from "./pages/mobile/more/admin/organization/licence/AdminOrgLicenceDelete";
import AdminOrgMember from "./pages/mobile/more/admin/organization/member/AdminOrgMember";
import AdminOrgMemberDetail from "./pages/mobile/more/admin/organization/member/AdminOrgMemberDetail";
import AdminOrgMeetingEdit from "./pages/mobile/more/admin/organization/meeting/AdminOrgMeetingEdit";
import AdminOrgMeetingNew from "./pages/mobile/more/admin/organization/meeting/AdminOrgMeetingNew";
import AdminOrgMeeting from "./pages/mobile/more/admin/organization/meeting/AdminOrgMeeting";
import OrganizationMeetingRoles from "./pages/mobile/more/admin/organization/meeting/AdminOrgMeetingRoles";
import OrganizationMetingCandidates from "./pages/mobile/more/admin/organization/meeting/AdminOrgMeetingCandidates";
import AdminOrgOrganizationEdit from "./pages/mobile/more/admin/organization/organization/AdminOrgOrganizationEdit";
import AdminOrgOrganization from "./pages/mobile/more/admin/organization/organization/AdminOrgOrganization";
import PersonalNewsDetail from "./pages/mobile/news/PersonalNews/PersonalNewsDetail";


interface IAppProps {
    auth: boolean;
    user: IUserModel;
    profile: IUserProfile;
    refresh: () => Promise<void>;
}

interface IAppState {
    loading: boolean;
    isAuthorized: boolean;
    user?: IUserModel;
    adminRoles: IOrgFunction[];
    trainerRoles: IOrgFunction[];
    tunierAdminRoles: IOrgFunction[];
}

const AppJudoMobile: React.FC<IAppProps> = ({auth, user, profile, refresh}) => {
    // const navigate = useNavigate();
    //console.log('+auth+', auth);
    /*CapApp.addListener('backButton', (ev: BackButtonListenerEvent) => {
        // todo close app if no more go back
        console.log('go-back')
        / *if (!history..canGoBack()) {
            App_judo_mobile.exitApp();
        } else {* /
        navigate(-1);
    });*/

    const pprops = {user, profile};

    return (
        <BrowserRouter basename="/">
            <Application>
                <Toaster />
                <div className={styles.layout}>
                    <Routes>
                        <Route path="/news" element={!auth ?  <PublicNews /> : <MemberNews />} />
                        <Route path="/news/:id" element={<NewsDetail />} />
                        <Route path="/news/notification" element={auth ? <MemberNews isPersonalNews={true} /> : <Login />} />
                        <Route path="/news/notification/:id" element={auth ? <PersonalNewsDetail /> : <Login />} />
                        <Route path="/gallery" element={<Gallery />} />
                        <Route path="/gallery/:id" element={<GalleryDetail />} />
                        <Route path="/event" element={<Tournament />} />
                        <Route path="/event/:id" element={<TournamentDetail />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/membercard" element={auth ? <MemberCard user={user} refresh={refresh} /> : <Login />} />
                        <Route path="/more/profile" element={auth ? <MemberProfile user={user} refresh={refresh} /> : <Login />} />
                        <Route path="/more/profile/trainer" element={auth ? <ProfileTrainer user={user} refresh={refresh} /> : <Login />} />
                        <Route path="/more/profile/edit" element={auth ? <MemberProfileEdit user={user} refresh={refresh} /> : <Login />} />
                        <Route path="/more" element={auth ? <More user={user} refresh={refresh} /> : <Login />} />
                        <Route path="/more/tournament" element={auth ? <AdminTournament /> : <Login />} />

                        <Route path="/more/admin/:org/:role/:form" element={auth ? <AppForm {...pprops} /> : <Login/>} />
                        <Route path="/more/admin/:org/:role/:form/:id" element={auth ? <AppForm {...pprops} /> : <Login/>} />

                        <Route path="/more/organization/edit/:role" element={auth ? <AdminOrgOrganizationEdit user={user} profile={profile} /> : <Login />} />
                        <Route path="/more/organization/edit/:role/:id" element={auth ? <AdminOrgOrganizationEdit user={user} profile={profile} /> : <Login />} />
                        <Route path="/more/organization/list/:role" element={auth ? <AdminOrgOrganization user={user} profile={profile} /> : <Login />} />
                        <Route path="/more/organization/:id" element={auth ? <AdminOrg user={user} /> : <Login />} />
                        <Route path="/more/organization/licence/:role" element={auth ? <AdminOrgLicence user={user} /> : <Login />} />
                        <Route path="/more/organization/licence/:role/new" element={auth ? <AdminOrgLicenceNew user={user} /> : <Login />} />

                        <Route path="/more/organization/licence/:role/:id/order" element={auth ? <AdminOrgLicenceOrder user={user} /> : <Login />} />
                        <Route path="/more/organization/licence/:role/:id/submit" element={auth ? <AdminOrgLicenceSubmit user={user} /> : <Login />} />
                        <Route path="/more/organization/licence/:role/:id/approve" element={auth ? <AdminOrgLicenceApprove user={user} /> : <Login />} />
                        <Route path="/more/organization/licence/:role/:id/delete" element={auth ? <AdminOrgLicenceDelete user={user} /> : <Login />} />

                        <Route path="/more/organization/member/:role" element={auth ? <AdminOrgMember user={user} profile={profile} /> : <Login />} />
                        <Route path="/more/organization/member/:role/:id" element={auth ? <AdminOrgMemberDetail user={user} profile={profile}/> : <Login />} />

                        <Route path="/more/organization/meeting/:id" element={auth ? <AdminOrgMeeting user={user} /> : <Login />} />
                        <Route path="/more/organization/meeting/:role/new" element={auth ? <AdminOrgMeetingNew user={user} profile={profile} /> : <Login />} />
                        <Route path="/more/organization/meeting/:role/:id/edit" element={auth ? <AdminOrgMeetingEdit user={user} profile={profile}/> : <Login />} />
                        <Route path="/more/organization/meeting/:role/:id/:type" element={auth ? <OrganizationMetingCandidates user={user} profile={profile}/> : <Login />} />
                        <Route path="/more/organization/meeting/:role/:id" element={auth ? <OrganizationMeetingRoles user={user} profile={profile} /> : <Login />} />

                        <Route path="/more/trainer/:id" element={auth ? <AdminTrainer user={user} /> : <Login />} />
                        <Route path="/more/trainer/membership/:id" element={auth ? <AdminTrainerJudoCards user={user} /> : <Login />} />
                        <Route path="/more/trainer/kyu/:id" element={auth ? <AdminTrainerKyu user={user} /> : <Login />} />
                        {/*<Route path="/more/trainer/kyu/:role/new" element={auth ? <AdminTrainerKyuNew user={user} /> : <Login />} />
                        <Route path="/more/trainer/kyu/:role/:id/edit" element={auth ? <AdminTrainerKyuEdit user={user} /> : <Login />} />
                        <Route path="/more/trainer/kyu/:role/:id" element={auth ? <AdminTrainerKyuCandidates user={user} /> : <Login />} />*/}
                        <Route path="/more/trainer/nomination" element={auth ? <AdminTrainerNomination /> : <Login />} />
                        <Route path="/" element={<Navigate to="/news" />} />
                        <Route path="*" element={<Login />} />
                    </Routes>
                    <div className={styles.tabsContainer}>
                    <Tabs>
                        <Tab href="/news" icon={faNewspaper} label="News"></Tab>
                        <Tab href="/gallery" icon={faImages} label="Gallery"></Tab>
                        <Tab href="/event" icon={faCalendarDays} label="Events"></Tab>
                        {auth && <Tab href="/membercard" icon={faIdCard} label={"JCard"}/> }
                        {auth ?
                            <Tab href={"/more"} icon={faBars} label="More" />
                            :
                            <Tab href={"/login"} icon={faSignIn} label="Member area" />}
                    </Tabs>
                    </div>
                </div>
            </Application>
        </BrowserRouter>
    )
}

export default withAuth(AppJudoMobile);
