import {useEffect, useState} from "react";
import {AuthService, CacheService} from "./index";
import {IUserModel} from "./models/UserModel";
import {Loading} from "../components/foundation/Loading";
import {IUserProfile} from "./models/AuthModel";
import {LocalService} from "../api";

export interface IAppAuth {
    auth: boolean;
}

let dontRunAuthenticateTwice = false

/**
 * HOC (Higher Order Component) that provides authentication functionality to the wrapped component.
 *
 * @param {React.Component} Component - The component to be wrapped with authentication functionality
 */
export const withAuth = (Component: any) => (props: any) => {
    const [state, setState] = useState<IAppAuth|null>(null);
    const [user, setUser] = useState<IUserModel|null>(null);
    const [profile, setProfile] = useState<IUserProfile|null>(null)

    useEffect(() => {
        const authenticate = async() => {
            try {
                /*await AuthService.refresh('global', {
                    refresh: localStorage.getItem('refresh') || '',
                    token: localStorage.getItem('token') || '',
                })*/
                await AuthService.refreshJwt()
                const usr = await refresh();
                // console.log(usr)
                setState({
                    auth: !!usr,
                })
            } catch (e) {
                // console.error(e);
                setState({
                    auth: false,
                })
            }
        }

        if (!dontRunAuthenticateTwice) {
            authenticate()
        }
        dontRunAuthenticateTwice=true
    }, [])

    const refresh = async () => {
        CacheService.clear();
        // console.log('Starting refresh...');
        // console.log('Auth status:', LocalService.getAuth()); // Check if we have valid auth

        try {
            const [usr, profile] = await Promise.all([AuthService.getUser(), AuthService.getProfile()]);
            // console.log('API Response - user:', usr);
            // console.log('API Response - profile:', profile);

            if (!usr) {
                // console.log('Warning: User data is null after getUser()');
            }

            setUser(usr);
            setProfile(profile);

                // console.log('*usr*', usr);
            return usr;
        } catch (error) {
            console.error('Refresh error:', error);
            return null;
        }
    };

    useEffect(() => {
        // console.log('*User* state updated:', user);
    }, [user]);

    return state ? <Component {...props} {...state} user={user} profile={profile} refresh={refresh}/> : <Loading />;
};
