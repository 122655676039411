import {Constants} from "./constants";
import {HttpService} from "./http";
import {IKyuCandidate, IKyuChairMember, IKyuExam, ILicenceOrder, ILicenceCandidate, ILicenceCategory} from "./models";
import {AuthService} from "./auth";


const GET_LICENCES = `${Constants.JAMA_SERVER_API_V2}/admin/:org/licences`;
const GET_LICENCE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/licence/`;
const GET_LICENCE_CATEGORIES = `${Constants.JAMA_SERVER_API_V2}/admin/:org/licence/categories`;
const GET_LICENCE_CANDIDATES = `${Constants.JAMA_SERVER_API_V2}/admin/:org/licence/candidates/`;
const PUT_LICENCE_CANDIDATE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/licence/candidates`;
const POST_LICENCE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/licence`;
const PUT_LICENCE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/licence`;
const PUT_LICENCE_SUBMIT = `${Constants.JAMA_SERVER_API_V2}/admin/:org/licence/submit/`;
const PUT_LICENCE_APPROVE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/licence/approve/`;
//const PUT_KYU_EXAM_UPGRADE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu/:id/upgrade`;
//const GET_KYU_EXAM_CHAIR_MEMBERS = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu/chair/search`;
//const GET_KYU_EXAM_CHAIR_MEMBER = `${Constants.JAMA_SERVER_API_V2}/admin/:org/kyu/chair/get`;



/**
 * Represents a request object for creating an instance of IKyu.
 */
export interface IHandleAdminLicenceCreateReq {
    categoryId: number;
}

/**
 * Interface for updating Kyu exams.
 */
interface IHandleAdminLicenceUpdateReq {
    id: number;
    name: string;
    date: string;
}



/**
 * A class that provides methods for interacting with Licence orders and candidates.
 */
export class LicenceService {

    public static async all(org: number): Promise<ILicenceOrder[]> {
        // await AuthService.refreshJwt();
        const res = await HttpService.get<ILicenceOrder[]>(`${GET_LICENCES}`.replace('/:org/', `/${org}/`), true);
        return res || [];
    }

    public static async get(org: number, id: number): Promise<ILicenceOrder> {
        // await AuthService.refreshJwt();
        const res = await HttpService.get<ILicenceOrder>(`${GET_LICENCE}${id}`.replace('/:org/', `/${org}/`), true);
        return res || []
    }

    public static async categories(org: number) : Promise<ILicenceCategory[]> {
        // await AuthService.refreshJwt();
        const res = await HttpService.get<ILicenceCategory[]>(`${GET_LICENCE_CATEGORIES}`.replace('/:org/', `/${org}/`), true);
        return res || [];
    }


    public static async candidates(org: number, id: number): Promise<ILicenceCandidate[]> {
        // await AuthService.refreshJwt();
        const res = await HttpService.get<ILicenceCandidate[]>(`${GET_LICENCE_CANDIDATES}${id}`.replace('/:org/', `/${org}/`), true)
        return res || [];
    }


    public static async update(org: number, orderId: number, memberId: number, remove: boolean): Promise<boolean> {
        // await AuthService.refreshJwt();
        const res = await HttpService.put2<{
            id: number,
            mgId: number,
            remove: boolean
        }, boolean>(PUT_LICENCE_CANDIDATE.replace('/:org/', `/${org}/`), {
            id: orderId,
            mgId: memberId,
            remove,
        });
        return res || false;
    }


    public static async create(org: number, data: IHandleAdminLicenceCreateReq) {
        // await AuthService.refreshJwt();
        return await HttpService.post2<IHandleAdminLicenceCreateReq, number>(POST_LICENCE.replace('/:org/', `/${org}/`), data);
    }


    public static async submit(org: number, id: number) {
        // await AuthService.refreshJwt();
        return await HttpService.put2<null, boolean>(PUT_LICENCE_SUBMIT.replace('/:org/', `/${org}/`) + id, null);
    }

    public static async approve(org: number, id: number) {
        // await AuthService.refreshJwt();
        return await HttpService.put2<null, boolean>(PUT_LICENCE_APPROVE.replace('/:org/', `/${org}/`) + id, null);
    }

    public static async delete(org: number, id: number) {
        // await AuthService.refreshJwt();
        return await HttpService.delete<boolean>(PUT_LICENCE.replace('/:org/', `/${org}/`) + `/${id}`);
    }
}