// src/components/admin/organization/organization_trainings.tsx
import React from 'react';
import { IPageLinkProps } from "../../page.props";
import { ItemNavGroup } from "../../form/ItemNavGroup";
import { Form, Item, Label } from "../../form";

const OrganizationTrainings: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    return (
        <>
            <ItemNavGroup label="Ausbildungen">
                <Form>
                    <Item type="full">
                        <Label>
                            Hier werden in Zukunft die Ausbildungen der Organisation angezeigt.
                        </Label>
                    </Item>
                </Form>
            </ItemNavGroup>
        </>
    );
};

export default OrganizationTrainings;