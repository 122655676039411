import React, { useState, useEffect } from 'react';
import { Card } from "../../components/foundation";
import {Button, Email, Form, Item, Submit} from "../../components/form";
import { Password } from "../../components/form/Password";
import styles from "./login.module.scss";
import { toaster } from "../../core/toaster";
import { AuthService } from "../../api";
import i18n from "../../config/i18n";
import { Link } from "react-router-dom";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {toast } from "react-toastify";
import { Tooltip } from 'react-tooltip';
import {LocalService} from "../../api";
import { useHelp } from '../../components/help/HelpContext';
import { ApiError } from "../../api/http";
import packageJson from '../../../package.json';

interface ILoginProps {
    // Define any props here if needed in the future
}

export const Login: React.FC<ILoginProps> = () => {
    const [email, setEmail] = useState<string | null>(localStorage.getItem('email'));
    //const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const { openHelp } = useHelp();  // Add this hook
    const version = packageJson.version || undefined;

    useEffect(() => {
        console.log(email);
    }, [email]);

    const login = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (disableButton) {
            return;
        }
        setDisableButton(true);
        localStorage.setItem('email', email || '');
        console.log('login...');
        try {
            console.log('try: authorizePassword...');
            const result = await AuthService.authorizePassword(email || '', password);
            // Check if we have a valid auth in LocalService
            const auth = LocalService.getAuth();
            if (auth && auth.jwtToken) {
                console.log('if: login successful');
                // Show success message before redirect
                toast.success(i18n().login.success, {
                    autoClose: 2000
                });
                setTimeout(() => {
                        window.location.href = '/';
                }, 250);
            } else {
                console.log('else: invalid credentials');
                toast.error(i18n().login.invalidPassword, {
                    autoClose: 3000
                });
            }
        } catch (error: unknown) {
            console.log('catch...', error);
            // Handle specific error types
            if (error instanceof ApiError) {
                if (error.result?.statusCode === 403) {
                    toast.error(i18n().login.invalidPassword, {
                        autoClose: 3000
                    });
                } else {
                    toast.error(error.result?.message || i18n().error.message, {
                        autoClose: 3000
                    });
                }
            } else if (error instanceof Error) {
                toast.error(error.message, {
                    autoClose: 3000
                });
            } else {
                toast.error(i18n().error.message, {
                    autoClose: 3000
                });
            }
        } finally {
            console.log('finally...');
            setDisableButton(false);
        }
    };

    return (
        <div className={styles.center}>
            <div className={styles.image}/>
            <Card>
                <Form onSubmit={login}>
                    <Item>
                        <div className={styles.jamaheader}>{i18n().login.message}</div>
                    </Item>

                    <Item type={'full'} label="Email (vom Verein registriert)">
                        <Email
                            name="email"
                            placeholder="Email"
                            value={email || ''}
                            onChange={(e) => {
                                console.log("Event in parent component: ", e);
                                setEmail(e);  // Directly update state with new value
                            }}
                        />
                    </Item>
                    <Item type={'full'} label="Password">
                        <div style={{position: 'relative'}}>
                            <Password
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e)}
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                style={{
                                    color: 'white',
                                    position: 'absolute',
                                    right: '0.5rem',
                                    height: '2rem',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    border: 'none',
                                    borderRadius: '0.3rem',
                                    backgroundColor: 'white !important',
                                    background: 'white !important',
                                }}
                                aria-label={showPassword ? 'Hide password' : 'Show password'}
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} style={{height: '1.5rem', width: '1.5rem'}} />
                            </button>
                        </div>
                    </Item>
                    {/*<div className={styles.submitbttn}>*/}
                    <Submit label="Log In" name="login" disabled={disableButton}/>

                    <div className={styles.passwdreset}>
                        <Link
                            to="/password_request"
                            data-tooltip-id="pwd-reset-tooltip"
                            aria-label="Password zurücksetzen - Reset-Link per Email erhalten"
                        >
                            <strong>Zum ERSTLOGIN</strong> oder falls vergessen<br/> ➜Passwort hier zurücksetzen
                        </Link>
                        <Tooltip
                            id="pwd-reset-tooltip"
                            place="top"
                            content="Reset-Link per Email erhalten"
                        />
                    </div>
                </Form>
            </Card>
            <div className={styles.help}>
                <Button
                    className={styles.helplink}
                    width={"full"}
                    label={"🛟 Hilfe"}
                    onClick={() => openHelp('vereine')}
                />
                <div className={styles.version}>Version: {version}</div>
            </div>

            <a
                href="https://alt.judojama.org"
                className={styles.oldjama}
                rel="noopener noreferrer"
                target="_blank"
            >
                <p><strong>altes JudoJama</strong><br/>
                    https://alt.judojama.org<br/>
                    auf gleicher Datenbasis</p>
            </a>
        </div>
    );
};
