import React, {ReactNode} from "react";
import ReactSelect, {components, OptionProps} from "react-select";
import {ThemeConfig} from "react-select/dist/declarations/src/theme";

// Custom Option component with checkbox
const Option = (props: OptionProps<any, true>) => {
    // Get the current option's index
    const index = props.options.indexOf(props.data);
    const isEvenPair = (index + 1) % 2 === 0;

    return (
        <>
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null} // handled by react-select
                    />
                    <label style={{marginLeft: '8px'}}>{props.label}</label>
                </components.Option>
            </div>
            {isEvenPair && index < props.options.length - 1 && (
                <hr style={{
                    margin: '4px 0',
                    border: 'none',
                    borderBottom: '1px solid var(--gray-light)',
                    width: '100%'
                }}/>
            )}
        </>
    );
};

interface ISelectProps {
    onChange?: (v: any[]) => any,
    onInputChange?: (v: string) => any,
    defaultValue?: any[],
    value?: any[],
    options: { label: string, value: any }[],
    render?: SelectRender,
    disabled?: boolean,
    input?: string,
    className?: string,
    theme?: ThemeConfig,
    type?: 'number' | 'text',
    placeholder?: string
}

export type SelectRender = (data: { value: any, label: string }) => ReactNode;

export const SelectMulti: React.FC<ISelectProps> = ({
                                                        onChange,
                                                        onInputChange,
                                                        defaultValue,
                                                        input,
                                                        options,
                                                        render,
                                                        disabled,
                                                        value,
                                                        className,
                                                        theme,
                                                        type,
                                                        placeholder
                                                    }) => {
    const customStyles = {
        control: (baseStyles: any, {isDisabled}: any) => ({
            ...baseStyles,
            width: '100%',
            minHeight: '3rem',
            height: 'auto',
            border: '1px solid var(--gray)',
            backgroundColor: isDisabled ? 'var(--gray-super-light)' : 'var(--primary-color-super-light)',
        }),
        menu: (provided: any) => ({
            ...provided,
            width: 'calc(100vw - 1rem)',
            maxWidth: '30rem',
            margin: '0',
        }),
        multiValue: (provided: any) => ({
            ...provided,
            backgroundColor: 'var(--custom-background-color)',
            color: 'var(--custom-text-color)',
        }),
        multiValueLabel: (provided: any) => ({
            ...provided,
            color: 'white',
            backgroundColor: 'var(--primary-color)',
            fontSize: '1rem',
            padding: '0.25rem 0.25rem',
            minWidth: '4rem',
        }),
        multiValueRemove: (provided: any) => ({
            ...provided,
            color: 'var(--custom-text-color)',
            ':hover': {
                backgroundColor: 'red',
                color: 'white',
            },
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: '8px 12px',
            backgroundColor: state.isSelected ? 'var(--primary-color-super-light)' : 'white',
            color: 'black !important',
            '&:hover': {
                backgroundColor: 'var(--primary-button-color-light)',
                color: 'white !important',
            },
        }),
    };

    return (
        <ReactSelect
            placeholder={placeholder}
            isDisabled={disabled || false}
            options={options}
            defaultValue={defaultValue}
            value={value ? options?.filter(x => type === 'number' ? value.map(y => Number(y)).includes(Number(x.value)) : value.includes(x.value)) : null}
            onChange={(v) => {
                if (onChange) {
                    type === 'number' ? onChange(v.map(x => Number(x.value))) : onChange(v?.map(x => x?.value));
                }
            }}
            onInputChange={onInputChange}
            inputValue={input}
            formatOptionLabel={render}
            styles={customStyles}
            className={className}
            theme={theme}
            isMulti={true}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
                Option
            }}
        />
    );
};