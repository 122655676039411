import React, { useState, useEffect, useMemo } from 'react';
import { Form, Item, Button, Select, Input, Label } from '../../form';
import { InputArea } from '../../form/InputArea';
import { Upload } from '../../form/Upload';
import { SelectMulti } from '../../form/SelectMulti';
import { ItemNavGroup } from '../../form/ItemNavGroup';
import { INotificationCreate } from '../../../api/models/NotificationModel';
import { NotificationService } from '../../../api/notification';
import { MeetingService } from "../../../api/meeting";
import {useParamId, useParamOrg, useParamRole} from '../../useParam';
import { IPageLinkProps } from '../../page.props';
import moment from 'moment';
import styles from './notificationForm.module.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import classNames from "classnames";
import {IFileUpload} from "../../../api/models";
import {toaster} from "../../../core/toaster";
import {UtilService} from "../../../api";
import { MetaService } from '../../../api/meta';
import news from "../../../pages/desktop/news";
import {getOrgLevelFromId, getOrgMotherId} from "../../../util/roles";

export const NotificationForm: React.FC<IPageLinkProps> = ({ user, profile, refresh, setRefresh, gotoUrl }) => {
    const [meetingIds, setmeetingIds] = useState<number[]>([]);
    const [meeting, setMeeting] = useState<any[]>([]); // Add this state for events
    const [roles, setRoles] = useState<string[]>([]);
    const [mediaType, setMediaType] = useState<string>('email');
    const [title, setTitle] = useState('');
    const [pushMessage, setPushMessage] = useState('');
    const [content, setContent] = useState('');
    const role = useParamRole(user);
    const [paType, setPaType] = useState<string[]>(['email', 'push']);
    const notificationTypeOptions = [
        { label: 'Email', value: 'email' },
        { label: 'Push', value: 'push' }
    ];

    const [allOrgsSelected, setAllOrgsSelected] = useState(false);
    const [allClubsAndStatesSelected, setAllClubsAndStatesSelected] = useState(false);
    const currentOrgLevel = getOrgLevelFromId(useParamOrg());

    const [orgIds, setOrgIds] = useState<number[]>(() => {
        if (currentOrgLevel === 3 && role?.orgId) {
            return [role.orgId];
        }
        return [];
    });

    useEffect(() => {
        if (currentOrgLevel === 3 && role?.orgId) {
            setOrgIds(prev => {
                // Only set if not already set
                if (!prev.includes(role.orgId)) {
                    return [role.orgId];
                }
                return prev;
            });
        }
    }, [currentOrgLevel, role?.orgId]);

    //--ReactQuill
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
            ['clean']
        ],
    };
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
    ];
    //--
    const EXCLUDE_GROUP_IDS = [1, 6, 28];

    const currentMotherOrgId = useMemo(() => {
        return role?.orgId ? getOrgMotherId(role.orgId, profile.organizations) : 0;
    }, [role?.orgId, profile.organizations]);

    const orgOptions = useMemo(() => {
        if (!profile.organizations) return [];

        // Find current user's organization to get its level
        const currentOrg = profile.organizations.find(org => org.id === role?.orgId);
        const currentOrgLevel = currentOrg?.orgLevel || 0;
        const currentMotherOrgId = getOrgMotherId(role?.orgId || 0, profile.organizations);

        //console.log('currentOrgLevel/Mother', currentOrgLevel,'/' , currentMotherOrgId);

        // Case 1: orgLevel = 1 (no limits)
        if (currentOrgLevel === 1) {
            return profile.organizations
                .sort((a, b) => {
                    // If both are level 3, sort by name
                    if (a.orgLevel === 3 && b.orgLevel === 3) {
                        return a.nameShort.localeCompare(b.nameShort);
                    }

                    const motherIdA = getOrgMotherId(a.id, profile.organizations);
                    const motherIdB = getOrgMotherId(b.id, profile.organizations);

                    // If both motherIds are <= 10, sort by motherId
                    if (motherIdA <= 10 && motherIdB <= 10) {
                        return motherIdA - motherIdB;
                    }
                    // If both motherIds are > 10, sort by name
                    if (motherIdA > 10 && motherIdB > 10) {
                        return a.nameShort.localeCompare(b.nameShort);
                    }
                    // If one is <= 10 and other is > 10, the <= 10 comes first
                    return motherIdA <= 10 ? -1 : 1;
                })
                .map(org => ({
                    label: org.nameShort,
                    value: org.id
                }));
        }

        // Case 2: orgLevel = 2 (modified)
        if (currentOrgLevel === 2) {
            return profile.organizations
                .filter(org => {
                    const orgMotherId = getOrgMotherId(org.id, profile.organizations);

                    if (currentMotherOrgId === 1) {
                        return (
                            orgMotherId === 1 ||                  // siblings
                            orgMotherId === role?.orgId ||        // children
                            org.id === 1                          // mother org
                        );
                    }

                    return (
                        orgMotherId === currentMotherOrgId ||    // siblings
                        orgMotherId === role?.orgId              // children
                    );
                })
                .sort((a, b) => {
                    // If both are level 3, sort by name
                    if (a.orgLevel === 3 && b.orgLevel === 3) {
                        return a.nameShort.localeCompare(b.nameShort);
                    }

                    const motherIdA = getOrgMotherId(a.id, profile.organizations);
                    const motherIdB = getOrgMotherId(b.id, profile.organizations);

                    // If both motherIds are <= 10, sort by motherId
                    if (motherIdA <= 10 && motherIdB <= 10) {
                        return motherIdA - motherIdB;
                    }
                    // If both motherIds are > 10, sort by name
                    if (motherIdA > 10 && motherIdB > 10) {
                        return a.nameShort.localeCompare(b.nameShort);
                    }
                    // If one is <= 10 and other is > 10, the <= 10 comes first
                    return motherIdA <= 10 ? -1 : 1;
                })
                .map(org => ({
                    label: org.nameShort,
                    value: org.id
                }));
        }

        // Case 3: orgLevel = 3 (default case)
        return profile.organizations
            .filter(org => getOrgMotherId(org.id, profile.organizations) === currentMotherOrgId)
            .sort((a, b) => {
                // For level 3 organizations, always sort by name
                if (a.orgLevel === 3 && b.orgLevel === 3) {
                    return a.nameShort.localeCompare(b.nameShort);
                }

                const motherIdA = getOrgMotherId(a.id, profile.organizations);
                const motherIdB = getOrgMotherId(b.id, profile.organizations);

                // If both motherIds are <= 10, sort by motherId
                if (motherIdA <= 10 && motherIdB <= 10) {
                    return motherIdA - motherIdB;
                }
                // If both motherIds are > 10, sort by name
                if (motherIdA > 10 && motherIdB > 10) {
                    return a.nameShort.localeCompare(b.nameShort);
                }
                // If one is <= 10 and other is > 10, the <= 10 comes first
                return motherIdA <= 10 ? -1 : 1;
            })
            .map(org => ({
                label: org.nameShort,
                value: org.id
            }));
    }, [profile.organizations, role?.orgId, currentMotherOrgId]);

    //console.log('+++profile.organizations',profile.organizations);

    useEffect(() => {
        const fetchEvents = async () => {
            if (role?.orgId) {
                try {
                    const today = moment().format('YYYYMMDD');
                    const threeYearsFromNow = moment().add(36, 'month').format('YYYYMMDD');

                    const meetingData = await MeetingService.all(
                        role.orgId,
                        today,
                        threeYearsFromNow
                    );

                    // Add null check before sorting and mapping
                    if (meetingData && Array.isArray(meetingData)) {
                        // Sort meetings by startdate in ascending order
                        const sortedMeetings = [...meetingData].sort((a, b) =>
                            moment(a.startdate).valueOf() - moment(b.startdate).valueOf()
                        );

                        const meetingOptions = sortedMeetings.map(meeting => {
                            const mainLine = `${meeting.id} | ${meeting.name}`;
                            const dateLine = `${moment(meeting.startdate).format('DD.MM.YYYY')} - ${moment(meeting.enddate).format('DD.MM.YYYY')}`;
                            const registrationLine = `Anmeldung: ${meeting.open_from ? moment(meeting.open_from).format('DD.MM.YYYY') : '--'} - ${meeting.open_to ? moment(meeting.open_to).format('DD.MM.YYYY') : '--'}`;

                            return {
                                label: (
                                    <div>
                                        <div className={styles['notification-form__event-line--main']}>
                                            {mainLine}
                                        </div>
                                        <div className={styles['notification-form__event-line--details']}>
                                            {dateLine} | {registrationLine}
                                        </div>
                                    </div>
                                ),
                                value: meeting.id
                            };
                        });

                        setMeeting(meetingOptions);
                    } else {
                        setMeeting([]); // Set empty array if no data
                    }
                } catch (error) {
                    console.error('Failed to fetch events:', error);
                    setMeeting([]); // Set empty array on error
                }
            }
        };
        fetchEvents();
    }, [role?.orgId]);


    // Meeting roles for SelectMulti
    const roleOptions = useMemo(() => {
        if (!profile.roleGroups) return [];

        return profile.roleGroups
            .filter(group => !EXCLUDE_GROUP_IDS.includes(group.groupId))
            .flatMap((group: any) =>
                (group.roles || []).map((role: any) => ({
                    label: `${group.groupName || 'Unknown Group'} | ${role.name || 'Unknown Role'}`,
                    value: role.name,
                    groupName: group.groupName || 'Unknown Group',
                    roleName: role.name || 'Unknown Role'
                }))
            )
            .sort((a, b) => {
                // First sort by group name
                const groupCompare = a.groupName.localeCompare(b.groupName);
                if (groupCompare !== 0) return groupCompare;
                // Then by role name
                return a.roleName.localeCompare(b.roleName);
            });
    }, [profile.organizations, role?.orgId, currentMotherOrgId]);


    //console.log('++profile.roleGroups',profile.roleGroups)
    // Add new function to handle "select all" checkbox
    useEffect(() => {
        if (orgOptions.length > 0 && orgIds.length > 0) {
            setAllOrgsSelected(orgOptions.length === orgIds.length);
        }
    }, [orgIds, orgOptions]);

    const handleOrgChange = (newValues: number[]) => {
        setOrgIds(newValues);
    };

    const handleSubmit = async () => {
        try {
            const notificationData: INotificationCreate = {
                title,
                content,
                paType: mediaType === 'both' ? ['email', 'push'] : [mediaType],
                paOrganizations: orgIds,
                paEvents: meetingIds.length > 0 ? meetingIds : undefined,
                paRoles: roles.map(role => Number(role)),
                attachedFiles: [],
                lang: 'de',
            };

            const newsId = await NotificationService.create(role.orgId, notificationData);
            if (setRefresh) {
                setRefresh((refresh || 0) + 1);
            }
        } catch (error) {
            console.error('Failed to create notification:', error);
        }
    };

    // Add new function to handle "select all" checkbox
    const handleSelectAllOrgs = (checked: boolean) => {
        if (checked) {
            const allOrgIds = orgOptions.map(org => org.value);
            setOrgIds(allOrgIds);
        } else {
            setOrgIds([]);
        }
        setAllOrgsSelected(checked);
        // Reset the other checkbox when this one is used
        setAllClubsAndStatesSelected(false);
    };

    const handleAllClubsAndStatesChange = (checked: boolean) => {
        if (checked) {
            // Select all organizations with level 2 (states) and 3 (clubs)
            const stateAndClubIds = profile.organizations
                .filter(org => org.orgLevel === 2 || org.orgLevel === 3)
                .map(org => org.id);
            setOrgIds(stateAndClubIds);
        } else {
            setOrgIds([]);
        }
        setAllClubsAndStatesSelected(checked);
        // Reset the other checkbox when this one is used
        setAllOrgsSelected(false);
    };

    return (
        <ItemNavGroup label="Neuen Nachricht erstellen">
            <Form>
                <Item size={12} type="full" label="Organisationen">
                    {currentOrgLevel === 1 ? (
                        <div className={styles.checkboxGroup}>
                            <div className={styles.selectAllContainer}>
                                <label className={styles.selectAllLabel}>
                                    <input
                                        type="checkbox"
                                        checked={allOrgsSelected}
                                        onChange={(e) => handleSelectAllOrgs(e.target.checked)}
                                        className={styles.selectAllCheckbox}
                                        disabled={allClubsAndStatesSelected}
                                    />
                                    Alle Organisationen auswählen
                                </label>
                            </div>
                            <div className={styles.selectAllContainer}>
                                <label className={styles.selectAllLabel}>
                                    <input
                                        type="checkbox"
                                        checked={allClubsAndStatesSelected}
                                        onChange={(e) => handleAllClubsAndStatesChange(e.target.checked)}
                                        className={styles.selectAllCheckbox}
                                        disabled={allOrgsSelected}
                                    />
                                    An alle Vereine und Landesverbände senden
                                </label>
                            </div>
                        </div>
                    ) : currentOrgLevel === 2 ? (
                        <div className={styles.selectAllContainer}>
                            <label className={styles.selectAllLabel}>
                                <input
                                    type="checkbox"
                                    checked={allOrgsSelected}
                                    onChange={(e) => handleSelectAllOrgs(e.target.checked)}
                                    className={styles.selectAllCheckbox}
                                />
                                An alle Landesverbände und alle Vereine deines Landesverbandes.
                            </label>
                        </div>
                    ) :  (
                        <div className={styles.selectAllContainer}>
                            <label className={styles.selectAllLabel}>
                                <input
                                    type="checkbox"
                                    checked={allOrgsSelected}
                                    onChange={(e) => handleSelectAllOrgs(e.target.checked)}
                                    className={styles.selectAllCheckbox}
                                />
                                An alle Vereine deines Landesverbandes senden.
                            </label>
                        </div>
                    )}
                    <SelectMulti
                        options={orgOptions}
                        placeholder={"an Personen dieser Organisation"}
                        value={orgIds}
                        onChange={handleOrgChange}
                        type="number"
                        disabled={currentOrgLevel === 1 ? (allOrgsSelected || allClubsAndStatesSelected) : false}
                    />
                    <Item size={12} type="full" label="Funktionen">
                        <SelectMulti
                            options={roleOptions}
                            value={roles}
                            onChange={setRoles}
                        />
                    </Item>
                    <Item size={12} type="full" label="Ausbildungen">
                        <SelectMulti
                            options={roleOptions}
                            value={roles}
                            onChange={setRoles}
                        />
                    </Item>
                </Item>
                <Item size={12} type="full" label="oder sende an Teilnehmer eines   Events">
                    <Select
                        options={meeting}
                        value={meetingIds}
                        onChange={setmeetingIds}
                        type="number"
                        placeholder="Wähle ein Event aus"
                        freeHeight={true}
                        className={styles.notificationFormSelect}
                    />
                </Item>

                <Item size={12} type="full" label="Event-Rollen (Kampfrichter, Supervisor, genannete Judoka)">
                    <SelectMulti
                        options={roleOptions}
                        value={roles}
                        onChange={setRoles}
                    />
                </Item>

                <Item size={12} type="full" label="Medientyp">
                    <SelectMulti
                        options={notificationTypeOptions}
                        value={paType}
                        onChange={setPaType}
                        type="text"
                        className={styles.select}
                    />
                </Item>

                <Item size={12} type="full" label="Email-Betreff bzw. Push-Messagetitel (am besten < 100 Zeichen)">
                    <Input
                        value={title}
                        onChange={setTitle}
                        className="form-control"
                        maxLength={250}
                        required={true}
                    />
                    <div
                        className={classNames(
                            styles.characterCount,
                            {
                                [styles['characterCount--warning']]: title.length > 100,
                                [styles['characterCount--error']]: title.length > 249
                            }
                        )}
                    >
                        {title.length} / 100 Zeichen (empfohlen:100, max: 250)
                    </div>
                </Item>

                <Item size={12} type="full" label="Zusammenfassung bzw. Inhalt der Push-Nachricht">
                    <InputArea
                        value={pushMessage}
                        onChange={setPushMessage}
                        className="form-control"
                        rows={3}
                        required={true}
                        maxLength={1020}
                    />
                    <div
                        className={classNames(
                            styles.characterCount,
                            {
                                [styles['characterCount--green']]: pushMessage.length < 61,
                                [styles['characterCount--warning']]: pushMessage.length > 180,
                                [styles['characterCount--error']]: pushMessage.length > 1020
                            }
                        )}
                    >
                        {pushMessage.length} / 180 Zeichen (empfohlen: 50-60, {`<`} 180 Zeichen für direkte Sichtbarkeit, max: 1020)
                    </div>
                </Item>

                <Item size={12} type="full" label="Inhalt (nur Email), wird bei Auswahl der Pushnachricht in der App    geöffnet">
                    <ReactQuill
                        theme="snow"
                        value={content}
                        onChange={setContent}
                        modules={modules}
                        formats={formats}
                        className={styles.editor}
                    />
                </Item>

                <Button
                    width="full"
                    label="Nachricht erstellen"
                    onClick={handleSubmit}
                />
            </Form>
        </ItemNavGroup>
    );
};