// src/components/admin/licences/licence_statistic.tsx
import React, { useState } from 'react';
import { IUserModel, ILicenceOrder } from "../../../api/models";
import { ItemNavGroup } from "../../form/ItemNavGroup";
import { FD } from "../../form";
import styles from "./licence.module.scss";

interface IStatisticsProps {
    user: IUserModel;
    filteredOrders?: ILicenceOrder[];
    dateFrom?: Date | null;
    dateTo?: Date | null;
}

// Component for statistics tab - moved from licence_detail.tsx
const StatisticsComponent: React.FC<IStatisticsProps> = ({
                                                             user,
                                                             filteredOrders = [],
                                                             dateFrom,
                                                             dateTo
                                                         }) => {
    // State for sorting and filtering
    const [sortField, setSortField] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [searchBestellNr, setSearchBestellNr] = useState<string>('');
    const [searchJahr, setSearchJahr] = useState<string>('');
    const [searchAnfrage, setSearchAnfrage] = useState<string>('');
    const [searchFreigabe, setSearchFreigabe] = useState<string>('');
    const [searchLV, setSearchLV] = useState<string>('');
    const [searchVereinId, setSearchVereinId] = useState<string>('');
    const [searchVerein, setSearchVerein] = useState<string>('');

    const handleSort = (field: string) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const SortableHeader: React.FC<{ field: string; label: string }> = ({ field, label }) => (
        <th
            onClick={() => handleSort(field)}
            className={styles.sortableHeader}
            style={{ cursor: 'pointer' }}
        >
            {label}
            {sortField === field && (
                <span className={styles.sortIcon}>
                    {sortDirection === 'asc' ? ' ↑' : ' ↓'}
                </span>
            )}
        </th>
    );

    // Group orders by mother (Verein)
    const groupedOrders = filteredOrders?.reduce<{ [key: string]: ILicenceOrder[] }>((groups, order) => {
        if (order.completed === null) {
            return groups;
        }

        const key = (!order.mother || Number(order.mother) < 2) ?
            (order.orgId?.toString() || 'Unbekannt') :
            order.mother?.toString() || 'Unbekannt';

        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(order);
        return groups;
    }, {});

    // Flatten and sort the orders
    const allOrders = Object.values(groupedOrders || {})
        .flat()
        .filter(order => {
            return (
                (!searchBestellNr || order.id.toString().includes(searchBestellNr)) &&
                (!searchJahr || order.years?.[0]?.toString().includes(searchJahr)) &&
                (!searchAnfrage || FD(order.created).includes(searchAnfrage)) &&
                (!searchFreigabe || FD(order.completed).includes(searchFreigabe)) &&
                (!searchLV || order.orgName?.toLowerCase().includes(searchLV.toLowerCase())) &&
                (!searchVerein || order.mother?.toLowerCase().includes(searchVerein.toLowerCase())) &&
                (!searchVereinId || (order.orgId?.toString()).includes(searchVereinId))
            );
        })
        .sort((a, b) => {
            if (!sortField) return 0;

            let compareA: any = a[sortField as keyof ILicenceOrder];
            let compareB: any = b[sortField as keyof ILicenceOrder];

            if (sortField === 'year') {
                compareA = a.years?.[0];
                compareB = b.years?.[0];
            }

            if (compareA === compareB) return 0;
            if (compareA == null) return 1;
            if (compareB == null) return -1;

            const result = compareA < compareB ? -1 : 1;
            return sortDirection === 'asc' ? result : -result;
        });

    // Calculate total items
    const totalItems = allOrders.reduce((sum, order) => sum + (order.items || 0), 0);

    return (
        <ItemNavGroup label={`Statistik ${dateFrom ? FD(dateFrom) : '---'} bis ${dateTo ? FD(dateTo) : '---'}`}>
            <table className={styles.excelTable}>
                <thead>
                <tr>
                    <SortableHeader field="id" label="Best. #" />
                    <SortableHeader field="items" label="Anzahl" />
                    <SortableHeader field="year" label="Jahr" />
                    <SortableHeader field="created" label="Anfrage" />
                    <SortableHeader field="completed" label="Freigabe" />
                    <SortableHeader field="orgId" label="Org.ID" />
                    <SortableHeader field="mother" label="Verein" />
                    <SortableHeader field="orgName" label="LV" />
                </tr>
                <tr className={styles.filterRow}>
                    <td>
                        <input
                            type="text"
                            value={searchBestellNr}
                            onChange={(e) => setSearchBestellNr(e.target.value)}
                            placeholder="Suche..."
                            className={styles.searchInput}
                        />
                    </td>
                    <td>-- </td>
                    <td>
                        <input
                            type="text"
                            value={searchJahr}
                            onChange={(e) => setSearchJahr(e.target.value)}
                            placeholder="Suche..."
                            className={styles.searchInput}
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            value={searchAnfrage}
                            onChange={(e) => setSearchAnfrage(e.target.value)}
                            placeholder="Suche..."
                            className={styles.searchInput}
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            value={searchFreigabe}
                            onChange={(e) => setSearchFreigabe(e.target.value)}
                            placeholder="Suche..."
                            className={styles.searchInput}
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            value={searchVereinId}
                            onChange={(e) => setSearchVereinId(e.target.value)}
                            placeholder="Suche..."
                            className={styles.searchInput}
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            value={searchLV}
                            onChange={(e) => setSearchLV(e.target.value)}
                            placeholder="Suche..."
                            className={styles.searchInput}
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            value={searchVerein}
                            onChange={(e) => setSearchVerein(e.target.value)}
                            placeholder="Suche..."
                            className={styles.searchInput}
                        />
                    </td>
                </tr>
                </thead>
                <tbody>
                {allOrders.map((order: ILicenceOrder) => (
                    <tr key={order.id}>
                        <td>{order.id}</td>
                        <td>{order.items}</td>
                        <td>{order.years?.[0]}</td>
                        <td>{FD(order.created)}</td>
                        <td>{FD(order.completed)}</td>
                        <td>{order.orgId}</td>
                        <td>{order.orgName}</td>
                        <td>{order.mother}</td>
                    </tr>
                ))}
                {/* Summary row */}
                <tr className={styles.summaryRow}>
                    <td><strong>Summe</strong></td>
                    <td><strong>{totalItems}</strong></td>
                    <td colSpan={6}></td>
                </tr>
                </tbody>
            </table>
        </ItemNavGroup>
    );
};

export default StatisticsComponent;