import React, {useEffect, useMemo, useState, useCallback} from 'react';
import {IPageLinkProps} from "../../page.props";
import {EKyuExamStatus, IKyuCandidate, IKyuExam, IBeltColor} from "../../../api/models";
import {CacheService, Constants, IMAGE_URL, KyuService} from "../../../api";
import {toaster, toastError} from "../../../core/toaster";
import {ApiError} from "../../../api/http";
import {Loading} from "../../foundation/Loading";
import {ItemNav} from "../../form/ItemNav";
import {faArrowUp, faCertificate, faCircleCheck, faEdit, faFileArrowDown} from "@fortawesome/free-solid-svg-icons";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import PullToRefresh from "react-simple-pull-to-refresh";
import {BackButton, Button, DateInput, DS, FD, Form, Input, Item, Segment} from "../../form";
import classNames from "classnames";
import styles from "./kyu_detail_candidates.module.scss";
import {useParamId, useParamRole} from "../../useParam";
import {AuthenticatedLink} from "../../foundation/authenticated_link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AvatarImage from "../../foundation/AvatarImage";
import {addMonths} from "date-fns";
import SelectKyuBordMembers from "../../tournaments/SelectKyuChairMembers";
import {useNavigate, useLocation, Link} from "react-router-dom";
import printStyles from '../../../theme/print.module.scss';
import {LicenceService} from "../../../api/licence";
import {GroupAdminRoles} from "../../../util/roles";
import { BeltColorStripes } from '../../foundation/BeltColorStripes';

const HOST = Constants.JAMA_SERVER_API_V2

interface KyuDetailCandidatesProps extends IPageLinkProps {
    refresh?: number;
    setRefresh?: React.Dispatch<React.SetStateAction<number>>;
    triggerRefresh: () => void;
    candidateToggled: boolean;
    setCandidateToggled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const KyuDetailCandidates: React.FC<KyuDetailCandidatesProps> = ({
                                                                            user,
                                                                            profile,
                                                                            gotoUrl,
                                                                            refresh,
                                                                            setRefresh,
                                                                            triggerRefresh,
                                                                            candidateToggled,
                                                                            setCandidateToggled
                                                                        }) => {
    const role = useParamRole(user);
    const licencesUrl = `/${role?.orgId}/${role?.funkMgId}/licences/`;
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);

    const [candidates, setCandidates] = useState<IKyuCandidate[]>([]);
    const [exam, setExam] = useState<IKyuExam | null>(null);
    const [search, setSearch] = useState<string>('');
    const [filterState, setFilterState] = useState<number>(3); //default Filterauswahl
    const location = useLocation();
    const examId = useParamId();
    const [date, setDate] = useState<Date | null>(new Date(Date.now()));
    const [toggleLoading, setToggleLoading] = useState<number | null>(null);
    const [selectedCount, setSelectedCount] = useState<number>(0);
    const [lastToggledCandidateId, setLastToggledCandidateId] = useState<number | null>(null);
    const refreshCandidates = async (force: boolean, fullRefresh: boolean = false) => {
        if (role != null) {
            console.log('Starting refreshCandidates, force:', force, 'fullRefresh:', fullRefresh);
            setRefreshing(true);

            if (force) await CacheService.clear();

            try {
                if (fullRefresh) {
                    console.log('Fetching candidates');
                    setCandidates([]); // Clear candidates when doing a full refresh
                    const candidates = await KyuService.candidates(role?.orgId, examId || 0);
                    console.log('Fetched candidates:', candidates);
                    setCandidates(candidates);
                } else {
                    console.log('Skipping candidate fetch, using existing data');
                }

                setRefresh && setRefresh((prev) => prev + 1);
            } catch (e) {
                console.error('Error in refreshCandidates:', e);
                toastError((e as ApiError)?.result?.error)
            } finally {
                console.log('Finishing refreshCandidates');
                setRefreshing(false);
            }
        }
    };

    const [lastToggleTime, setLastToggleTime] = useState<number | null>(null);
    const toggleCandidate = async (candidate: IKyuCandidate) => {
        console.log("toggleCandidate called for:", candidate.mgId);
        setToggleLoading(candidate.mgId);
        try {
            setCandidates(prevCandidates => {
                const newCandidates = prevCandidates.map(c =>
                    c.mgId === candidate.mgId
                        ? {...c, assignedMgId: c.assignedMgId ? undefined : candidate.mgId}
                        : c
                );
                const newCount = newCandidates.reduce((acc, c) => c.assignedMgId ? acc + 1 : acc, 0);
                setSelectedCount(newCount);
                return newCandidates;
            });
            setLastToggledCandidateId(candidate.mgId);

            console.log("+++Candidate toggled - setLastToggledCandidateId", candidate);
            setCandidateToggled(prev => !prev);  // Toggle this when a candidate is toggled
            const saveResult = await saveCandidates();
            console.log("Save completed after toggle, result:", saveResult);

            if (!saveResult) {
                // If save failed, revert the change
                setCandidates(prevCandidates => prevCandidates.map(c =>
                    c.mgId === candidate.mgId
                        ? {...c, assignedMgId: c.assignedMgId ? undefined : candidate.mgId}
                        : c
                ));
                toastError('Failed to save changes. Please try again.');
            }
        } catch (error) {
            console.error("Error in toggleCandidate:", error);
            toastError('Error toggling candidate. Please try again.');
        } finally {
            setToggleLoading(null);
        }
    };

    const filter = useMemo(() => {
        if (window.matchMedia('print').matches) {
            return candidates; // Return all candidates when printing
        }
        return candidates.filter(x => {
            const matchesSearch =
                x.firstname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                x.surname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                `${x.mgId}`.includes(search);

            let passesFilter = true;
            if (filterState === 1) {
                passesFilter = x.assignedMgId != null;
            } else if (filterState === 2) {
                passesFilter = x.assignedMgId == null && (x.outError?.length || 0) === 0;
            } else if (filterState === 3) {
                passesFilter = (x.outError?.length || 0) === 0;
            }

            //console.log(`Candidate ${x.mgId}: matchesSearch=${matchesSearch}, passesFilter=${passesFilter}`);
            return matchesSearch && passesFilter;
        });
    }, [candidates, filterState, search]);

    const validateDetele = () => {
        return exam?.status === EKyuExamStatus.created || exam?.status == undefined ? false : true;
    }

    const navigate = useNavigate();
    const handleEditClick = () => {
        navigate(`${gotoUrl}/${examId}?t=edit`);
    };
    const saveCandidates = async (): Promise<boolean> => {
        try {
            setLoading(true);
            setRefreshing(true);

            const updates = candidates.map(c => c.assignedMgId);
            const updatesFiltered = updates.filter(item => item !== null);

            const filteredCandidates = updatesFiltered.filter(id => id !== undefined);

            // Filter candidates to get only those with assignedMgId and map to get their mgId
            console.log("##Sending updates for candidates:##", filteredCandidates);
            const success = await KyuService.batchUpdateCandidates(role?.orgId, exam?.id || 0, filteredCandidates);
            if (!success) {
                throw new Error("Failed to update candidates");
            }

            await refreshCandidates(true, false);
            setRefresh && setRefresh((prevRefresh) => prevRefresh + 1);
            triggerRefresh();

            return true;
        } catch (error) {
            console.error("Error in saveCandidates function:", error);
            toastError('Error saving candidates. Please try again.');
            return false;
        } finally {
            setLoading(false);
            setRefreshing(false);
        }
    };

    const execute = async (event: React.MouseEvent<HTMLButtonElement>): Promise<boolean> => {
        if (!validateIfItems()) {
            //console.log("Validation failed");
            return false;
        }
        setLoading(true);
        try {
            // First, send all candidate updates
            const updates = candidates.map(c => c.assignedMgId);
            const updatesFiltered = (updates.filter(item => item !== null)).filter(Boolean);
            //console.log("Sending updates for candidates:", updatesFiltered);
            const success = await KyuService.batchUpdateCandidates(role?.orgId, exam?.id || 0, updatesFiltered);

            if (!success) {
                throw new Error("Failed to update candidates");
            }

            // Then, upgrade the exam
            //console.log("Upgrading exam");
            await KyuService.upgrade(role?.orgId, exam?.id || 0);

            // Fetch updated exam data
            //console.log("Fetching updated exam data");
            const updatedExam = await KyuService.get(role?.orgId || 0, exam?.id || 0);
            if (updatedExam) {
                setExam(updatedExam);
                //console.log('Updated exam after upgrade:', updatedExam);
            }

            // Refresh candidates with full fetch after exam upgrade
            //console.log("Refreshing candidates");
            await refreshCandidates(true, true);

            //console.log("Calling triggerRefresh");
            triggerRefresh();

            // Update refresh state
            setRefresh && setRefresh((prevRefresh) => {
                const newRefresh = (prevRefresh || 0) + 1;
                //console.log('New refresh value:', newRefresh);
                return newRefresh;
            });

            toaster(async () => {
                return Promise.resolve();
            }, {
                success: 'Kyu Prüfung erfolgreich gespeichert',
                failure: 'Fehler beim Speichern der Prüfung!'
            });

            setTimeout(() => {
                navigate(`${gotoUrl}/${exam?.id}?t=candidates`);
                window.location.reload();
            }, 0);

            return true;
        } catch (error) {
            //console.error("Error in execute function:", error);
            toastError('Fehler beim Speichern der Prüfung!');
            return false;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        //console.log('KyuDetailCandidates received new refresh value:', refresh);
    }, [refresh]);

    const deleteExam = async (data: React.MouseEvent<HTMLButtonElement>): Promise<boolean> => {
        const gotoAfterDelete = `${gotoUrl}/0?t=delete`
        console.log("gotoUrl--candidated--: ", gotoAfterDelete);
        if (exam?.status === EKyuExamStatus.created || exam?.status == undefined) {
            toaster(async () => {
                try {
                    await KyuService.delete(role?.orgId, exam?.id || 0);
                    console.log("Kyu deleted successful");
                    await refreshCandidates(true, false); // Don't fetch candidates again

                    setRefresh && setRefresh((refresh || 0) + 1);
                    navigate(`${gotoUrl}/0?t=delete`);
                } catch (error) {
                    console.error("Error in toaster function:", error);
                }
            }, {
                success: 'Kyu Prüfung #' + exam?.id + ' gelöscht',
                failure: 'Fehler beim Löschen der Prüfung #' + exam?.id + ' !'
            });
        } else {
            //console.log("Validation failed");
        }
        return false;
    };


    // const approve = (data: React.MouseEvent<HTMLButtonElement>): boolean => {
    //     if (validateApprove()) {
    //         toaster(async () => {
    //             await KyuService.approve(role?.orgId || 0, exam?.id || 0);
    //             // Fetch updated exam data
    //             const updatedExam = await KyuService.get(role?.orgId || 0, exam?.id || 0);
    //             if (updatedExam) {
    //                 setExam(updatedExam);
    //                 //console.log('Updated exam after upgrade:', updatedExam);
    //             }
    //
    //             // Refresh candidates
    //             await refreshCandidates(true);
    //             setRefresh && setRefresh((refresh || 0) + 1);
    //             navigate(`${gotoUrl}/${exam?.id}?t=candidates`)
    //             return
    //         }, {success: 'Kyu Prüfung erfolgreich durchgeführt', failure: 'Fehler!'})
    //     }
    //     return false
    // }

    const validateApprove = () => {
        return date != null && exam?.status === EKyuExamStatus.completed;
    }

    const validateIfItems = () => {
        return exam?.items && exam.items > 0 ? true : false;
    };
    //--

    useEffect(() => {
        setLoading(true);
        setCandidates([]); // Clear candidates when examId changes
        //console.log('fetchedCandidates:1');
        const fetchCandidates = async () => {
            try {
                const fetchedCandidates = await KyuService.candidates(role?.orgId, examId || 0);
                setCandidates(fetchedCandidates);
            } catch (error) {
                console.error('Error fetching candidates:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCandidates();
    }, [role?.orgId, examId]);

    useEffect(() => {
        if (role?.orgId) {
            //console.log('get:3');
            KyuService.get(role.orgId, examId || 0).then(fetchedExam => {
                if (fetchedExam) {
                    setExam(fetchedExam);
                    // console.log("Before updating exam items");
                    // console.log('Updated exam items:', fetchedExam.items);
                    // console.log("After updating exam items");
                }
            });
        }
    }, [role, examId, refresh]);

    useEffect(() => {
        const count = candidates.reduce((acc, c) => c.assignedMgId ? acc + 1 : acc, 0);
        setSelectedCount(count);

    }, [candidates]);

    useEffect(() => {
        //console.log('Filtered candidates:', filter);
    }, [filter]);

    useEffect(() => {
        validateIfItems();
    }, [exam?.items]);

    useEffect(() => {
        //console.log('lastToggleTime effect triggered', lastToggleTime);
        if (lastToggleTime !== null) {
            //console.log("Setting up save timer");
            const timer = setTimeout(() => {
                //console.log("Save timer triggered, calling saveCandidates");
                saveCandidates();
            }, 700);

            return () => {
                //console.log("Clearing save timer");
                clearTimeout(timer);
            };
        }
    }, [lastToggleTime]);

    useEffect(() => {
        if (candidateToggled) {
            saveCandidates().then(() => {
                setCandidateToggled(false);
                triggerRefresh();  // Call triggerRefresh after saving candidates
            });
        }
    }, [candidateToggled, setCandidateToggled, triggerRefresh]);

    const handleFilterChange = (newFilterState: number) => {
        setFilterState(newFilterState);
        setSearch(''); // Reset search to an empty string
    };

    const adminRoles = useMemo(() => GroupAdminRoles(user), [user]);

    const goToLicence = (ordersystemId: number): string => {
        interface AdminRole {
            form: string;
            orgId: number;
            orgName: string;
            funkMgId: number;
            orgLevel: number;
            functionName: string;
        }

        // First find the matching organization key
        const matchingOrgKey = Object.keys(adminRoles).find(key =>
            adminRoles[key].some(r =>
                r.form === 'licences' && r.orgId === role?.orgId
            )
        );

        // Then find the LICENCE role in that organization's roles
        const licenceRole = matchingOrgKey ?
            adminRoles[matchingOrgKey].find(r =>
                r.form === 'licences' && r.orgId === role?.orgId
            )
            : null;

        if (licenceRole) {
            return `/${role?.orgId}/${licenceRole.funkMgId}/licences/${ordersystemId}?t=candidates`;
        } else {
            return '#';
            console.error('No Licence role found for this user');
        }
    }


    const approve = async () => {
        try {
            // Show loading state if needed
            setLoading(true);

            await toaster(async () => {
                // Perform the approval
                await KyuService.approve(role.orgId, examId || 0);

                // Update exam data
                const updatedExam = await KyuService.get(role.orgId, examId || 0);
                if (updatedExam) {
                    setExam(updatedExam);
                }

                // Ensure refreshes are triggered
                await refreshCandidates(true, true);

                // Update refresh counter
                setRefresh && setRefresh((prev) => prev + 1);

                // Trigger parent refresh
                triggerRefresh();

                // Force a refresh of the main component
                await KyuService.exams(role.orgId);

                // Return promise to ensure toaster waits
                return Promise.resolve();
            }, {
                success: "KYU-Prüfung freigegeben",
                failure: "Fehler beim Freigeben"
            });

            // After successful approval and refresh, navigate
            //window.location.href = `${gotoUrl}/`;
            navigate(`${gotoUrl}/${examId}?t=candidates`)
        } catch (error) {
            console.error("Error in approve function:", error);
            toastError('Fehler beim Freigeben der Prüfung');
        } finally {
            setLoading(false);
        }
    };

    const [ showScrollButton, setShowScrollButton] = useState(false);

// Add this useEffect in your component
    useEffect(() => {
        const handleScroll = () => {
            // Show button when scrolled down 400px
            const shouldShow = window.scrollY > 100;
            setShowScrollButton(shouldShow);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

// Add this function in your component
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    //--

    return <>
        {(loading || refreshing) && <Loading/>}

        <div className={printStyles['hide-for-print']}>
            <BackButton label={'Prüfung verlassen'} navigateTo={gotoUrl}/>
        </div>
        <ItemNavGroup label={`Prüfung ${exam?.id} / ${exam?.status} `} className={styles.overview}>
            <ul className={styles.examList}>
                <li className={styles.examItem}>
                    <div className={styles.examLabel}>Datum:</div>
                    <div className={styles.examValue}>{FD(exam?.date)}</div>
                </li>
                {exam?.name && (
                    <li className={styles.examItem}>
                        <div className={styles.examLabel}>Name</div>
                        <div className={styles.examValue}>{exam?.name}</div>
                    </li>
                )}
                <li className={styles.examItem}>
                    <div className={styles.examLabel}>Verein:</div>
                    <div className={styles.examValue}>{exam?.org}</div>
                </li>
                <li className={styles.examItem}>
                    <div className={styles.examLabel}>Vorsitz:</div>
                    <div className={styles.examValue}>{exam?.examChairMain}</div>
                </li>
                {(exam?.examChair1.trim() && exam.examChair1.length > 0) && (
                    <li className={styles.examItem}>
                        <div className={styles.examLabel}>1.Beisitz:</div>
                        <div className={styles.examValue}>{exam?.examChair1}</div>
                    </li>
                )}
                {(exam?.examChair2.trim() && exam.examChair2.length > 0) && (
                    <li className={styles.examItem}>
                        <div className={styles.examLabel}>2.Beisitz:</div>
                        <div className={styles.examValue}>{exam?.examChair2}</div>
                    </li>
                )}

                <li className={styles.examItem}>
                    <div className={styles.examLabel}>Zuletzt bearbeitet von:</div>
                    <div className={styles.examValue}>{exam?.editor}</div>
                </li>
            </ul>
        </ItemNavGroup>

        <ItemNavGroup label="Statistik - Gürtelgrade" >
            {candidates.reduce((acc: { [key: string]: number }, candidate) => {
                // Only include candidates based on status and filterState
                if (exam?.status === 'ERSTELLT' && !(filterState === 1 || filterState === 3)) {
                    return acc;
                }

                // Choose belt based on status
                const belt = exam?.status === 'ERSTELLT'
                    ? (candidate.nextName || 'Unbekannt')
                    : (candidate.trainingBelt || 'Unbekannt');

                // For ERSTELLT status, count ONLY assigned candidates
                if (exam?.status === 'ERSTELLT' && !candidate.assignedMgId) {
                    return acc;
                }

                acc[belt] = (acc[belt] || 0) + 1;
                return acc;
            }, {}) && (
                <div className={styles.statisticsContainer}>
                    <div className={styles.statisticsGrid}>
                        {Object.entries(candidates.reduce((acc: { [key: string]: number }, candidate) => {
                            if (exam?.status === 'ERSTELLT' && !(filterState === 1 || filterState === 3)) {
                                return acc;
                            }

                            // Choose belt based on status
                            const belt = exam?.status === 'ERSTELLT'
                                ? (candidate.nextName || 'Unbekannt')
                                : (candidate.trainingBelt || 'Unbekannt');

                            if (exam?.status === 'ERSTELLT' && !candidate.assignedMgId) {
                                return acc;
                            }

                            acc[belt] = (acc[belt] || 0) + 1;
                            return acc;
                        }, {}))
                            .sort((a, b) => {
                                // Update sorting to use nextSort for ERSTELLT status
                                const aCandidate = candidates.find(c =>
                                    exam?.status === 'ERSTELLT'
                                        ? c.nextName === a[0]
                                        : c.trainingBelt === a[0]
                                );
                                const bCandidate = candidates.find(c =>
                                    exam?.status === 'ERSTELLT'
                                        ? c.nextName === b[0]
                                        : c.trainingBelt === b[0]
                                );
                                return (aCandidate?.trainingSort || 0) - (bCandidate?.trainingSort || 0);
                            })
                                    .map(([belt, count]) => (
                                <Item key={belt} type="split" className={styles.statisticsItem}>
                                    <div>
                                        {belt !== undefined && user.sysconfig.BELTCOLORS
                                            ? <BeltColorStripes
                                                colors={((user.sysconfig.BELTCOLORS[belt.replace(/\//g, '_') as keyof typeof user.sysconfig.BELTCOLORS] as unknown) as IBeltColor)?.colors || []}
                                            />
                                            : 'NA'}
                                    </div>
                                    <div className={styles.beltStatistic}>
                                        <strong>{belt}</strong>
                                        {exam?.status !== 'ERSTELLT' && candidates.find(c => c.trainingBelt === belt)?.trainingGrade && (
                                            <div className={styles.beltGrade}>
                                                {candidates.find(c => c.trainingBelt === belt)?.trainingGrade}
                                            </div>
                                        )}
                                        <div className={styles.beltCount}>
                                            {count} Teilnehmer
                                        </div>

                                    </div>
                                </Item>
                            ))}
                    </div>
                    <Item type="split" className={styles.statisticsTotal}>
                        <strong>Gesamt: {
                            candidates
                                .filter(c => {
                                    if (exam?.status === 'ERSTELLT' && !(filterState === 1 || filterState === 3)) {
                                        return false;
                                    }
                                    if (exam?.status === 'ERSTELLT' && !c.assignedMgId) {
                                        return false;
                                    }
                                    return true;
                                })
                                .length
                        } Teilnehmer</strong>
                    </Item>
                </div>
            )}
        </ItemNavGroup>
        {exam?.status === EKyuExamStatus.created && exam?.orgId == role.orgId && (
            <Button
                className={styles.executeButton}
                width={'full'}
                label="Datum, Vorsitz und Beisitz bearbeiten"
                onClick={handleEditClick}
            />
        )}
        {(exam?.status === EKyuExamStatus.completed && role.orgLevel < 3) ?
            <Button
                className={styles.executeButton}
                width={'full'}
                label={`Prüfung Nr. ${examId}: Freigeben`}
                //icon={faCircleCheck}
                //href={`${gotoUrl}/${examId}?t=approve`}
                onClick={() => approve()}
                disabled={loading}
            /> : ''
        }

        {exam?.status === EKyuExamStatus.created && exam?.orgId == role.orgId && (
            <Button
                className={styles.executeButton}
                width={'full'}
                label="Prüfung abschließen, Grade eintragen, Urkunden erstellen & JudoCards bestellen"
                onClick={execute}
                disabled={!validateIfItems()}
            />
        )}

        {(exam?.status === EKyuExamStatus.created || exam?.status == 'ABGESAGT') && exam?.orgId == role.orgId && (
            <Button
                className={styles.deleteButton}
                width={'full'}
                label="Prüfung unwiderruflich löschen"
                onClick={deleteExam}
                disabled={validateDetele()}
            />)}

        {exam?.status === EKyuExamStatus.submitted && (exam?.orgId != role.orgId || role?.orgLevel < 3) && (
            <Button
                className={styles.approveButton}
                width={'full'}
                label="Freigeben."
                onClick={approve}
                disabled={!validateApprove()}
            />
        )}

        {/*<LoadingTest />*/}


        <ItemNavGroup className={printStyles['hide-for-print']}>
            <div className={styles.candidatesContainer}>
                {/*{!window.matchMedia('print').matches ? (*/}
                {/*    <PullToRefresh onRefresh={() => refreshCandidates(false, false)} onFetchMore={async () => {*/}
                {/*        console.log('more')*/}
                {/*    }}>*/}
                        <>
                            {(loading || refreshing) && (
                                <div className={styles.loadingOverlay}>
                                    <Loading/>
                                    <div>Daten werden geladen...</div>
                                </div>
                            )}
                            <Item type="full">
                                <Input onChange={setSearch}
                                       className={styles.name} value={search} placeholder={'Suche'}/>
                            </Item>
                            {exam?.status === EKyuExamStatus.created  &&
                            <Item type="full">
                                <div className={printStyles['hide-for-print']}>
                                    <div className={styles.filterheader}>Filter</div>
                                    <Segment
                                        className={styles.filter}
                                        value={filterState}
                                        onChange={handleFilterChange}
                                        options={[
                                            {label: 'Teilnehmerauswahl ', value: 3},
                                            {label: 'Anmeldeübersicht', value: 1},
                                            {label: 'Alle Judoka', value: 0},
                                            //{ label: 'Filter: Nur zulässige', value: 2 },
                                        ]}
                                    />
                                </div>
                            </Item>
                            }
                        {exam?.status !== EKyuExamStatus.created ?  (filter.length > 0 &&
                            <AuthenticatedLink
                                className={classNames(styles.all, styles.kyuUrkunden)}
                                url={`${HOST}/admin/${role.orgId}/report/kyu/${examId}`}
                                filename={"Kyu-Urkunden.pdf"}
                            >Alle Urkunden <FontAwesomeIcon icon={faFileArrowDown}/> nach Gürtel
                                sortiert</AuthenticatedLink>) : (filter.length > 0 &&
                            <div className={styles.hint}>HINWEIS: Urkunden wurden vom Verein noch nicht erstellt!</div>)}

                        {/*{console.log('filter:', filter)}*/}
                        {/*{console.log('filterState:', filterState)}*/}
                            <div className={styles.candidatesContainer}>
                                {loading && <div className={styles.loadingOverlay}><Loading/></div>}

                                {/*{filter.length > 0 ? filter.map((c, index) => (*/}
                                {/*    <React.Fragment key={`candidate-${c.mgId}`}>*/}

                                {filter.length > 0 ? (
                                    <div className={printStyles['print-content']}>
                                        {filter.map((c, index) => (
                                            <React.Fragment key={`candidate-${c.mgId}`}>
                                                <Item
                                                    type="full"
                                                    key={`candidate-${c.mgId}`}
                                                    onClick={exam?.status === EKyuExamStatus.created && (filterState == 1 || ((c.outError?.length || 0) === 0)) ? () => toggleCandidate(c) : undefined}
                                                    className={printStyles['candidate-item']}
                                                >
                                                    <div className={classNames(
                                                        styles.candidate,
                                                        printStyles['candidate'],
                                                        c.assignedMgId != null ? styles.active : styles.inactive,
                                                        c.judocard == null && styles.nojudocard,
                                                        c.outError?.length && styles.regelverletzung
                                                    )}>
                                                        {toggleLoading === c.mgId && <Loading/>}
                                                        <div className={styles.candidateContainer}>
                                                            <div className={styles.mainContent}>
                                                                <div className={styles.card}>
                                                                    <div className={styles.imageContainer}>
                                                                        <AvatarImage
                                                                            className={styles.image}
                                                                            src={IMAGE_URL + c?.uuid + `?v=${(Date.now() / 1000000).toFixed(0)}`}
                                                                        />
                                                                    </div>
                                                                    <div className={styles.rightSide}>
                                                                        <div className={styles.header}>
                                                                            <strong>{c.surname} {c.firstname} {c.assignedMgId && ' \u2705'}</strong>
                                                                            <div>{FD(c.birthdate)} / {c.mgId} / {exam?.date && c.birthdate ?
                                                                                new Date(exam.date).getFullYear() - new Date(c.birthdate).getFullYear() : '?'} J</div>
                                                                            {(exam?.status === EKyuExamStatus.completed || exam?.status === EKyuExamStatus.approved || exam?.status == undefined) && c.assignedMgId ?
                                                                                <AuthenticatedLink
                                                                                    url={`${HOST}/admin/${role.orgId}/report/kyu/${examId}/${c.mgId}`}
                                                                                    filename={`KyuCertificate_${c.surname}_${c.firstname}_${exam?.date}.pdf`}
                                                                                    className={classNames(styles.kyuUrkunden)}
                                                                                >Urkunde&nbsp;<FontAwesomeIcon icon={faFileArrowDown}/>
                                                                                </AuthenticatedLink>
                                                                                : ''}
                                                                        </div>
                                                                        <div className={styles.lastexam}>
                                                                            <div>Letzte Prüfung: {FD(c.lastKyuDate)}</div>
                                                                            {c.judocard ? (
                                                                                !c.ordersystemId ? (
                                                                                    <div className={classNames(styles.licence)}>
                                                                                        JUDOCARD: <span className={styles.valid}>{c.judocard}</span>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div>
                                                                                        <div className={classNames(styles.licence)}>
                                                                                            JUDOCARD: <span className={styles.valid}>{c.judocard}</span>
                                                                                            <span className={styles.missing}>autom. bestellt</span>
                                                                                        </div>
                                                                                        <div className={styles.licenceLinkBg}>
                                                                                            <Link className={styles.licenceLink} to={goToLicence(c.ordersystemId)}>
                                                                                                {`öffne Bestellung # ${c.ordersystemId}`}
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            ) : (
                                                                                <div>
                                                                                    <div className={classNames(styles.licence)}>
                                                                                        JudoCard: <span className={styles.missing}>
                                                                                            {c.ordersystemId ? `in Bestellung` : `* fehlt *`}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className={styles.info}>
                                                                <div className={styles.belt}>
                                                                    <div className={styles.beltInfo}>
                                                                        aktuell: {c.trainingBelt}
                                                                        <div className={styles.beltColor}>
                                                                            {c.trainingBelt !== undefined && user.sysconfig.BELTCOLORS
                                                                                ? <BeltColorStripes
                                                                                    colors={((user.sysconfig.BELTCOLORS[c.trainingBelt.replace(/\//g, '_') as keyof typeof user.sysconfig.BELTCOLORS] as unknown) as IBeltColor)?.colors || []}
                                                                                />
                                                                                : 'NA'}
                                                                        </div>
                                                                    </div>
                                                                    {c.nextName && (
                                                                        <div className={styles.beltInfo}>
                                                                            nächster: {c.nextName}
                                                                            <div className={styles.beltColor}>
                                                                                {c.nextName !== undefined && user.sysconfig.BELTCOLORS
                                                                                    ? <BeltColorStripes
                                                                                        colors={((user.sysconfig.BELTCOLORS[c.nextName.replace(/\//g, '_') as keyof typeof user.sysconfig.BELTCOLORS] as unknown) as IBeltColor)?.colors || []}
                                                                                    />
                                                                                    : 'NA'}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {((c.outError?.length || 0) > 0) && exam?.status === EKyuExamStatus.created && (
                                                            <div className={styles.error}>
                                                                {c?.outError?.map(x => x.message).join(' | ')}
                                                            </div>
                                                        )}
                                                    </div>
                                                </Item>
                                                {(index + 1) % 5 === 0 && <div className={printStyles['page-break']}/>}

                                            </React.Fragment>

                                        ))}

                                    </div>
                                ) : (
                                    <h3 className={styles.center}>Keine Kandidaten verfügbar</h3>
                                )}

                            </div>
                        </>

            </div>
        </ItemNavGroup>
    </>
};