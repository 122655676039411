import React, {MouseEvent} from "react";
import styles from './Button.module.scss'
import classNames from "classnames";

export interface IButtonProps {
    label?: string;
    children?: React.ReactNode; // Changed to ReactNode to accept multiple children
    width?: 'standard' | 'full';
    disabled?: boolean;
    onClick?: (evt: MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    type?: 'button' | 'submit'
    color?: 'primary' | 'success' | 'light';
    tooltip?: string;
}

export const Button: React.FC<IButtonProps> = ({
                                                   label,
                                                   children,
                                                   disabled,
                                                   onClick,
                                                   className,
                                                   type,
                                                   width,
                                                   color,
                                                   tooltip,
                                               }) => {
    const buttonContent = children || label;

    const buttonElement = (
        <button
            name={'type'}
            onClick={(evt) => onClick && onClick(evt)}
            type={type || 'button'}
            className={classNames(styles.input, className, width === 'full' && styles.full, styles[color || 'primary'])}
            disabled={disabled}
            title={tooltip}
            data-tooltip={tooltip}
        >
            {buttonContent}
        </button>
    );

    return tooltip ? (
        <div className={styles.buttonWrapper}>
            {buttonElement}
        </div>
    ) : buttonElement;
};