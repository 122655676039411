import { AuthService } from './auth';
import { CacheService } from './cache';
import { LocalService } from './local';

export function getAuth() {
    return `Bearer ${LocalService.getAuth()?.jwtToken || 'NONE'}`
}

export class ApiError extends Error {
    constructor(message: string, public result: any) {
        super(message);
    }
}

export class HttpService {
    private static async handleRequest<T>(
        request: () => Promise<T>,
        useAuth: boolean = true
    ): Promise<T> {
        try {
            return await request();
        } catch (error) {
            if (error instanceof ApiError &&
                error.result?.statusCode === 403 &&
                useAuth) {
                try {
                    await AuthService.refreshJwt();
                    return await request();
                } catch (refreshError) {
                    throw error;
                }
            }
            throw error;
        }
    }

    private static async handleError(url: string, ret: Response): Promise<ApiError> {
        let result: any;
        try {
            result = await ret.json();
        } catch (e) {
            result = {
                statusCode: ret.status,
                message: await ret.text()
            };
        }
        result.statusCode = ret.status;
        return new ApiError(`Error: ${ret.status} ${ret.statusText} - ${url}`, result);
    }

    public static async get<T>(url: string, useAuth = true, useCache = true): Promise<T> {
        if (useCache) {
            const cache = await CacheService.get(url);
            if (cache != null) {
                return cache;
            }
        }

        return this.handleRequest(async () => {
            const headers = (useAuth && LocalService.getAuth()?.jwtToken)
                ? new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${LocalService.getAuth()?.jwtToken}`,
                })
                : new Headers({ 'Content-Type': 'application/json' });

            const ret = await fetch(url, {
                method: 'GET',
                headers
            });

            if (ret.ok) {
                const data = await ret.json();
                if (useCache) await CacheService.set(url, data);
                return data;
            }
            throw await this.handleError(url, ret);
        }, useAuth);
    }

    public static async post<T>(url: string, body: any = null, useAuth = true): Promise<T> {
        return this.handleRequest(async () => {
            const headers = useAuth && LocalService.getAuth()?.jwtToken
                ? new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${LocalService.getAuth()?.jwtToken}`,
                })
                : new Headers({ 'Content-Type': 'application/json' });

            const ret = await fetch(url, {
                method: 'POST',
                headers,
                body: body ? JSON.stringify(body) : null
            });

            if (ret.ok) {
                return ret.json();
            }
            throw await this.handleError(url, ret);
        }, useAuth);
    }

    public static async post2<T, R>(url: string, body: T | null = null, useAuth = true, useCache = false): Promise<R> {
        if (useCache) {
            const cache = await CacheService.get(url);
            if (cache != null) {
                return cache;
            }
        }

        return this.handleRequest(async () => {
            const headers = useAuth && LocalService.getAuth()?.jwtToken
                ? new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${LocalService.getAuth()?.jwtToken}`,
                })
                : new Headers({ 'Content-Type': 'application/json' });

            const ret = await fetch(url, {
                method: 'POST',
                headers,
                body: body ? JSON.stringify(body) : null
            });

            if (ret.ok) {
                const data = await ret.json();
                if (useCache) await CacheService.set(url, data);
                return data;
            }
            throw await this.handleError(url, ret);
        }, useAuth);
    }

    public static async put<T>(url: string, body: T): Promise<T> {
        return this.handleRequest(async () => {
            const headers = LocalService.getAuth()?.jwtToken
                ? new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${LocalService.getAuth()?.jwtToken}`,
                })
                : new Headers({ 'Content-Type': 'application/json' });

            const ret = await fetch(url, {
                method: 'PUT',
                headers,
                body: JSON.stringify(body)
            });

            if (ret.ok) {
                return ret.json();
            }
            throw await this.handleError(url, ret);
        }, true);
    }

    public static async put2<T, R>(url: string, body: T): Promise<R> {
        return this.handleRequest(async () => {
            const headers = LocalService.getAuth()?.jwtToken
                ? new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${LocalService.getAuth()?.jwtToken}`,
                })
                : new Headers({ 'Content-Type': 'application/json' });

            const ret = await fetch(url, {
                method: 'PUT',
                headers,
                body: JSON.stringify(body)
            });

            if (ret.ok) {
                return ret.json();
            }
            throw await this.handleError(url, ret);
        }, true);
    }

    public static async delete<T>(url: string): Promise<T> {
        return this.handleRequest(async () => {
            const headers = LocalService.getAuth()?.jwtToken
                ? new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${LocalService.getAuth()?.jwtToken}`,
                })
                : new Headers({ 'Content-Type': 'application/json' });

            const ret = await fetch(url, {
                method: 'DELETE',
                headers
            });

            if (ret.ok) {
                return ret.json();
            }
            throw await this.handleError(url, ret);
        }, true);
    }

    public static async postBinary<T>(url: string, formData: FormData): Promise<T> {
        return this.handleRequest(async () => {
            const headers = new Headers();
            if (LocalService.getAuth()?.jwtToken) {
                headers.append('Authorization', `Bearer ${LocalService.getAuth()?.jwtToken}`);
            }

            const ret = await fetch(url, {
                method: 'POST',
                headers,
                body: formData
            });

            if (ret.ok) {
                return ret.json();
            }
            throw await this.handleError(url, ret);
        }, true); // Enable JWT refresh for binary uploads
    }

    public static async postBinaryJson<T>(url: string, formData: FormData): Promise<T> {
        return this.handleRequest(async () => {
            const headers = new Headers();
            if (LocalService.getAuth()?.jwtToken) {
                headers.append('Authorization', `Bearer ${LocalService.getAuth()?.jwtToken}`);
            }

            const ret = await fetch(url, {
                method: 'POST',
                headers,
                body: formData
            });

            if (ret.ok) {
                return ret.json();
            }
            throw await this.handleError(url, ret);
        }, true);
    }

    public static async postBinaryWithoutJsonResponse(url: string, formData: FormData): Promise<Response> {
        return this.handleRequest(async () => {
            const headers = new Headers();
            if (LocalService.getAuth()?.jwtToken) {
                headers.append('Authorization', `Bearer ${LocalService.getAuth()?.jwtToken}`);
            }

            const ret = await fetch(url, {
                method: 'POST',
                headers,
                body: formData
            });

            if (ret.ok) {
                return ret;  // Return the raw response
            }
            throw await this.handleError(url, ret);
        }, true);
    }


    public static async patch<T>(url: string, body: any = null, useAuth = true): Promise<T> {
        return this.handleRequest(async () => {
            const headers = useAuth && LocalService.getAuth()?.jwtToken
                ? new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${LocalService.getAuth()?.jwtToken}`,
                })
                : new Headers({ 'Content-Type': 'application/json' });

            const ret = await fetch(url, {
                method: 'PATCH',
                headers,
                body: body ? JSON.stringify(body) : null
            });

            if (ret.ok) {
                return ret.json();
            }
            throw await this.handleError(url, ret);
        }, useAuth);
    }
}