import React, { useEffect,useState } from 'react'; // Added useState import
import { ILeagueTeamEinzelBegeg, ILeagueTeamMember } from '../../../api/models/LeagueModel';
import styles from './leagueTeamEinzelBegeg.module.scss';
import { scoreOptions } from './league.utils';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

interface MatchTableProps {
    rounds: number[];
    matchesByRound: Record<number, ILeagueTeamEinzelBegeg[]>;
    homeTeamFighters: string[];
    awayTeamFighters: string[];
    isEditing: boolean;
    handleFieldChange: (matchId: number, field: string, value: number | string) => void;
    matchDetail: any;
    decisionMatchWinner: 'home' | 'away' | null;                       // Add this
    handleDecisionMatchWinner: (winner: 'home' | 'away' | null) => void; // Add this
}

export const MatchTable: React.FC<MatchTableProps> = ({
                                                          rounds,
                                                          matchesByRound,
                                                          homeTeamFighters,
                                                          awayTeamFighters,
                                                          isEditing,
                                                          handleFieldChange,
                                                          matchDetail,
                                                          decisionMatchWinner,
                                                          handleDecisionMatchWinner
                                                      }) => {
    // Helper function to get team members from matchDetail
    // Helper function to get team members from matchDetail
    const getTeamMembers = (isHomeTeam: boolean): ILeagueTeamMember[] => {
        if (!matchDetail) return [];

        // Try both property naming conventions (API uses lowercase, code may expect camelCase)
        const teamMembers = isHomeTeam
            ? (matchDetail.teammembers_h || matchDetail.teamMembers_h || [])
            : (matchDetail.teammembers_a || matchDetail.teamMembers_a || []);

        return teamMembers;
    };

    // Get team members
    const homeTeamMembers = getTeamMembers(true);
    const awayTeamMembers = getTeamMembers(false);

    useEffect(() => {
        if (matchDetail) {
            console.log('Team members from API:', {
                home: matchDetail.teammembers_h,
                away: matchDetail.teammembers_a
            });
        }
    }, [matchDetail]);

    // Create options arrays for dropdowns
    const getTeamMemberOptions = (isHomeTeam: boolean) => {
        const teamMembers = isHomeTeam ? homeTeamMembers : awayTeamMembers;
        const legacyOptions = isHomeTeam ? homeTeamFighters : awayTeamFighters;

        // If we have team members from matchDetail, use those
        if (teamMembers && teamMembers.length > 0) {
            return teamMembers.map(member => ({
                label: member.name,
                value: member.name,
                id: member.blTeamsId
            }));
        }

        // Fall back to legacy options if needed
        return legacyOptions.map(name => ({
            label: name,
            value: name,
            id: 0
        }));
    };

    const homeTeamOptions = getTeamMemberOptions(true);
    const awayTeamOptions = getTeamMemberOptions(false);
    // Function to calculate sum of sieg and UBW for a round
    // Function to calculate sum of sieg, UBW, and shido for a round
    const calculateRoundTotals = (round: number) => {
        const matches = matchesByRound[round] || [];
        return matches.reduce(
            (totals, match) => {
                return {
                    sieg_h: totals.sieg_h + match.sieg_h,
                    sieg_a: totals.sieg_a + match.sieg_a,
                    ubw_h: totals.ubw_h + match.ubw_h,
                    ubw_a: totals.ubw_a + match.ubw_a,
                    shido_h: totals.shido_h + match.shido_h,  // Add shido_h calculation
                    shido_a: totals.shido_a + match.shido_a   // Add shido_a calculation
                };
            },
            { sieg_h: 0, sieg_a: 0, ubw_h: 0, ubw_a: 0, shido_h: 0, shido_a: 0 }  // Initialize shido counts
        );
    };

    // Function to calculate overall totals for all rounds
    // Function to calculate overall totals for all rounds
    const calculateOverallTotals = () => {
        return rounds.reduce(
            (totals, round) => {
                const roundTotals = calculateRoundTotals(round);
                return {
                    sieg_h: totals.sieg_h + roundTotals.sieg_h,
                    sieg_a: totals.sieg_a + roundTotals.sieg_a,
                    ubw_h: totals.ubw_h + roundTotals.ubw_h,
                    ubw_a: totals.ubw_a + roundTotals.ubw_a,
                    shido_h: totals.shido_h + roundTotals.shido_h,  // Add shido_h calculation
                    shido_a: totals.shido_a + roundTotals.shido_a   // Add shido_a calculation
                };
            },
            { sieg_h: 0, sieg_a: 0, ubw_h: 0, ubw_a: 0, shido_h: 0, shido_a: 0 }  // Initialize shido counts
        );
    };

    // Function to handle decision match winner selection
    const handleDecisionMatchChange = (winner: 'home' | 'away' | null) => {
        // No need to maintain local state as it's passed from parent
        handleDecisionMatchWinner(winner);
    };

    // Function to get final scores including decision match if applicable
    const getFinalScores = () => {
        const totals = calculateOverallTotals();
        // if (decisionMatchWinner) {
        //     if (decisionMatchWinner === 'home') {
        //         return {
        //             ...totals,
        //             sieg_h: totals.sieg_h + 2,
        //             sieg_a: totals.sieg_a + 1
        //         };
        //     } else {
        //         return {
        //             ...totals,
        //             sieg_h: totals.sieg_h + 1,
        //             sieg_a: totals.sieg_a + 2
        //         };
        //     }
        // }
        return totals;
    };

    // Render a summary row for intermediate or final scores
    // Updated renderSummaryRow function with winner/loser indicators
    // Updated renderSummaryRow function with trophy emoji
    // Updated renderSummaryRow function to handle final decision match results
    // Updated renderSummaryRow function to include shido totals
    const renderSummaryRow = (title: string, totals: {
        sieg_h: number,
        sieg_a: number,
        ubw_h: number,
        ubw_a: number,
        shido_h: number,
        shido_a: number
    }, isFinal = false) => {
        // Determine match result
        let homeResult = '';
        let awayResult = '';

        if (title === 'Endergebnis' || title === 'Entscheidungs-kampf') {
            if (totals.sieg_h > totals.sieg_a) {
                homeResult = isFinal ? 'SIEGER 🏆' : 'Sieger 🏆';
                awayResult = isFinal ? 'VERLIERER' : 'Verlierer';
            } else if (totals.sieg_a > totals.sieg_h) {
                homeResult = isFinal ? 'VERLIERER' : 'Verlierer';
                awayResult = isFinal ? 'SIEGER 🏆' : 'Sieger 🏆';
            } else {
                // If sieg values are equal, it's a tie (UBW is not used as a tiebreaker)
                homeResult = 'unentschieden';
                awayResult = 'unentschieden';
            }
        }

        return (
            <tr className={`${styles.summaryRow} ${isFinal ? styles.finalSummaryRow : ''}`}>
                <td colSpan={2} className={styles.summaryTitle}>{title}</td>

                {/* Home Team Summary */}
                <td colSpan={4} className={styles.summaryEmpty}>
                    {/*{homeResult && (*/}
                    {/*    <div className={`${styles.resultIndicator} ${homeResult.includes('SIEGER') || homeResult.includes('Sieger') ? styles.winnerIndicator : homeResult === 'unentschieden' ? styles.tieIndicator : styles.loserIndicator}`}>*/}
                    {/*        {homeResult}*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </td>

                {/* Add shido total for home team */}
                <td className={styles.summaryShido}>{totals.shido_h}</td>

                <td className={styles.summaryEmpty}></td>  {/* HSM placeholder */}
                <td className={styles.summarySieg}>{totals.sieg_h}</td>
                <td className={styles.summaryUbw}>{totals.ubw_h}</td>

                {/* Away Team Summary */}
                <td colSpan={4} className={styles.summaryEmpty}>
                    {/*{awayResult && (*/}
                    {/*    <div className={`${styles.resultIndicator} ${awayResult.includes('SIEGER') || awayResult.includes('Sieger') ? styles.winnerIndicator : awayResult === 'unentschieden' ? styles.tieIndicator : styles.loserIndicator}`}>*/}
                    {/*        {awayResult}*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </td>

                {/* Add shido total for away team */}
                <td className={styles.summaryShido}>{totals.shido_a}</td>

                <td className={styles.summaryEmpty}></td>  {/* HSM placeholder */}
                <td className={styles.summarySieg}>{totals.sieg_a}</td>
                <td className={styles.summaryUbw}>{totals.ubw_a}</td>
            </tr>
        );
    };

    // Render decision match row with checkboxes
    // Render decision match row with checkboxes that can be unselected
    const renderDecisionMatchRow = () => (
        <tr className={styles.decisionMatchRow}>
            <td colSpan={2} className={styles.summaryTitle}>

                {/*{decisionMatchWinner && (*/}
                {/*    <button*/}
                {/*        className={styles.clearSelectionButton}*/}
                {/*        onClick={() => setDecisionMatchWinner(null)}*/}
                {/*        disabled={!isEditing}*/}
                {/*    >*/}
                {/*        ✕ Zurücksetzen*/}
                {/*    </button>*/}
                {/*)}*/}
            </td>

            {/* Home Team Checkbox */}
            <td colSpan={8} className={styles.decisionMatchCell}>
                <label className={styles.decisionMatchLabel}>
                    <input
                        type="checkbox"
                        checked={decisionMatchWinner === 'home'} // This will be false if decisionMatchWinner is null
                        onChange={() => handleDecisionMatchWinner(decisionMatchWinner === 'home' ? null : 'home')}
                        disabled={!isEditing}
                    />
                    Sieger im Entscheidungskampf
                </label>
            </td>

            {/* Away Team Checkbox */}
            <td colSpan={8} className={styles.decisionMatchCell}>
                <label className={styles.decisionMatchLabel}>
                    <input
                        type="checkbox"
                        checked={decisionMatchWinner === 'away'} // This will be false if decisionMatchWinner is null
                        onChange={() => handleDecisionMatchWinner(decisionMatchWinner === 'away' ? null : 'away')}
                        disabled={!isEditing}
                    />
                    Sieger im Entscheidungskampf
                </label>
            </td>
        </tr>
    );

    // Render dropdown component for numeric values with special styling for Sieg fields
    // Update the renderDropdown function
    const renderDropdown = (matchId: number, field: string, value: number, options: number[]) => {
        // Find the match object
        const match = [...rounds].flatMap(round => matchesByRound[round] || [])
            .find(m => m.einzelbegeg_id === matchId);

        if (!match) return <span>{value}</span>;

        // Determine if this is a Sieg field
        const isSiegField = field === 'sieg_h' || field === 'sieg_a';

        // Determine the CSS class for Sieg fields
        let siegClass = '';
        if (isSiegField) {
            siegClass = value === 1 ? styles.siegWin : styles.siegLoss;
        }

        // Determine if this control should be disabled
        const isDisabled = shouldDisableControls(match, field);

        return isEditing ? (
            <select
                value={value}
                onChange={(e) => handleFieldChange(matchId, field, parseInt(e.target.value))}
                className={`
                    ${styles.scoreDropdown} 
                    ${siegClass} 
                    ${isDisabled ? styles.disabledControl : ''} 
                    ${value !== 0 ? styles.valueChanged : ''}
                  `}
                disabled={isDisabled}
            >
                {options.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}
            </select>
        ) : (
            <span className={siegClass}>{value}</span>
        );
    };

    const calculateMatchPoints = () => {
        // First check if any fighters are selected at all
        const hasFighters = areAnyFightersSelected();

        // If no fighters are selected, return 0 points for both teams
        if (!hasFighters) {
            return {
                homePoints: 0,
                awayPoints: 0
            };
        }

        // Update the return type to allow undefined values
        type PointsResult = {
            homePoints: number | undefined;
            awayPoints: number | undefined;
        };

        // If matchDetail has point values, use them directly
        if (matchDetail &&
            matchDetail.erg_punkte_h !== undefined &&
            matchDetail.erg_punkte_a !== undefined) {
            return {
                homePoints: matchDetail.erg_punkte_h,
                awayPoints: matchDetail.erg_punkte_a
            } as PointsResult;
        }

        // Fallback: Calculate points based on match results
        const totals = calculateOverallTotals();

        // Initialize points with explicitly allowing undefined
        let homePoints: number | undefined = 0;
        let awayPoints: number | undefined = 0;

        // Award points based on siege scores
        if (totals.sieg_h > totals.sieg_a) {
            homePoints = 2;
            awayPoints = 0;
        } else if (totals.sieg_a > totals.sieg_h) {
            homePoints = 0;
            awayPoints = 2;
        } else {
            // Equality case (1:1)
            homePoints = 1;
            awayPoints = 1;

            // Add bonus point for decision match winner if available
            if (decisionMatchWinner === 'home') {
                homePoints += 1;
            } else if (decisionMatchWinner === 'away') {
                awayPoints += 1;
            } else {
                // Case when there's a tie but no decision match winner
                homePoints = undefined;
                awayPoints = undefined;
            }
        }

        return { homePoints, awayPoints } as PointsResult;
    };

    const renderPointsRow = () => {
        const { homePoints, awayPoints } = calculateMatchPoints();

        // Determine result text based on points
        let homeResult = '';
        let awayResult = '';

        if (homePoints !== undefined && awayPoints !== undefined) {
            if (homePoints > awayPoints) {
                homeResult = 'SIEGER 🏆';
                awayResult = ' ';
            } else if (awayPoints > homePoints) {
                homeResult = ' ';
                awayResult = 'SIEGER 🏆';
            } else if (homePoints === 0 && awayPoints === 0) {
                // Special case for 0-0
                homeResult = ''; // Or any specific text for 0-0
                awayResult = ''; // Or any specific text for 0-0
            } else {
                // They're equal but not 0-0
                homeResult = 'unentschieden';
                awayResult = 'unentschieden';
            }
        } else {
            // Handle the case where either or both points are undefined
            homeResult = '';
            awayResult = '';
        }

        return (
            <tr className={`${styles.summaryRow} ${styles.finalSummaryRow}`}>
                <td colSpan={2} className={styles.summaryTitle}>Punkte</td>

                {/* Home Team Points */}
                <td colSpan={8} className={styles.summaryEmpty}>
                    {homePoints !== undefined && (
                        <div className={`${styles.resultIndicator} ${
                            homePoints > (awayPoints || 0) ? styles.winnerIndicator :
                                homePoints === awayPoints && homePoints > 0 ? styles.tieIndicator :
                                    homePoints > 0 ? styles.loserIndicator : ''
                        }`}>
                            {homeResult} {homePoints > 0 && (
                            <>+{homePoints} Punkt{homePoints !== 1 ? 'e' : ''}</>
                        )}
                        </div>
                    )}
                </td>

                {/* Away Team Points */}
                <td colSpan={8} className={styles.summaryEmpty}>
                    {awayPoints !== undefined && (
                        <div className={`${styles.resultIndicator} ${
                            awayPoints > (homePoints || 0) ? styles.winnerIndicator :
                                awayPoints === homePoints && awayPoints > 0 ? styles.tieIndicator :
                                    awayPoints > 0 ? styles.loserIndicator : ''
                        }`}>
                            {awayResult} {awayPoints > 0 && (
                            <>+{awayPoints} Punkt{awayPoints !== 1 ? 'e' : ''}</>
                        )}
                        </div>
                    )}
                </td>
            </tr>
        );
    };

    // Update the fighter dropdown rendering
    const renderFighterDropdown = (matchId: number, field: string, value: string, options: Array<{label: string, value: string, id: number}>) => {
        // Find the match object
        const match = [...rounds].flatMap(round => matchesByRound[round] || [])
            .find(m => m.einzelbegeg_id === matchId);

        if (!match) return <span>{value}</span>;

        // Determine if this control should be disabled
        const isDisabled = false; //shouldDisableControls(match, field);

        // Map field names to ID field names
        const getIdFieldName = (nameField: string) => {
            if (nameField === 'name_heim') return 'bl_teams_mg_id_heim';
            if (nameField === 'name_ausw') return 'bl_teams_mg_id_ausw';
            return '';
        };

        // Find currently selected fighter ID
        const selectedFighterId = field === 'name_heim' ?
            match.bl_teams_mg_id_heim :
            field === 'name_ausw' ?
                match.bl_teams_mg_id_ausw :
                0;

        // For debugging
        //console.log('Current match:', match);
        //console.log('Selected fighter ID:', selectedFighterId);

        return isEditing ? (
            <select
                value={selectedFighterId || ''}
                onChange={(e) => {
                    const fighterId = e.target.value;
                    //console.log('Selected fighter ID from dropdown:', fighterId);

                    const idFieldName = getIdFieldName(field);
                    //console.log('ID field name:', idFieldName);

                    // Find the selected fighter to get their name
                    const selectedFighter = options.find(f => f.id.toString() === fighterId);
                    const fighterName = selectedFighter ? selectedFighter.label : '';
                    //console.log('Selected fighter name:', fighterName);

                    // Update both the name and ID fields
                    handleFieldChange(matchId, field, fighterName);

                    if (idFieldName) {
                        // Make sure we're passing a number, not a string
                        const fighterIdAsNumber = parseInt(fighterId) || 0;
                        //console.log('Fighter ID as number:', fighterIdAsNumber);

                        handleFieldChange(matchId, idFieldName, fighterIdAsNumber);

                        // Log the match again after update
                        setTimeout(() => {
                            const updatedMatch = [...rounds].flatMap(round => matchesByRound[round] || [])
                                .find(m => m.einzelbegeg_id === matchId);
                            //console.log('Updated match:', updatedMatch);
                        }, 100);
                    }
                }}
                className={`
                    ${styles.fighterDropdown} 
                    ${isDisabled ? styles.disabledControl : ''} 
                    ${selectedFighterId ? styles.fighterValueChanged : ''}
                  `}
                disabled={isDisabled}
            >
                <option value="">-- Select Fighter --</option>
                {options.map(fighter => (
                    <option key={fighter.id || fighter.value} value={fighter.id}>{fighter.label}</option>
                ))}
            </select>
        ) : (
            <span>{value}</span>
        );
    };

    // Add this helper function to determine if controls should be disabled
    const shouldDisableControls = (match: ILeagueTeamEinzelBegeg, field: string) => {
        // If not in editing mode, all controls are disabled
        if (!isEditing) return true;

        // If neither side has reached 100 UBW, don't disable anything
        if (match.ubw_h < 100 && match.ubw_a < 100) return false;

        // Get the field value
        const fieldValue = match[field as keyof ILeagueTeamEinzelBegeg];

        // If this field already has a non-zero value, keep it enabled for correction
        if (fieldValue && (typeof fieldValue === 'number' && fieldValue > 0)) {
            return false;
        }

        // If this is a UBW field, never disable it to allow corrections
        if (field === 'ubw_h' || field === 'ubw_a') {
            return false;
        }

        // If this is a Sieg field for the winner, never disable it
        if ((field === 'sieg_h' && match.ubw_h >= 100) || (field === 'sieg_a' && match.ubw_a >= 100)) {
            return false;
        }

        // Otherwise, disable empty/zero fields when any side has 100+ UBW
        return true;
    };

    const areAnyFightersSelected = () => {
        // Check all matches across all rounds
        return rounds.some(round => {
            const matchesInRound = matchesByRound[round] || [];

            // For each match in the round, check if either home or away fighter is selected
            return matchesInRound.some(match =>
                match.name_heim?.trim() || match.name_ausw?.trim()
            );
        });
    };

    useEffect(() => {
        //console.log('MatchTable received matchesByRound:', matchesByRound);

        // Check each round for UBW values
        Object.keys(matchesByRound).forEach(round => {
            const roundMatches = matchesByRound[Number(round)];
            if (roundMatches) {
                roundMatches.forEach(match => {
                    //console.log(`Round ${round}, Match ${match.einzelbegeg_id} - UBW Home: ${match.ubw_h} (type: ${typeof match.ubw_h}), UBW Away: ${match.ubw_a} (type: ${typeof match.ubw_a})`);

                    // If UBW should be 100, verify this
                    if (match.ubw_h === 100) {
                        //console.log(`Confirmed match ${match.einzelbegeg_id} has UBW_H = 100`);
                    } else if (match.ubw_h === 1 && (match.ippon_h === 1 || match.hsm_a === 1)) {
                        //console.log(`WARNING: Match ${match.einzelbegeg_id} has UBW_H = 1 but should be 100! ippon_h=${match.ippon_h}, hsm_a=${match.hsm_a}`);
                    }
                });
            }
        });
    }, [matchesByRound]);

    // Render empty table with placeholders when there are no rounds
    if (rounds.length > 0) {
        // Calculate overall totals
        const overallTotals = calculateOverallTotals();
        const finalScores = getFinalScores();

        // Check if sieg values are equal (to determine if we need decision match UI)
        const siegValuesEqual = overallTotals.sieg_h === overallTotals.sieg_a;

        return (
            <>
                {rounds.includes(1) && (
                    <div key="round-1" className={styles.tabelContainer}>
                        <label>1.Durchgang</label>
                        <div className={styles.roundTable}>
                            <table className={styles.matchTable}>
                                <thead>
                                <tr>
                                    <th className={styles.colNo} rowSpan={2}>Nr.</th>
                                    <th className={styles.colKG} rowSpan={2}>KG</th>
                                    <th className={styles.teamHeader} colSpan={8}>HEIM: {matchDetail?.teamname_h || "Home Team"}</th>
                                    <th className={styles.teamHeader} colSpan={8}>AUSWÄRTS: {matchDetail?.teamname_a || "Away Team"}</th>
                                </tr>
                                <tr>
                                    {/* Table headers remain the same */}
                                    <th className={styles.colName}>Name</th>
                                    <th className={styles.colScore}>Y</th>
                                    <th className={styles.colScore}>W</th>
                                    <th className={styles.colScore}>I</th>
                                    <th className={styles.colScore}>S</th>
                                    <th className={styles.colScore}>HSM</th>
                                    <th className={styles.colScore}>Sieg</th>
                                    <th className={styles.colScore}>UBW</th>

                                    <th className={styles.colName}>Name</th>
                                    <th className={styles.colScore}>Y</th>
                                    <th className={styles.colScore}>W</th>
                                    <th className={styles.colScore}>I</th>
                                    <th className={styles.colScore}>S</th>
                                    <th className={styles.colScore}>HSM</th>
                                    <th className={styles.colScore}>Sieg</th>
                                    <th className={styles.colScore}>UBW</th>
                                </tr>
                                </thead>
                                <tbody>
                                {/* Render round 1 matches */}
                                {matchesByRound[1].map((match) => (
                                    <tr key={match.einzelbegeg_id} className={styles.matchRow}>
                                        {/* Match row rendering remains the same */}
                                        <td className={styles.colNo}>{match.laufno}</td>
                                        <td className={styles.colKG}>{match.bl_gewklass}</td>

                                        {/* Home Team Data */}
                                        <td className={styles.colName}>
                                            {renderFighterDropdown(match.einzelbegeg_id, 'name_heim', match.name_heim || '', homeTeamOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'yuko_h', match.yuko_h, scoreOptions.yukoOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'wazaari_h', match.wazaari_h, scoreOptions.wazaariOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'ippon_h', match.ippon_h, scoreOptions.ipponOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'shido_h', match.shido_h, scoreOptions.shidoOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'hsm_h', match.hsm_h, scoreOptions.hsmOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'sieg_h', match.sieg_h, scoreOptions.siegOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {isEditing ? (
                                                <input
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    value={match.ubw_h}
                                                    onChange={(e) => handleFieldChange(match.einzelbegeg_id, 'ubw_h', Math.min(100, Math.max(0, parseInt(e.target.value) || 0)))}
                                                    className={`${styles.scoreInput} ${shouldDisableControls(match, 'ubw_h') ? styles.disabledControl : ''}`}
                                                    disabled={shouldDisableControls(match, 'ubw_h')}
                                                />
                                            ) : match.ubw_h}
                                        </td>

                                        {/* Away Team Data */}
                                        <td className={styles.colName}>
                                            {renderFighterDropdown(match.einzelbegeg_id, 'name_ausw', match.name_ausw || '', awayTeamOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'yuko_a', match.yuko_a, scoreOptions.yukoOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'wazaari_a', match.wazaari_a, scoreOptions.wazaariOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'ippon_a', match.ippon_a, scoreOptions.ipponOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'shido_a', match.shido_a, scoreOptions.shidoOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'hsm_a', match.hsm_a, scoreOptions.hsmOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'sieg_a', match.sieg_a, scoreOptions.siegOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {isEditing ? (
                                                <input
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    value={match.ubw_a}
                                                    onChange={(e) => handleFieldChange(match.einzelbegeg_id, 'ubw_a', Math.min(100, Math.max(0, parseInt(e.target.value) || 0)))}
                                                    className={`${styles.scoreInput} ${shouldDisableControls(match, 'ubw_a') ? styles.disabledControl : ''}`}
                                                    disabled={shouldDisableControls(match, 'ubw_a')}
                                                />
                                            ) : match.ubw_a}
                                        </td>
                                    </tr>
                                ))}

                                {/* Add Zwischenstand (Intermediate score) after round 1 */}
                                {renderSummaryRow("Zwischenstand", calculateRoundTotals(1))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {rounds.includes(2) && (
                    <div key="round-2" className={styles.tabelContainer}>
                        <label>2.Durchgang</label>
                        <div className={styles.roundTable}>
                            <table className={styles.matchTable}>
                                <thead>
                                <tr>
                                    <th className={styles.colNo} rowSpan={2}>Nr.</th>
                                    <th className={styles.colKG} rowSpan={2}>KG</th>
                                    <th className={styles.teamHeader} colSpan={8}>HEIM: {matchDetail?.teamname_h || "Home Team"}</th>
                                    <th className={styles.teamHeader} colSpan={8}>AUSWÄRTS: {matchDetail?.teamname_a || "Away Team"}</th>
                                </tr>
                                <tr>
                                    {/* Table headers remain the same */}
                                    <th className={styles.colName}>Name</th>
                                    <th className={styles.colScore}>Y</th>
                                    <th className={styles.colScore}>W</th>
                                    <th className={styles.colScore}>I</th>
                                    <th className={styles.colScore}>S</th>
                                    <th className={styles.colScore}>HSM</th>
                                    <th className={styles.colScore}>Sieg</th>
                                    <th className={styles.colScore}>UBW</th>

                                    <th className={styles.colName}>Name</th>
                                    <th className={styles.colScore}>Y</th>
                                    <th className={styles.colScore}>W</th>
                                    <th className={styles.colScore}>I</th>
                                    <th className={styles.colScore}>S</th>
                                    <th className={styles.colScore}>HSM</th>
                                    <th className={styles.colScore}>Sieg</th>
                                    <th className={styles.colScore}>UBW</th>
                                </tr>
                                </thead>
                                <tbody>
                                {/* Render round 2 matches */}
                                {matchesByRound[2].map((match) => (
                                    <tr key={match.einzelbegeg_id} className={styles.matchRow}>
                                        {/* Match row rendering remains the same */}
                                        <td className={styles.colNo}>{match.laufno}</td>
                                        <td className={styles.colKG}>{match.bl_gewklass}</td>

                                        {/* Home Team Data */}
                                        <td className={styles.colName}>
                                            {renderFighterDropdown(match.einzelbegeg_id, 'name_heim', match.name_heim || '', homeTeamOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'yuko_h', match.yuko_h, scoreOptions.yukoOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'wazaari_h', match.wazaari_h, scoreOptions.wazaariOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'ippon_h', match.ippon_h, scoreOptions.ipponOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'shido_h', match.shido_h, scoreOptions.shidoOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'hsm_h', match.hsm_h, scoreOptions.hsmOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'sieg_h', match.sieg_h, scoreOptions.siegOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {isEditing ? (
                                                <input
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    value={match.ubw_h}
                                                    onChange={(e) => handleFieldChange(match.einzelbegeg_id, 'ubw_h', Math.min(100, Math.max(0, parseInt(e.target.value) || 0)))}
                                                    className={styles.scoreInput}
                                                />
                                            ) : match.ubw_h}
                                        </td>

                                        {/* Away Team Data */}
                                        <td className={styles.colName}>
                                            {renderFighterDropdown(match.einzelbegeg_id, 'name_ausw', match.name_ausw || '', awayTeamOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'yuko_a', match.yuko_a, scoreOptions.yukoOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'wazaari_a', match.wazaari_a, scoreOptions.wazaariOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'ippon_a', match.ippon_a, scoreOptions.ipponOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'shido_a', match.shido_a, scoreOptions.shidoOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'hsm_a', match.hsm_a, scoreOptions.hsmOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {renderDropdown(match.einzelbegeg_id, 'sieg_a', match.sieg_a, scoreOptions.siegOptions)}
                                        </td>
                                        <td className={styles.colScore}>
                                            {isEditing ? (
                                                <input
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    value={match.ubw_a}
                                                    onChange={(e) => handleFieldChange(match.einzelbegeg_id, 'ubw_a', Math.min(100, Math.max(0, parseInt(e.target.value) || 0)))}
                                                    className={styles.scoreInput}
                                                />
                                            ) : match.ubw_a}
                                        </td>
                                    </tr>
                                ))}

                                {/* Add Endergebnis (Final result) after round 2 */}
                                {renderSummaryRow("Endergebnis", overallTotals)}

                                {/* Add decision match UI if sieg values are equal */}
                                {siegValuesEqual && (
                                    <>
                                        {renderDecisionMatchRow()}
                                        {/* Final score with decision match winner - mark as final */}
                                        {/*{decisionMatchWinner && renderSummaryRow("Entscheidungs-kampf", finalScores, true)}*/}
                                    </>
                                )}
                                {renderPointsRow()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </>
        );
    }

    // If there are rounds, render the rounds - update to use new dropdown options
    return (
        <>
            {rounds.map((round) => (
                <div key={`round-${round}`}>
                    <label>Durchgang {round}</label>
                    <div className={styles.roundTable}>
                        <table className={styles.matchTable}>
                            <thead>
                            <tr>
                                <th className={styles.colNo} rowSpan={2}>Nr.</th>
                                <th className={styles.colKG} rowSpan={2}>KG</th>
                                <th className={styles.teamHeader} colSpan={8}>HEIM: {matchDetail?.teamname_h || "Home Team"}</th>
                                <th className={styles.teamHeader} colSpan={8}>AUSWÄRTS: {matchDetail?.teamname_a || "Away Team"}</th>
                            </tr>
                            <tr>
                                {/* Home Team Column Headers */}
                                <th className={styles.colName}>Name</th>
                                <th className={styles.colScore}>Y</th>
                                <th className={styles.colScore}>W</th>
                                <th className={styles.colScore}>I</th>
                                <th className={styles.colScore}>S</th>
                                <th className={styles.colScore}>HSM</th>
                                <th className={styles.colScore}>Sieg</th>
                                <th className={styles.colScore}>UBW</th>

                                {/* Away Team Column Headers */}
                                <th className={styles.colName}>Name</th>
                                <th className={styles.colScore}>Y</th>
                                <th className={styles.colScore}>W</th>
                                <th className={styles.colScore}>I</th>
                                <th className={styles.colScore}>S</th>
                                <th className={styles.colScore}>HSM</th>
                                <th className={styles.colScore}>Sieg</th>
                                <th className={styles.colScore}>UBW</th>
                            </tr>
                            </thead>
                            <tbody>
                            {matchesByRound[round].map((match) => (
                                <tr key={match.einzelbegeg_id} className={styles.matchRow}>
                                    <td className={styles.colNo}>{match.laufno}</td>
                                    <td className={styles.colKG}>{match.bl_gewklass}</td>

                                    {/* Home Team Data */}
                                    <td className={styles.colName}>
                                        {renderFighterDropdown(match.einzelbegeg_id, 'name_heim', match.name_heim || '', homeTeamOptions)}
                                    </td>
                                    <td className={styles.colScore}>
                                        {renderDropdown(match.einzelbegeg_id, 'yuko_h', match.yuko_h, scoreOptions.yukoOptions)}
                                    </td>
                                    <td className={styles.colScore}>
                                        {renderDropdown(match.einzelbegeg_id, 'wazaari_h', match.wazaari_h, scoreOptions.wazaariOptions)}
                                    </td>
                                    <td className={styles.colScore}>
                                        {renderDropdown(match.einzelbegeg_id, 'ippon_h', match.ippon_h, scoreOptions.ipponOptions)}
                                    </td>
                                    <td className={styles.colScore}>
                                        {renderDropdown(match.einzelbegeg_id, 'shido_h', match.shido_h, scoreOptions.shidoOptions)}
                                    </td>
                                    <td className={styles.colScore}>
                                        {renderDropdown(match.einzelbegeg_id, 'hsm_h', match.hsm_h, scoreOptions.hsmOptions)}
                                    </td>
                                    <td className={styles.colScore}>
                                        {renderDropdown(match.einzelbegeg_id, 'sieg_h', match.sieg_h, scoreOptions.siegOptions)}
                                    </td>
                                    <td className={styles.colScore}>
                                        {isEditing ? (
                                            <input
                                                type="number"
                                                min="0"
                                                max="100"
                                                value={match.ubw_h}
                                                onChange={(e) => handleFieldChange(match.einzelbegeg_id, 'ubw_h', Math.min(100, Math.max(0, parseInt(e.target.value) || 0)))}
                                                className={styles.scoreInput}
                                            />
                                        ) : match.ubw_h}
                                    </td>

                                    {/* Away Team Data */}
                                    <td className={styles.colName}>
                                        {renderFighterDropdown(match.einzelbegeg_id, 'name_ausw', match.name_ausw || '', awayTeamOptions)}
                                    </td>
                                    <td className={styles.colScore}>
                                        {renderDropdown(match.einzelbegeg_id, 'yuko_a', match.yuko_a, scoreOptions.yukoOptions)}
                                    </td>
                                    <td className={styles.colScore}>
                                        {renderDropdown(match.einzelbegeg_id, 'wazaari_a', match.wazaari_a, scoreOptions.wazaariOptions)}
                                    </td>
                                    <td className={styles.colScore}>
                                        {renderDropdown(match.einzelbegeg_id, 'ippon_a', match.ippon_a, scoreOptions.ipponOptions)}
                                    </td>
                                    <td className={styles.colScore}>
                                        {renderDropdown(match.einzelbegeg_id, 'shido_a', match.shido_a, scoreOptions.shidoOptions)}
                                    </td>
                                    <td className={styles.colScore}>
                                        {renderDropdown(match.einzelbegeg_id, 'hsm_a', match.hsm_a, scoreOptions.hsmOptions)}
                                    </td>
                                    <td className={styles.colScore}>
                                        {renderDropdown(match.einzelbegeg_id, 'sieg_a', match.sieg_a, scoreOptions.siegOptions)}
                                    </td>
                                    <td className={styles.colScore}>
                                        {isEditing ? (
                                            <input
                                                type="number"
                                                min="0"
                                                max="100"
                                                value={match.ubw_a}
                                                onChange={(e) => handleFieldChange(match.einzelbegeg_id, 'ubw_a', Math.min(100, Math.max(0, parseInt(e.target.value) || 0)))}
                                                className={styles.scoreInput}
                                            />
                                        ) : match.ubw_a}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
        </>
    );
};