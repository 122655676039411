import React, {useEffect, useMemo, useState} from "react";
import { useNavigate } from "react-router-dom";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {ItemNav} from "../../form/ItemNav";
import classNames from "classnames";
import {IPageLinkProps} from "../../page.props";
import styles from './notification.module.scss'
import {useParamId, useParamRole} from "../../useParam";
import {Loading} from "../../foundation/Loading";
import { NotificationService } from "../../../api/notification";
import { INotification } from "../../../api/models/NotificationModel";
import {DateBetweenText, FDT, FDS, FDTHBR, Input, Item} from "../../form";
import {faAdd} from "@fortawesome/free-solid-svg-icons";

const Notification: React.FC<IPageLinkProps> = ({user, gotoUrl, refresh}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [search, setSearch] = useState<string>('');

    const id = useParamId();
    const role = useParamRole(user);

    const filterLength = 200;

    useEffect(() => {
        setLoading(true);
        if(role != null) {
            NotificationService.all(role.orgId).then((m) => {
                setNotifications(m);
            }).finally(() => setLoading(false));
        } else {
            setLoading(false)
        }
    }, [user, refresh, role])

    const filter = useMemo(() => {
        const source = notifications;
        return search.length > 0 ? source.filter(x =>
            x.title?.toLowerCase()?.includes(search?.toLowerCase())
        ) : source;
    }, [notifications, search]);

    const goto = (pcId: number) => {
         navigate(`${gotoUrl}/${pcId}`);
    };

    return <>
        <ItemNavGroup label={role?.orgName}>
            <>
                {loading && <Loading/>}
                <ItemNav
                    label="Neuen Nachricht erstellen"
                    icon={faAdd}
                    onClick={() => goto(0)}
                />
                <Item type="full" className={styles.search}>
                    <Input
                        onChange={setSearch}
                        value={search}
                        className={styles.name}
                        placeholder="Search notifications..."
                    />
                </Item>
                {!loading && (filter?.length > 0 ? (
                    <>
                        {filter.slice(0, filterLength).map((m) => (
                            <ItemNav
                                key={m.id}
                                onClick={() => goto(m.pcId)}  // Using navigate instead of href
                                className={classNames(styles.content)}
                                selected={id === m.pcId}
                            >
                                <div className={styles.entry}>
                                    <div className={styles.name}>
                                        <div className={styles.notificationId}>{m.pcId}/{m.id}</div>
                                        {m.title}
                                        <div className={styles.types}>
                                            {m.paType.map((type, index) => (
                                                <span key={index} className={styles.type}>
                                                    {type}
                                                </span>
                                            ))}
                                        </div>
                                        <div className={styles.date}>
                                            {FDTHBR(new Date(m.publishedAt).toLocaleString())}
                                        </div>
                                    </div>
                                </div>
                            </ItemNav>
                        ))}
                        {filter.length > filterLength && (
                            <Item>Only showing first {filterLength} results</Item>
                        )}
                    </>
                ) : <Item type="list" label="Keine Benachrichtigungen gefunden!" />)}
            </>
        </ItemNavGroup>
    </>
}

export default Notification;