import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useParams } from "react-router-dom";
import { IPageProps } from "../../page.props";
import { useParamId, useParamRole } from "../../useParam";
import { NotificationService } from "../../../api/notification";
import { INotification } from "../../../api/models/NotificationModel";
import { ItemNavGroup } from "../../form/ItemNavGroup";
import { Item } from "../../form/Item";
import { Loading } from "../../foundation/Loading";
import { FDT } from "../../form/Date";
import styles from './notification_detail.module.scss';
import {Constants} from "../../../api/constants";
import ImageUrl from '../../foundation/ImageUrl';
import { NotificationForm } from './notificationForm';
import {IPageLinkProps} from "../../page.props";
import { CompetitionsService } from "../../../api/competitions";

export const NotificationDetail: React.FC<IPageLinkProps> = (props) => {
    const role = useParamRole(props.user);
    const [notification, setNotification] = useState<INotification | null>(null);
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const pcId = params.id;

    useEffect(() => {
        const fetchMessage = async () => {
            if (role?.orgId && pcId && pcId !== "0") {
                setLoading(true);
                try {
                    const result = await NotificationService.get(role.orgId, Number(pcId));
                    console.log('Debug - fetch result:', result);
                    setNotification(result);
                } catch (error) {
                    console.error('Error fetching message:', error);
                    setNotification(null);
                } finally {
                    setLoading(false);
                }
            } else {
                setNotification(null);
                setLoading(false);
            }
        };
        fetchMessage();
    }, [pcId, role?.orgId, props.refresh]);

    const FILE_DOWNLOAD = `${Constants.JAMA_SERVER_API_V2}/web/download`;


    if (pcId === "0") {
        return (
            <NotificationForm
                {...props}  // This will pass all IPageLinkProps correctly
            />
        );
    }

    console.log('Debug - attempting to fetch notification with:', {
        orgId: role?.orgId,
        pcId: pcId
    });

    if (loading) {
        return <Loading />;
    }

    if (!notification) {
        return <ItemNavGroup label="Notifications">
            <Item type="list" label="No notification selected" />
        </ItemNavGroup>;
    }

    return (
        <ItemNavGroup label={`Notification #${notification.id}/${pcId}`}>
            <>
                {/* Header Information */}
                <Item type="full" label="Title">
                    <div className={styles.title}>{notification.title}</div>
                </Item>

                <Item type="full" label="Published">
                    <div className={styles.date}>
                        {notification.publishedAt ? FDT(notification.publishedAt) : ''}
                    </div>
                </Item>

                {/* Types - with null check */}
                {notification.paType && notification.paType.length > 0 && (
                    <Item type="full" label="Types">
                        <div className={styles.types}>
                            {notification.paType.map((type, index) => (
                                <span key={index} className={styles.type}>
                                    {type}
                                </span>
                            ))}
                        </div>
                    </Item>
                )}

                {/* Abstract - with null check */}
                {notification.abstract && (
                    <Item type="full" label="Abstract">
                        <div
                            className={styles.abstract}
                            dangerouslySetInnerHTML={{ __html: notification.abstract }}
                        />
                    </Item>
                )}

                {/* Content - with null check */}
                {notification.content && (
                    <Item type="full" label="Content">
                        <div
                            className={styles.content}
                            dangerouslySetInnerHTML={{ __html: notification.content }}
                        />
                    </Item>
                )}

                {/* Attachments - with null check */}
                {/*{notification.attachedFiles && notification.attachedFiles.length > 0 && (*/}
                {/*    <Item type="full" label="Attachments">*/}
                {/*        <div className={styles.attachments}>*/}
                {/*            {notification.attachedFiles.map((file, index) => (*/}
                {/*                <div key={index} className={styles.attachment}>*/}
                {/*                    {file.fileParam.mime.startsWith('image/') ? (*/}

                {/*                        <img*/}
                {/*                            src={`${FILE_DOWNLOAD}/${file.fileParam.name}?view=inline`}*/}
                {/*                            alt={file.fileParam.name}*/}
                {/*                            className={styles.image}*/}
                {/*                        />*/}
                {/*                    ) : (*/}
                {/*                        <div className={styles.fileName}>*/}
                {/*                            {file.fileParam.name}*/}
                {/*                        </div>*/}
                {/*                    )}*/}
                {/*                </div>*/}
                {/*            ))}*/}
                {/*        </div>*/}
                {/*    </Item>*/}
                {/*)}*/}


                {/* Attachments - with null check */}
                {notification.attachedFiles && notification.attachedFiles.length > 0 && (
                    <Item type="full" label="Attachments">
                        <div className={styles.attachments}>
                            {notification.attachedFiles.map((file, index) => (
                                <div key={index} className={styles.attachment}>
                                    <ImageUrl
                                        fileParam={file.fileParam}
                                        size="md"
                                        className={styles.image}
                                        baseUrl={FILE_DOWNLOAD}
                                    />
                                </div>
                            ))}
                        </div>
                    </Item>
                )}

                {/* Recipients Information - with null checks */}
                {notification.paOrganizations && notification.paOrganizations.length > 0 && (
                    <Item type="full" label="Target Organizations">
                        <div className={styles.tags}>
                            {notification.paOrganizations.map((orgId, index) => (
                                <span key={index} className={styles.tag}>
                                    {orgId}
                                </span>
                            ))}
                        </div>
                    </Item>
                )}

                {notification.paRoles && notification.paRoles.length > 0 && (
                    <Item type="full" label="Target Roles">
                        <div className={styles.tags}>
                            {notification.paRoles.map((roleId, index) => (
                                <span key={index} className={styles.tag}>
                                    {roleId}
                                </span>
                            ))}
                        </div>
                    </Item>
                )}
            </>
        </ItemNavGroup>
    );
};