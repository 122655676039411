import {INewsModel} from "./models/NewsModel";
import {HttpService} from "./http";
import {Constants} from "./constants";


const NEWS = `${Constants.JAMA_SERVER_API}/web/news/1?category=news&limit=50`
const NOTIFICATIONS = `${Constants.JAMA_SERVER_API}/web/notifications/1`
const NOTIFICATIONS_READ = `${Constants.JAMA_SERVER_API}/web/notification/read/`
const NEWS_PUSHCONTENT = `${Constants.JAMA_SERVER_API_V2}/admin/news/1?category=news&limit=50`

/**
 * Represents a NewsService class.
 */
export class NewsService {


    /**
     * Retrieves the news from the server.
     *
     * @return {Promise<INewsModel[]>} Returns a promise that resolves with an array of INewsModel objects from the server.
     */
    public static async getNews(): Promise<INewsModel[]> {

                let res = await HttpService.get<INewsModel[]>(NEWS, false);
        /* if (res == null) return [];
        res = await Promise.all((res || []).map(async x => {
            x.media = await ApiService.get<INewsMediaModel>(`${NEWS_MEDIA_WP}${x.featured_media}`, false);
            return x;
        })); */
        // console.log(res);
        return Array.isArray(res) ? res || [] : [];
    }

    /**
     * Retrieves a news article by its ID.
     *
     * @param {number} id - The ID of the news article to retrieve.
     * @return {Promise<INewsModel | null>} A Promise that resolves to the news article with the specified ID, or null if not found.
     */
    public static async getNewsArticle(id: number): Promise<INewsModel | null> {
        let res = await NewsService.getNews();
        return res?.find(x => x.id === id) || null;
    }

    /**
     * Retrieves notifications from the server.
     * @returns {Promise<INewsModel[]>} - A promise that resolves to an array of INewsModel objects representing the notifications.
     */
    public static async getNotifications(): Promise<INewsModel[]> {
        let res = await HttpService.get<INewsModel[]>(NOTIFICATIONS, true);
        return Array.isArray(res) ? res || [] : [];
    }

    /**
     * Retrieve a notification article by its ID.
     *
     * @param {number} id - The ID of the notification article.
     * @returns {Promise<INewsModel | null>} - A promise that resolves with the notification article or null if not found.
     */
    public static async getNotificationArticle(id: number): Promise<INewsModel | null> {
        let res = await NewsService.getNotifications();
        const ret = res?.find(x => x.id === id) || null;
        if (ret) {
            await this.readNotification(ret.pcuId || 0);
        }
        return ret;
    }

    /**
     * Reads a notification for the specified PCU ID.
     *
     * @param {number} pcuId - The PCU ID of the notification to be read.
     * @return {Promise<boolean>} - A promise that resolves to a boolean indicating whether the notification was read successfully or not.
     */
    public static async readNotification(pcuId: number): Promise<boolean> {
        return await HttpService.post2<{},boolean>(`${NOTIFICATIONS_READ}${pcuId}`, {}) || false;
    }
}