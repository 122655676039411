import {Constants} from "./constants";
import {HttpService} from "./http";
import {AuthService} from "./auth";
import {CacheService} from "./cache";
import {INotification, INotificationCreate} from "./models/NotificationModel";
import {IMeeting} from "./models";


// API Endpoints (corrected URL patterns)
const GET_NOTIFICATIONS = `${Constants.JAMA_SERVER_API_V2}/admin/:org/notification`;
const GET_NOTIFICATION = `${Constants.JAMA_SERVER_API_V2}/admin/:org/notification/:id`;
const POST_NOTIFICATION = `${Constants.JAMA_SERVER_API_V2}/admin/:org/notification`;
const GET_NOTIFICATION_AUDIENCES = `${Constants.JAMA_SERVER_API_V2}/admin/:org/notification/audiences`;
const GET_NOTIFICATION_AUDIENCE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/notification/audience/:id`;
const POST_NOTIFICATION_AUDIENCE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/notification/audience`;
const POST_NOTIFICATION_PUBLISH = `${Constants.JAMA_SERVER_API_V2}/admin/:org/notification/publish/:news/:audience`;

export class NotificationService {
    public static async all(org: number): Promise<INotification[]> {
        // await AuthService.refreshJwt();
        // Construct URL directly
        const url = `${Constants.JAMA_SERVER_API_V2}/admin/${org}/notification`;
        const res = await HttpService.get<INotification[]>(url);
        return res || [];
    }

    public static async get(org: number, pcId: number): Promise<INotification> {
        // await AuthService.refreshJwt();
        // Construct URL directly without string replacement
        const url = `${Constants.JAMA_SERVER_API_V2}/admin/${org}/notification/${pcId}`;
        console.log('Fetching notification with URL:', url);  // Debug log
        const response = await HttpService.get<INotification[] | INotification>(url,true, false);

        // Check if response is an array
        if (Array.isArray(response)) {
            if (response.length === 0) {
                throw new Error('Notification not found');
            }
            console.log('response[0]:', response[0]);
            return response[0];  // Return first item if it's an array
        }
        console.log('full response:', response);
        return response;
    }

    // public static async create(org: number, data: INotification): Promise<number> {
    //     // await AuthService.refreshJwt();
    //     await CacheService.clear();
    //     const url = `${Constants.JAMA_SERVER_API_V2}/admin/${org}/notification`;
    //     return await HttpService.post2<INotification, number>(url, data);
    // }

    public static async create(org: number, data: INotificationCreate): Promise<number> {
        // await AuthService.refreshJwt();
        await CacheService.clear();

        // If you need to handle file uploads separately, you might need a different approach
        // depending on your backend requirements
        const url = `${Constants.JAMA_SERVER_API_V2}/admin/${org}/notification`;
        return await HttpService.post2<INotificationCreate, number>(url, {
            ...data,
            orgId: org
        });
    }

    public static async getAudiences(org: number): Promise<INotification[]> {
        // await AuthService.refreshJwt();
        const url = `${Constants.JAMA_SERVER_API_V2}/admin/${org}/notification/audiences`;
        const response = await HttpService.get<INotification[]>(url);
        return response || [];
    }

    public static async getAudience(org: number, audienceId: number): Promise<INotification> {
        // await AuthService.refreshJwt();
        const url = `${Constants.JAMA_SERVER_API_V2}/admin/${org}/notification/audience/${audienceId}`;
        return await HttpService.get<INotification>(url);
    }

    public static async createAudience(org: number, data: any): Promise<number> {
        // await AuthService.refreshJwt();
        await CacheService.clear();
        const url = `${Constants.JAMA_SERVER_API_V2}/admin/${org}/notification/audience`;
        return await HttpService.post2<any, number>(url, data);
    }

    public static async publish(org: number, newsId: number, audienceId: number): Promise<boolean> {
        // await AuthService.refreshJwt();
        await CacheService.clear();
        const url = `${Constants.JAMA_SERVER_API_V2}/admin/${org}/notification/publish/${newsId}/${audienceId}`;
        return await HttpService.post2<null, boolean>(url, null);
    }
}