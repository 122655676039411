import {Ii18n} from "../../app";

export const en: Ii18n = {
    global: {
        processing: "Processing request",
        success: "Success",
        error: "Error: Something went wrong",
    },
    login: {
        message: "JUDOJAMA Login",
        success: "Login successful",
        failure: "Login failed",
        reset: "Reset your password",
        request: "Enter email to request new password",
        invalidPassword: "Invalid Email or Password"
    },
    pin: {
        message: 'PIN Login'
    },
    error: {
        message: "Unexpected Login Error",
    }
}

export default en;