import React from "react";
import {Button} from "../form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera} from "@fortawesome/free-solid-svg-icons";
import styles from './camera_button.module.scss';
import classNames from "classnames";
import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { ProfileService } from "../../api";

interface ICameraButtonProps {
    uuid: string;
    className?: string;
    onClick?: () => void;
}

export const CameraButton: React.FC<ICameraButtonProps> = ({uuid, className, onClick}) => {
    const takePicture = async () => {
        // Check if we're running on native platform
        if (Capacitor.isNativePlatform()) {
            try {
                console.log(await Camera.requestPermissions({permissions: ['camera']}));
                const image = await Camera.getPhoto({
                    resultType: CameraResultType.DataUrl,
                    source: CameraSource.Prompt,
                    quality: 100,
                    width: 350,
                });

                if (image.dataUrl) {
                    // Convert base64 to blob
                    const response = await fetch(image.dataUrl);
                    const blob = await response.blob();

                    // Create form data and upload
                    const formData = new FormData();
                    formData.append("file", blob, "profile.jpg");
                    await ProfileService.uploadProfilePicture(uuid, formData);

                    // Notify parent component
                    onClick?.();
                }
            } catch (error) {
                console.error('Error taking picture:', error);
            }
        } else {
            // On web, use the ImageCropDialog
            onClick?.();
        }
    };

    return (
        <div className={classNames(styles.container)}>
            <Button
                className={classNames(styles.camera, className)}
                onClick={takePicture}
            >
                <FontAwesomeIcon icon={faCamera} />
            </Button>
        </div>
    );
};

export default CameraButton;