import React, {useEffect, useMemo, useState} from "react";
import {faAdd, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {IMeeting} from "../../../api/models";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {ItemNav} from "../../form/ItemNav";
import {DateBetweenText, FD, FDS, FDHBR, Input, Item} from "../../form";
import classNames from "classnames";
import {MeetingService} from "../../../api/meeting";
import {IPageLinkProps} from "../../page.props";
import styles from './event.module.scss'
import {useParamId, useParamRole} from "../../useParam";
import {Loading} from "../../foundation/Loading";
import {doStupidStringIntBoolParamsConverstion} from "./event_detail_edit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { getOrgMotherId } from '../../../util/roles';
import {useSearchParams} from "react-router-dom";

const Event: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    const [loading, setLoading] = useState(true);
    const [meetings, setMeetings] = useState<IMeeting[]>([]);
    const [search, setSearch] = useState<string>('');
    const [searchParams] = useSearchParams();
    const mode = searchParams.get('t');

    const filterLength = 1000;
    const id = useParamId();
    const role = useParamRole(user);

    const IJF = 10000
    const EJU = 10001


    // Main effect for loading meetings
    useEffect(() => {
        //console.log("Loading meetings, refresh value:", refresh); // Debug loading
        setLoading(true);
        if(role != null) {
            MeetingService.all(role.orgId).then((m) => {
                //console.log("Received meetings:", m.length); // Debug received data
                setMeetings(m);
            }).finally(() => setLoading(false));
        } else {
            setLoading(false)
        }
    }, [user, refresh, role]);

    // Effect for handling deletion
    useEffect(() => {
        if (mode === 'delete' && id === 0 && setRefresh) {
            //console.log("Delete mode detected, triggering refresh"); // Debug deletion
            setRefresh(prev => prev + 1);
        }
    }, [mode, id, setRefresh]);

    useEffect(() => {
        setLoading(true);
        if(role != null) {
            MeetingService.all(role.orgId).then((m) => {
                setMeetings(m);
            }).finally(() => setLoading(false));
        } else {
            setLoading(false)
        }
    }, [user, refresh, role])

    const filter = useMemo(() => {
        const source = meetings;
        const now = new Date();

        // Filter based on search if search string exists
        const searchFiltered = search.length > 0
            ? source.filter(x =>
                (x.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    x.param?.ORT?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    x.id.toString().includes(search)))
            : source;

        // Split into future and past events
        const futureEvents = searchFiltered.filter(x => new Date(x.startdate) >= now);
        const pastEvents = searchFiltered.filter(x => new Date(x.startdate) < now);

        // Sort future events ascending (further future at bottom)
        const sortedFutureEvents = futureEvents.sort((a, b) =>
            new Date(a.startdate).getTime() - new Date(b.startdate).getTime()
        );

        // Sort past events descending (most recent past at top)
        const sortedPastEvents = pastEvents.sort((a, b) =>
            new Date(b.startdate).getTime() - new Date(a.startdate).getTime()
        );

        // Combine future events with past events (both in descending order)
        return [...sortedFutureEvents, ...sortedPastEvents];
    }, [meetings, role.orgId, search]); // Keep role.orgId in dependencies

    const getOrgDisplayName = (organizations: any[], orgId: number) => {
        const org = organizations.find(org => org.id === orgId);
        return org?.orgLevel === 3 ? 'Verein' : org?.nameShort || '';
    }


    const getOrgDisplayInfo = (organizations: any[], orgId: number) => {
        const org = organizations.find(org => org.id === orgId);
        return {
            displayName: org?.orgLevel === 3 ? '' : org?.nameShort || '',
            styleClass: org?.orgLevel === 2 ? 'lv'
                : org?.orgLevel === 1
                    ? (org?.id === EJU || org?.id === IJF)
                        ? 'eju'
                        : 'ojv'
                    : ''
        };
    };

    const showMeetingFilter = (currentOrgId: number, meetingOrgId: number, organizations: any[], meeting: any): boolean => {
        // Direct match of organization IDs
        if (meetingOrgId === currentOrgId) {
            return true;
        }

        // Check if meeting is nationwide (BUNDESWEIT)
        if (meeting.param?.BUNDESWEIT === true) {
            return true;
        }

        if(meeting.orgId === EJU ) return true;
        if(meeting.orgId === IJF ) return true;

        // Check if the meeting's organization is in the current org's hierarchy
        let parentId = currentOrgId;
        while (parentId) {
            if (parentId === meetingOrgId) {
                return true;
            }
            parentId = getOrgMotherId(parentId, organizations);
        }

        // Check if meeting's org_mutter_id matches current org ID
        if(meeting.org_mutter_id === currentOrgId) return true;

        // NEW CONDITION: Check if current org and meeting org have the same parent org
        const currentOrgMother = getOrgMotherId(currentOrgId, organizations);
        const meetingOrgMother = meeting.org_mutter_id || getOrgMotherId(meetingOrgId, organizations);

        if (currentOrgMother > 0 && currentOrgMother === meetingOrgMother) {
            return true;
        }

        return false;
    };
    // const showMeetingFilter = (currentOrgId: number, meetingOrgId: number, organizations: any[], meeting: any): boolean => {
    //     // Direct match of organization IDs
    //     if (meetingOrgId === currentOrgId) {
    //         return true;
    //     }
    //
    //     // Check if meeting is nationwide (BUNDESWEIT)
    //     if (meeting.param?.BUNDESWEIT === true) {
    //         return true;
    //     }
    //
    //     if(meeting.orgId === EJU ) return true;
    //     if(meeting.orgId === IJF ) return true;
    //
    //     // Compare orgMother IDs
    //     const currentOrgMother = getOrgMotherId(currentOrgId, organizations);
    //     const meetingOrgMother = getOrgMotherId(meetingOrgId, organizations);
    //
    //     return currentOrgMother === meetingOrgMother;
    // };


    return <>
        <ItemNavGroup label={role?.orgName} className={styles.itemnavgroup}>
            <>
            {loading && <Loading/>}
            <ItemNav
                label="Neuen Event hinzufügen"
                icon={faAdd}
                href={`${gotoUrl}/0?t=edit`}
            />
            <Item type="full" className={styles.search}>
                <Input onChange={setSearch} value={search} className={styles.name} placeholder="Suche nach ID, Name oder Adresse"/>
            </Item>
            {!loading && (filter?.length > 0 ? (
                <>
                    {filter.slice(0, filterLength)
                        .filter(e => showMeetingFilter(role.orgId, e.org_id, profile.organizations, e))
                        .map((e) => {
                        const DateBetween = DateBetweenText(e.open_from, e.open_to);
                        const editable = (e?.org_id === role.orgId || getOrgMotherId(e?.org_id, profile.organizations) === role.orgId || role.orgId === 1) || false
                        //console.log('DateBetween: ', e.id + ' ' + DateBetween)
                        //console.log('from-to: ',e.open_from, e.open_to);
                        return (
                        <ItemNav
                            key={e.id}
                            href={`${gotoUrl}/${e.id}?t=roles`}
                            //className={classNames(styles.content, e.id ===  1 && styles.active, editable ? styles.edit : styles.noedit) }
                            className={classNames(
                                styles.content,
                                e.id === 1 && styles.active,
                                editable ? styles.edit : styles.noedit,
                                new Date(e.enddate) < new Date() && styles.old  // Add this line
                            )}
                            selected={id === e.id}
                        >
                            <div className={styles.entry}>
                                <div className={classNames(styles.organizer)}>
                                    <div className={styles.meetingId}>{e.id}</div>
                                    <div className={classNames(styles.organizername, {
                                        [styles.lv]: getOrgDisplayInfo(profile.organizations, e.org_id).styleClass === 'lv',
                                        [styles.ojv]: getOrgDisplayInfo(profile.organizations, e.org_id).styleClass === 'ojv',
                                        [styles.jv]: getOrgDisplayInfo(profile.organizations, e.org_id).styleClass === 'jv'
                                    })}>
                                        {getOrgDisplayInfo(profile.organizations, e.org_id).styleClass === 'ojv' ? 'ÖJV' : getOrgDisplayInfo(profile.organizations, e.org_id).displayName}
                                    </div>
                                                    {/*{editable && <FontAwesomeIcon icon={faPenToSquare} />}   */}
                                </div>
                                <div className={styles.name}>
                                    {e.name}
                                    <div className={styles.nennzeitraum}>
                                        {e.open_from ? FDS(e.open_from) + ' - ' : '1x speichern!'} {e.open_to ? <>{FDS(e.open_to)} <FontAwesomeIcon icon={faPenToSquare} /></> : ''}
                                    </div>
                                    <div className={styles.eventzeitraum}>
                                        {FDS(e.startdate)} - {FDS(e.enddate)}
                                    </div>
                                </div>

                                <div>
                                    {doStupidStringIntBoolParamsConverstion(e.param?.BUNDESWEIT) ? <div className={styles.bundesweit}>
                                        BUNDESW.
                                    </div> : <div className={styles.local}>NUR LOKAL</div>}
                                    {DateBetween == 'open' ? <div className={styles.open}>ANMELDEN</div>
                                        : DateBetween == 'soon' ? <div className={styles.soon}>DEMNÄCH.</div>
                                            : DateBetween == 'closed' ? <div className={styles.closed}>GESCHLOSS.EN</div>
                                                : DateBetween == 'missing' ? <div className={styles.nojama}>KEINE ANM.</div> : null
                                                    // : null <div className={styles.error}>! ENTWURF !</div>
                                    }
                                </div>
                            </div>
                        </ItemNav>
                    )})}
                    <Item>Es werden nur die ersten {filterLength} Ergebnise angezeigt</Item>
                </>)  :  <Item type="list" label="Keine Veranstaltungen verfügbar" />)}
            </>
        </ItemNavGroup>
    </>
}

export default Event;