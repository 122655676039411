import React, { useState, useCallback} from 'react';
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Card, Content, Header } from "../../../components/foundation";
import { Link } from "react-router-dom";
import { Email, Form, Pin, Submit, Button } from "../../../components/form";
import { Password } from "../../../components/form/Password";
import styles from './Login.module.scss';
import { toaster } from "../../../core/toaster";
import i18n from "../../../config/i18n";
import { AuthService } from "../../../api";
import { Tab } from "../../../components/foundation";
import {faEye, faEyeSlash, faKey, faLock} from "@fortawesome/free-solid-svg-icons";
import PinInput from 'react-pin-input';
import packageJson from '../../../../package.json';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ILoginProps {}

const LoginPage: React.FC<ILoginProps> = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>(localStorage.getItem('email') || '');
    const [pin, setPin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loginType, setLoginType] = useState<'pin' | 'password'>('pin');
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const version = packageJson.version || undefined;
    const [showPassword, setShowPassword] = useState(false);

    // Convert class methods to functions
    const handlePinChange = (value: string) => {
        setPin(value);
    };

    const handlePasswordChange = (e: any) => {
        setPassword(e.target.value);
    };

    const handleEmailChange = (e: string) => {
        setEmail(e);
    };

    const toggleLoginType = useCallback(() => {
        setLoginType(prev => prev === 'pin' ? 'password' : 'pin');
        setPin('');
        setPassword('');
    }, []);

    const login = async (e: any): Promise<void> => {
        e.preventDefault();
        if (disableButton) {
            return;
        }

        setDisableButton(true);
        localStorage.setItem('email', email);

        await toaster(async () => {
            try {
                if (loginType === 'pin') {
                    await AuthService.authorize(email, pin);
                } else {
                    await AuthService.authorizePassword(email, password);
                }
                setTimeout(() => {
                    window.location.href = '/news';
                }, 250);
            } catch (e) {
                setTimeout(() => {
                     setDisableButton(false);
                }, 3000);
                throw e;
            }
        }, {
            success: i18n().login.success,
            failure: i18n().login.failure,
        });
    };

    const handlePinEmailRequest = () => {
        // You can add any additional logic here before navigation
        navigate('/signup');
    };


    return (
        <>
            <Header label="Login"/>
            <Content state="active">
                <div className={styles.loginTypeButtons}>
                    <Tab
                        icon={faKey}
                        label="PIN Login"
                        active={loginType === 'pin'}
                        onClick={() => setLoginType('pin')}
                    />
                    <Tab
                        icon={faLock}
                        label="Passwort"
                        active={loginType === 'password'}
                        onClick={() => setLoginType('password')}
                    />
                </div>
                <Card className={styles.card}>
                    <Form onSubmit={login}>
                        <h3>{loginType === 'pin' ? i18n().pin.message : 'Login mit EMAIL+PASSWORT'}</h3>
                        <Email
                            label="Email (vom Verein registriert)"
                            name="email"
                            placeholder="beispiel@email.com"
                            value={email}
                            onChange={handleEmailChange}
                        />

                        {loginType === 'pin' ? (
                            <>
                                <div className={styles.pinInputContainer}>
                                <PinInput
                                    length={6}
                                    initialValue={pin}
                                    type="numeric"
                                    inputMode="number"
                                    style={{
                                        padding: '0rem',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                    inputStyle={{
                                        borderColor: 'var(--gray)',
                                        backgroundColor: 'white',
                                        borderRadius: '0.3rem',
                                        width: 'calc(16.666% - 1rem)', // This ensures equal spacing between inputs
                                        minWidth: '1rem',
                                        border: '2px solid gray',
                                        fontSize: '2rem',
                                        marginLeft: '0rem', // Remove default margin
                                        marginRight: '0rem' // Remove default margin
                                    }}
                                    inputFocusStyle={{
                                        borderColor: '#3880ff',
                                        boxShadow: '0 0 0 2px rgba(56, 128, 255, 0.2)'
                                    }}
                                    onComplete={handlePinChange}
                                    //  onChange={handlePinChange}
                                    autoSelect={true}
                                    placeholder=""
                                    disabled={disableButton}
                                />
                                </div>
                            </>
                        ) : (
                            // <div style={{position: 'relative'}}>
                            <input
                                type="password"
                                name="password"
                                placeholder="Passwort"
                                value={password}
                                onChange={handlePasswordChange}
                                autoComplete="current-password"
                            />
                            // <button
                            // type="button"
                            // onClick={() => setShowPassword(!showPassword)}
                            //     style={{
                            //         color: 'black',
                            //         position: 'absolute',
                            //         right: '1rem',
                            //         height: '2rem',
                            //         top: '50%',
                            //         transform: 'translateY(-50%)',
                            //         border: 'none',
                            //         backgroundColor: 'white !important',
                            //         background: 'white !important',
                            //     }}
                            //     aria-label={showPassword ? 'Hide password' : 'Show password'}
                            // >
                            //     <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} style={{height: '1.5rem', width: '1.5rem'}} />
                            // </button>
                            // </div>
                        )}

                        <Submit
                            label={loginType === 'pin' ? "PIN Log In" : "Passwort Log In"}
                            name="login"
                            disabled={disableButton}
                        />
                        {loginType === 'pin' ? (<Button
                            className={styles.pinbutton}
                            label="PIN PER EMAIL ANFORDERN"
                            onClick={handlePinEmailRequest}
                        />):''}
                    </Form><div className={styles.version}>Version: {version}</div>
                </Card>

            </Content>
        </>
    );
};

export default LoginPage;