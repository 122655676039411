import React, {useEffect, useMemo, useState} from "react";
import { useNavigate } from "react-router-dom";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {ItemNav} from "../../form/ItemNav";
import classNames from "classnames";
import {IPageLinkProps} from "../../page.props";
import styles from './league.module.scss'
import {useParamId, useParamOrg, useParamRole} from "../../useParam";
import {Loading} from "../../foundation/Loading";
import { LeagueService } from "../../../api/league";
import { ILeagueTeamBegeg } from "../../../api/models/LeagueModel";
import {DateBetweenText, FDT, FDS, FDTHBR, FormatMatchDate, Input, Item} from "../../form";
import {faAdd} from "@fortawesome/free-solid-svg-icons";

const League: React.FC<IPageLinkProps> = ({user, gotoUrl, refresh}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [matches, setMatches] = useState<ILeagueTeamBegeg[]>([]);
    const [search, setSearch] = useState<string>('');

    const id = useParamId();
    const role = useParamRole(user);
    const orgId = useParamOrg();
    console.log('orgId:', orgId)

    const [selectedLeague] = useState<number>(orgId); // Default to league ID 1
    const [selectedSeason] = useState<number>(2025); // Default to season 2024

    console.log('selectedLeague:', selectedLeague);
    console.log('selectedSeason:', selectedSeason);

    const filterLength = 300;

    useEffect(() => {
        setLoading(true);
        if(role != null) {
            LeagueService.begegAll(role.orgId, selectedLeague, selectedSeason).then((m) => {
                setMatches(m);
            }).finally(() => setLoading(false));
        } else {
            setLoading(false)
        }
    }, [user, refresh, role, selectedLeague, selectedSeason])

    const filter = useMemo(() => {
        const source = matches;
        return search.length > 0 ? source.filter(x =>
            x.teamname_h?.toLowerCase()?.includes(search?.toLowerCase()) ||
            x.teamname_a?.toLowerCase()?.includes(search?.toLowerCase()) ||
            x.liga?.toLowerCase()?.includes(search?.toLowerCase()) ||
            x.ort?.toLowerCase()?.includes(search?.toLowerCase())
        ) : source;
    }, [matches, search]);

    const goto = (pcId: number) => {
        navigate(`${gotoUrl}/${pcId}`);
    };


    return <>
        <ItemNavGroup label={role?.orgName}>
            <>
                {loading && <Loading/>}
                {/*<ItemNav*/}
                {/*    label="Neuen Kampf erstellen"*/}
                {/*    icon={faAdd}*/}
                {/*    onClick={() => goto(0)}*/}
                {/*/>*/}
                <Item type="full" className={styles.search}>
                    <Input
                        onChange={setSearch}
                        value={search}
                        className={styles.name}
                        placeholder="Suche nach Teams, Liga oder Ort..."
                    />
                </Item>
                {!loading && (filter?.length > 0 ? (
                    <>
                        {filter.slice(0, filterLength).map((match) => (
                            <ItemNav
                                key={match.id}
                                onClick={() => goto(match.id)}
                                className={classNames(styles.content)}
                                selected={id === match.id}
                            >
                                <div className={styles.entry}>
                                    <div className={styles.name}>
                                        <div className={styles.notificationId}>
                                            {match.liga} - Runde {match.runde} {match.playoff && `(${match.playoff})`}
                                        </div>
                                        <div className={styles.matchTitle}>
                                            {match.teamname_h} vs {match.teamname_a}
                                        </div>
                                        <div className={styles.score}>
                                            {match.erg_siege_h}:{match.erg_siege_a} ({match.erg_ubw_h}:{match.erg_ubw_a})
                                        </div>
                                        <div className={styles.venue}>
                                            {match.ort}
                                        </div>
                                        <div className={styles.date}>
                                            {/*{formatMatchDate(match.kampfbeginn)}*/}
                                            {FormatMatchDate(match.kampfbeginn, match.uhrzeit)} / Match: {match.id}
                                        </div>
                                    </div>
                                </div>
                            </ItemNav>
                        ))}
                        {filter.length > filterLength && (
                            <Item>Only showing first {filterLength} results</Item>
                        )}
                    </>
                ) : <Item type="list" label="Keine Kämpfe gefunden!" />)}
            </>
        </ItemNavGroup>
    </>
}

export default League;