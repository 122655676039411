import React, {useState, useEffect} from "react";
import styles from './menu.module.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {ReactChildrenType} from "../../core/children";
import AvatarImage from "./AvatarImage";
import {AuthService, IMAGE_URL} from "../../api";
import {Button} from "../form/Button";
import { useHelp } from '../../components/help/HelpContext';

interface IMenuProps {
    children: ReactChildrenType;
    user: any;
}

export const Menu: React.FC<IMenuProps> = ({children, user}) => {
    const [open, setOpen] = useState<Boolean>(false);
    const [imageVersion, setImageVersion] = useState<number>(Date.now());
    const { openHelp } = useHelp();  // Add this hook

    // Listen for successful image uploads
    useEffect(() => {
        const handleImageUpload = (event: CustomEvent) => {
            // Check if the uploaded image matches our user
            if (event.detail?.uuid === user?.imageLink?.split('/').pop()) {
                setImageVersion(Date.now());
            }
        };

        // Add event listener
        window.addEventListener('profileImageUpdated', handleImageUpload as EventListener);

        return () => {
            // Cleanup
            window.removeEventListener('profileImageUpdated', handleImageUpload as EventListener);
        };
    }, [user]);

    const profileImage = `${IMAGE_URL}${user?.imageLink}?v=${imageVersion}`;
    const handleLogout = async () => {
        await AuthService.logout();
        window.location.href = '/admin/login';
    };
    return (
        <div className={styles.container}>
            <Button
                className={styles.logoutbttn}
                onClick={handleLogout}
                label="Logout"
            />
            <div className={classNames(styles.menu, open && styles.open)} onClick={() => setOpen(!open)}>
                <div className={styles.imageContainer}>
                    <AvatarImage
                        className={styles.image}
                        src={profileImage}
                    />
                </div>
                {open && <div className={styles.content}>{children}</div>}
            </div>
            <Button
                className={styles.helplink}
                width={"full"}
                label={"🛟"}
                onClick={() => openHelp('vereine')}
            />
        </div>
    );
};