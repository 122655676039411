import React from "react";
import {IUserModel, IUserProfile} from "./api/models";
import {BrowserRouter, NavigateFunction, Route, Routes} from "react-router-dom";
import styles from './app__desktop.module.scss';
import {withAuth} from "./api/withAuth";
import Forbidden from "./pages/desktop/forbidden";
import {DesktopForm} from "./pages/desktop/form";
import ProfilePage from "./pages/desktop/profile";
import ProfileTrainerPage from "./pages/desktop/profile_trainer";
import {Login} from "./pages/desktop/login";
import {Toaster} from "./core/toaster";
import {MenuItem} from "./components/foundation";
import {AuthService} from "./api";
import {PasswordRequest} from "./pages/desktop/password_request";
import {PasswordReset} from "./pages/desktop/password_reset";
import packageJson from '../package.json';
import {faIdCard} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { HelpProvider } from './components/help/HelpContext';
// Your existing code

interface IDesktopProps {
    auth: boolean;
    user: IUserModel;
    profile: IUserProfile;
    refresh: () => Promise<void>;
}

const AppJudoDesktop: React.FC<IDesktopProps> = ({auth, user, profile, refresh}) => {
    //const version = "1.0.1"; // Define the version string
    const version = packageJson.version;
    const judocard = user?.lastjudocardyear == moment(new Date()).format('YYYY') ? user?.lastjudocardyear :  ''
    const menu = (navigate: NavigateFunction) => (<>
        <MenuItem label="" user={profile.familyName + ' ' + profile.givenName}/>
        <MenuItem label="Dein Profil" to={"/profile"}/>
        {/*<MenuItem label="Hilfe"/>*/}

        {/*<MenuItem label="JudoCard" judocard={judocard}/>*/}
        <MenuItem label="" version={`Version: ${version}`}/>

        {/*<MenuItem label="Logout" onClick={() => {*/}
        {/*    AuthService.logout().then(() => {*/}
        {/*        navigate('/login')*/}
        {/*    });*/}
        {/*}}/>*/}
    </>)

    const pprops = {user, profile, menu};
    return (
        <BrowserRouter basename="/">
            <HelpProvider>
            <Toaster/>
            <div className={styles.layout}>
            <Routes>
                <Route path="/" element={auth ? <DesktopForm {...pprops} /> : <Login/>} />
                <Route path="/login" element={<Login />} />
                <Route path="/password_request" element={<PasswordRequest />} />
                <Route path="/repair-password/:token" element={<PasswordReset />} />
                <Route path="/profile" element={auth ? <ProfilePage {...pprops} /> : <Login />} />
                <Route path="/profile/trainer" element={auth ? <ProfileTrainerPage {...pprops} /> : <Login />} />
                <Route path="/:org" element={auth ? <DesktopForm {...pprops} /> : <Login/>} />
                <Route path="/:org/:role" element={auth ? <DesktopForm {...pprops} /> : <Login/>} />
                <Route path="/:org/:role/:form" element={auth ? <DesktopForm {...pprops} /> : <Login/>} />
                <Route path="/:org/:role/:form/:id" element={auth ? <DesktopForm {...pprops} /> : <Login/>} />
                {/*<Route path="/member/:role/:id" element={<DesktopMemberPage {...pprops} />} />*/}
            </Routes>
            </div>
          </HelpProvider>
        </BrowserRouter>
    )
}

export default withAuth(AppJudoDesktop);

